import React, { useState } from "react";
import "./App.css";
import AWS from "aws-sdk";
import Select from "react-select";
import { districts,  villages } from "./db";
import imageCompression from "browser-image-compression";
import { ProgressBar } from "react-loader-spinner";
import Swal from "sweetalert2";

function App() {
  const [file, setFile] = useState([]);
  const [district, setDistrict] = useState(null);

  const [village, setVillage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [flag, setFlag] = useState(false);

  const handleFileChange = async (e) => {
    setLoader(true);
    const images = [...file, ...e.target.files];

    const options = {
      maxSizeMB: 0.2,
      useWebWorker: true,
    };
    const length = images?.length;
    images.map(async (item, index) => {
      const compressedFile = await imageCompression(item, options);

      setFile((oldFile) => [...oldFile, compressedFile]);
      if (index === length - 1) {
        setLoader(false);
      }
    });
  };

  const options = districts?.map((item, index) => {
    return {
      label: item,
      value: item,
      key: index,
    };
  });


  const options3 =
    district &&
    villages?.filter(function (el) {
      return el.district === district ;
    });

  const options4 = options3?.map((item, index) => {
    return {
      label: item.name,
      value: item.name,
      key: index,
    };
  });

  const handleChange = async (selected) => {
    setDistrict(selected.value);
  };
 
  const handleChange3 = async (selected) => {
    if (selected.value === "अन्य") {
      setFlag(true);
    } else {
      setVillage(selected.value);
    }
  };

  const date = `${new Date().getDate()}-${
    new Date().getMonth() + 1
  }-${new Date().getFullYear()}`;

  var hours = new Date().getHours();
  var minutes = new Date().getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;

  const uploadFile = async () => {
    if (district  && village && file.length > 0) {
      const S3_BUCKET = "times-project2";
      const REGION = "ap-south-1";

      AWS.config.update({
        accessKeyId: "AKIAV6HRDU6KHW6UDFW3",
        secretAccessKey: "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",
      });
      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });
      const length = file?.length;
      file.map(async (item, index) => {
        const params = {
          Bucket: S3_BUCKET,
          Key: `${district}/${village}/${date}/${item.name}`,
          Body: item,
        };

        var upload = s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log(
              "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();

        await upload.then((err, data) => {
          console.log(err);
        });

        if (index === length - 1) {
          Swal.fire({
            title: "Form uploaded successfully",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload(false);
            }
          });
        }
      });
    } else {
      alert("कृपया सभी फ़ील्ड चुनें");
    }
  };

  return (
    <div>
      {loader && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "250px",
          }}
        >
          <p>Compressing Images</p>
          <ProgressBar
            height="140"
            width="140"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass="progress-bar-wrapper"
            borderColor="#000000"
            barColor="#808080"
          />
        </div>
      )}

      <div className={loader ? "hide" : "container"}>
        <img src={require("./logo.jpeg")} alt="" style={{ width: "160px" }} />
        <h4 style={{ textAlign: "center" }}>
        समुदाय आधारित आपदा प्रबंधन प्रशिक्षण परियोजना फेज 2
        </h4>
        <div className="form-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>Date: {date}</p>
            <p style={{ marginLeft: "20px" }}>Time: {strTime}</p>
          </div>
          <div className="form-group">
            <label className="form-label">जिला/जनपद चुनें</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              name="Category"
              options={options}
              onChange={handleChange}
            />
          </div>
        
          <div className="form-group">
            <label className="form-label">ग्राम पंचायत चुनें</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              name="Category"
              options={options4}
              onChange={handleChange3}
            />
          </div>
          {flag && (
            <div className="form-group">
              <label className="form-label">ग्राम पंचायत का नाम</label>
              <input
                type="text"
                name="name"
                value={village}
                onChange={(e) => setVillage(e.target.value)}
              />
            </div>
          )}

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input type="file" onChange={handleFileChange} multiple />
            <button onClick={uploadFile} className="uploadBtn">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
