
export const districts = [
    "आगरा",
    "अम्बेडकरनगर",
    "अमेठी",
    "अमरोहा",
    "औरैया",
    "बदायूँ",
    "बलरामपुर",
    "बांदा",
    "बरेली",
    "भदोही",
    "बागपत",
    "बिजनौर",
    "बुलन्दशहर",
    "चन्दौली",
    "देवरिया",
    "एटा",
    "इटावा",
    "फर्रुखाबाद",
    "फिरोजाबाद",
    "फतेहपुर",
    "गौतमबुद्धनगर",
    "गाजियाबाद",
    "हाथरस",
    "हमीरपुर",
    "हापुड़",
    "हरदोई",
    "जालौन",
    "जौनपुर",
    "कन्नौज",
    "कानपुर देहात",
    "कानपुर नगर",
    "कासगंज",
    "कौशाम्बी",
    "ललितपुर",
    "महोबा",
    "मैनपुरी ",
    "मथुरा",
    "मऊ",
    "मेरठ",
    "मीरजापुर",
    "मुजपफरनगर",
    "प्रयागराज ",
    "प्रयागराज",
    "पीलीभीत",
    "प्रतापगढ़",
    "रायबरेली जं.",
    "रामपुर",
    "शाहजहाँपुर",
    "सहारनपुर",
    "सम्भल",
    "संत कबीर नगर",
    "शामली",
    "श्रावस्ती",
    "सिद्धार्थनगर",
    "सीतापुर",
    "सुलतानपुर",
    "उन्नाव"
]

export const villages =[
    {
        district: "आगरा",
        name: "उमरेठा"
    },
    {
        district: "आगरा",
        name: "क्यौरी"
    },
    {
        district: "आगरा",
        name: "जेवरा"
    },
    {
        district: "आगरा",
        name: "कुंवरखेड़ा"
    },
    {
        district: "आगरा",
        name: "गौसिंली"
    },
    {
        district: "आगरा",
        name: "बमरौली"
    },
    {
        district: "आगरा",
        name: "बासौनी"
    },
    {
        district: "आगरा",
        name: "खेडाराठौर"
    },
    {
        district: "आगरा",
        name: "रेहा"
    },
    {
        district: "आगरा",
        name: "बरेन्डा"
    },
    {
        district: "आगरा",
        name: "अई"
    },
    {
        district: "आगरा",
        name: "स्वारा"
    },
    {
        district: "आगरा",
        name: "भरापुर"
    },
    {
        district: "आगरा",
        name: "शाहिदपुर"
    },
    {
        district: "आगरा",
        name: "महरा चैधरी"
    },
    {
        district: "आगरा",
        name: "मडायना"
    },
    {
        district: "आगरा",
        name: "वाजीदपुर"
    },
    {
        district: "आगरा",
        name: "मेवलीखुर्द"
    },
    {
        district: "आगरा",
        name: "मेवलीकलां"
    },
    {
        district: "आगरा",
        name: "गुढा"
    },
    {
        district: "आगरा",
        name: "कुण्डौल"
    },
    {
        district: "आगरा",
        name: "वाईपुर"
    },
    {
        district: "आगरा",
        name: "मिढ़ाकुर"
    },
    {
        district: "आगरा",
        name: "मलपुरा"
    },
    {
        district: "आगरा",
        name: "वाद"
    },
    {
        district: "आगरा",
        name: "बरौली अहीर"
    },
    {
        district: "आगरा",
        name: "जारूआ कटरा"
    },
    {
        district: "आगरा",
        name: "अंगूठी"
    },
    {
        district: "आगरा",
        name: "विसारना"
    },
    {
        district: "आगरा",
        name: "अकोला"
    },
    {
        district: "आगरा",
        name: "बसई जगनेर"
    },
    {
        district: "आगरा",
        name: "सरैधी"
    },
    {
        district: "आगरा",
        name: "खेरागढ"
    },
    {
        district: "आगरा",
        name: "भिलावली"
    },
    {
        district: "आगरा",
        name: "बेरीचाहर"
    },
    {
        district: "आगरा",
        name: "सैंया"
    },
    {
        district: "आगरा",
        name: "तेहरा"
    },
    {
        district: "आगरा",
        name: "इरादतनगर"
    },
    {
        district: "आगरा",
        name: "कागारौल"
    },
    {
        district: "आगरा",
        name: "जगनेर"
    },
    {
        district: "आगरा",
        name: "सांधन"
    },
    {
        district: "आगरा",
        name: "कुकथला"
    },
    {
        district: "आगरा",
        name: "रूनकता"
    },
    {
        district: "आगरा",
        name: "ब्यारा"
    },
    {
        district: "आगरा",
        name: "जैगारा"
    },
    {
        district: "आगरा",
        name: "कौरई"
    },
    {
        district: "आगरा",
        name: "कराहरा"
    },
    {
        district: "आगरा",
        name: "रायभा"
    },
    {
        district: "आगरा",
        name: "जाजऊ"
    },
    {
        district: "आगरा",
        name: "दूरा"
    },
    {
        district: "आगरा",
        name: "सीकरी चार हिस्सा"
    },
    {
        district: "आगरा",
        name: "सामरा"
    },
    {
        district: "आगरा",
        name: "सुरहरा"
    },
    {
        district: "आगरा",
        name: "रायपुर"
    },
    {
        district: "आगरा",
        name: "गदपुरा"
    },
    {
        district: "आगरा",
        name: "रहनकंला"
    },
    {
        district: "आगरा",
        name: "पोइया"
    },
    {
        district: "आगरा",
        name: "हाजीपुर खेडा"
    },
    {
        district: "आगरा",
        name: "गिजौली"
    },
    {
        district: "आगरा",
        name: "पैतखेडा"
    },
    {
        district: "आगरा",
        name: "सौरई"
    },
    {
        district: "आगरा",
        name: "सराय दायरूपा"
    },
    {
        district: "अम्बेडकरनगर",
        name: "मकरही"
    },
    {
        district: "अम्बेडकरनगर",
        name: "धनुकारा"
    },
    {
        district: "अम्बेडकरनगर",
        name: "सरफुद्दीनपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "चहोड़ा शाहपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "नयागांव"
    },
    {
        district: "अम्बेडकरनगर",
        name: "सैदपुर लेडुवाडीह"
    },
    {
        district: "अम्बेडकरनगर",
        name: "गोवर्धनपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "जमलूपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "हकीमपुर खुर्द"
    },
    {
        district: "अम्बेडकरनगर",
        name: "मंसूरगंज"
    },
    {
        district: "अम्बेडकरनगर",
        name: "बिड़हरखास"
    },
    {
        district: "अम्बेडकरनगर",
        name: "तिलकारपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "तरौली मुबारकपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "फूलपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "पृथ्वीपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "केवटला"
    },
    {
        district: "अम्बेडकरनगर",
        name: "नरकटा बैरागीपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "अजमेरीबादशाहपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "नौरहनीरामपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "शंकरपुर विशुनपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "वर्गीनिजामपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "नसीराबाद"
    },
    {
        district: "अम्बेडकरनगर",
        name: "टण्डवा दरब"
    },
    {
        district: "अम्बेडकरनगर",
        name: "दुहिया"
    },
    {
        district: "अम्बेडकरनगर",
        name: "इस्माइलपुर बेल्दहा"
    },
    {
        district: "अम्बेडकरनगर",
        name: "अवसानपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "महरीपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "मांझा उल्टहवा"
    },
    {
        district: "अम्बेडकरनगर",
        name: "सलाहपुर रजौर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "ककराही"
    },
    {
        district: "अम्बेडकरनगर",
        name: "बड्डूपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "नैपुरा"
    },
    {
        district: "अम्बेडकरनगर",
        name: "खैरपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "रसूलपुर मुबारकपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "चिन्तौरा"
    },
    {
        district: "अम्बेडकरनगर",
        name: "कटरिया"
    },
    {
        district: "अम्बेडकरनगर",
        name: "आसोपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "सलारपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "करमपुर बरसावां"
    },
    {
        district: "अम्बेडकरनगर",
        name: "रायपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "मीरानपुर बभनपुरा"
    },
    {
        district: "अम्बेडकरनगर",
        name: "इन्दौरपुर उर्फ घिनहापुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "रायपुर वेलवाडाडी"
    },
    {
        district: "अम्बेडकरनगर",
        name: "माझा कम्हरिया"
    },
    {
        district: "अम्बेडकरनगर",
        name: "अराजी देवारा"
    },
    {
        district: "अम्बेडकरनगर",
        name: "किन्नूपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "मुबारकपुर पिकार"
    },
    {
        district: "अम्बेडकरनगर",
        name: "चाण्डीपुरकलाँ"
    },
    {
        district: "अम्बेडकरनगर",
        name: "फुलवरिया महारमपुर"
    },
    {
        district: "अम्बेडकरनगर",
        name: "केवटली"
    },
    {
        district: "अमेठी",
        name: "बघेल"
    },
    {
        district: "अमेठी",
        name: "बहादुरपुर"
    },
    {
        district: "अमेठी",
        name: "ओदारी"
    },
    {
        district: "अमेठी",
        name: "मोहड्या केसरिया"
    },
    {
        district: "अमेठी",
        name: "चण्डेरिया"
    },
    {
        district: "अमेठी",
        name: "धौरहरा"
    },
    {
        district: "अमेठी",
        name: "भौसिंहपुर"
    },
    {
        district: "अमेठी",
        name: "सोनारीकनू"
    },
    {
        district: "अमेठी",
        name: "पीपरपुर"
    },
    {
        district: "अमेठी",
        name: "शिवगढ़ जलालपुर"
    },
    {
        district: "अमेठी",
        name: "सुमेरपुर"
    },
    {
        district: "अमेठी",
        name: "कडेरगांव"
    },
    {
        district: "अमेठी",
        name: "आलमपुर"
    },
    {
        district: "अमेठी",
        name: "वासदेवपुर"
    },
    {
        district: "अमेठी",
        name: "सोनारी"
    },
    {
        district: "अमेठी",
        name: "टीकरमाफी"
    },
    {
        district: "अमेठी",
        name: "पिछूती"
    },
    {
        district: "अमेठी",
        name: "मटियारी कला"
    },
    {
        district: "अमेठी",
        name: "कपूरीपुर"
    },
    {
        district: "अमेठी",
        name: "जलालपुर तिवारी"
    },
    {
        district: "अमेठी",
        name: "गाजनपुर दुवरिया"
    },
    {
        district: "अमेठी",
        name: "कोछित"
    },
    {
        district: "अमेठी",
        name: "कोटवा"
    },
    {
        district: "अमेठी",
        name: "रसूलाबाद"
    },
    {
        district: "अमेठी",
        name: "बधौना"
    },
    {
        district: "अमेठी",
        name: "भागीरथपुर"
    },
    {
        district: "अमेठी",
        name: "अकबरपुर फर्शी"
    },
    {
        district: "अमेठी",
        name: "रमई"
    },
    {
        district: "अमेठी",
        name: "रोहसी खुर्द"
    },
    {
        district: "अमेठी",
        name: "इटौजा पश्चिम"
    },
    {
        district: "अमेठी",
        name: "गौरीपुर"
    },
    {
        district: "अमेठी",
        name: "बस्तीदेई"
    },
    {
        district: "अमेठी",
        name: "जुगराजपुर"
    },
    {
        district: "अमेठी",
        name: "अहोरवा भवानी"
    },
    {
        district: "अमेठी",
        name: "जयनगरा"
    },
    {
        district: "अमेठी",
        name: "फूला"
    },
    {
        district: "अमेठी",
        name: "नीमपुर"
    },
    {
        district: "अमेठी",
        name: "काजीपुर"
    },
    {
        district: "अमेठी",
        name: "बूबूपुर"
    },
    {
        district: "अमेठी",
        name: "हरखूगऊ"
    },
    {
        district: "अमेठी",
        name: "रामशाहपुर"
    },
    {
        district: "अमेठी",
        name: "कसरावां"
    },
    {
        district: "अमेठी",
        name: "कौहार"
    },
    {
        district: "अमेठी",
        name: "सेंवई हेमगढ़"
    },
    {
        district: "अमेठी",
        name: "रामगढ़"
    },
    {
        district: "अमेठी",
        name: "सरवनपुर"
    },
    {
        district: "अमेठी",
        name: "लोहरता"
    },
    {
        district: "अमेठी",
        name: "कोरारी गिरधरशाह"
    },
    {
        district: "अमेठी",
        name: "भोएं"
    },
    {
        district: "अमेठी",
        name: "बलभद्रपुर"
    },
    
        {
            district: "अमरोहा",
            name: "अम्हेड़ा"
        },
        {
            district: "अमरोहा",
            name: "आरिफपुर माफी"
        },
        {
            district: "अमरोहा",
            name: "मिलक अख्त्यारपुर "
        },
        {
            district: "अमरोहा",
            name: "आजमपुर"
        },
        {
            district: "अमरोहा",
            name: "मलेशिया"
        },
        {
            district: "अमरोहा",
            name: "अहरोला माफी"
        },
        {
            district: "अमरोहा",
            name: "शेरपुर मुस्तकम"
        },
        {
            district: "अमरोहा",
            name: "चुचौला कलां"
        },
        {
            district: "अमरोहा",
            name: "दीपपुर"
        },
        {
            district: "अमरोहा",
            name: "दयावली खालसा"
        },
        {
            district: "अमरोहा",
            name: "रझाँहा"
        },
        {
            district: "अमरोहा",
            name: "करनपुर माफी"
        },
        {
            district: "अमरोहा",
            name: "हरियाना"
        },
        {
            district: "अमरोहा",
            name: "तरारा"
        },
        {
            district: "अमरोहा",
            name: "ओसामाफी"
        },
        {
            district: "अमरोहा",
            name: "कालाखेड़ा"
        },
        {
            district: "अमरोहा",
            name: "रघुनाथपुर"
        },
        {
            district: "अमरोहा",
            name: "हरियाना"
        },
        {
            district: "अमरोहा",
            name: "ढकिया चमन"
        },
        {
            district: "अमरोहा",
            name: "पायंती कलां"
        },
        {
            district: "अमरोहा",
            name: "चौधरपुर"
        },
        {
            district: "अमरोहा",
            name: "कैलसा"
        },
        {
            district: "अमरोहा",
            name: "डिडौली"
        },
        {
            district: "अमरोहा",
            name: "पतेई खालसा"
        },
        {
            district: "अमरोहा",
            name: "जलालपुर धना"
        },
        {
            district: "अमरोहा",
            name: "अदलपुर ताज"
        },
        {
            district: "अमरोहा",
            name: "जमनाखास"
        },
        {
            district: "अमरोहा",
            name: "हादीपुर कला स"
        },
        {
            district: "अमरोहा",
            name: "नन्हेड़ा अल्यारपुर"
        },
        {
            district: "अमरोहा",
            name: "गनस्थल"
        },
        {
            district: "अमरोहा",
            name: "बडेरना"
        },
        {
            district: "अमरोहा",
            name: "पेली चौहान"
        },
        {
            district: "अमरोहा",
            name: "अव्वलपुर"
        },
        {
            district: "अमरोहा",
            name: "बादशाहपुर"
        },
        {
            district: "अमरोहा",
            name: "रूखालू"
        },
        {
            district: "अमरोहा",
            name: "रहरा"
        },
        {
            district: "अमरोहा",
            name: "जयतौली मु०"
        },
        {
            district: "अमरोहा",
            name: "आदमपुर"
        },
        {
            district: "अमरोहा",
            name: "पिपलौती कला"
        },
        {
            district: "अमरोहा",
            name: "ढबारसी"
        },
        {
            district: "अमरोहा",
            name: "गंगेश्वरी"
        },
        {
            district: "अमरोहा",
            name: "करनपुर खादर"
        },
        {
            district: "अमरोहा",
            name: "पपसरा खादर"
        },
        {
            district: "अमरोहा",
            name: "खादगूजर"
        },
        {
            district: "अमरोहा",
            name: "मौहरका पटटी"
        },
        {
            district: "अमरोहा",
            name: "महेशरा"
        },
        {
            district: "अमरोहा",
            name: "कुमराला बहादुरपुर"
        },
        {
            district: "अमरोहा",
            name: "सिहाली जागीर"
        },
        {
            district: "अमरोहा",
            name: "रहमापुर माफी"
        },
        {
            district: "अमरोहा",
            name: "मनौटा"
        },
    
    {
        district: "औरैया",
        name: "गैली"
    },
    {
        district: "औरैया",
        name: "पाता"
    },
    {
        district: "औरैया",
        name: "मिरगांवा"
    },
    {
        district: "औरैया",
        name: "गुवारी"
    },
    {
        district: "औरैया",
        name: "पसुआ"
    },
    {
        district: "औरैया",
        name: "नौगवां"
    },
    {
        district: "औरैया",
        name: "नवीमोहन"
    },
    {
        district: "औरैया",
        name: "सवलपुर"
    },
    {
        district: "औरैया",
        name: "सिरयावा"
    },
    {
        district: "औरैया",
        name: "पिपरौली शिव"
    },
    {
        district: "औरैया",
        name: "फतेहपुर"
    },
    {
        district: "औरैया",
        name: "पूर्वा खुतेमदारी"
    },
    {
        district: "औरैया",
        name: "कुर्सी"
    },
    {
        district: "औरैया",
        name: "एरवा कुईली"
    },
    {
        district: "औरैया",
        name: "कुकरकाट"
    },
    {
        district: "औरैया",
        name: "हरचन्द्रपुर"
    },
    {
        district: "औरैया",
        name: "बांधमऊ"
    },
    {
        district: "औरैया",
        name: "बेला"
    },
    {
        district: "औरैया",
        name: "वंशी"
    },
    {
        district: "औरैया",
        name: "असजना"
    },
    {
        district: "औरैया",
        name: "गुहानी कलां"
    },
    {
        district: "औरैया",
        name: "जूहीखा"
    },
    {
        district: "औरैया",
        name: "भुरेपुर कलां"
    },
    {
        district: "औरैया",
        name: "जसवंतपुर"
    },
    {
        district: "औरैया",
        name: "गूँज"
    },
    {
        district: "औरैया",
        name: "कैथोंली"
    },
    {
        district: "औरैया",
        name: "अयाना"
    },
    {
        district: "औरैया",
        name: "अमावता"
    },
    {
        district: "औरैया",
        name: "बीधेपुर"
    },
    {
        district: "औरैया",
        name: "अनन्तराम"
    },
    {
        district: "औरैया",
        name: "कस्बा जाना"
    },
    {
        district: "औरैया",
        name: "चिटकापुर"
    },
    {
        district: "औरैया",
        name: "चकसत्तापुर"
    },
    {
        district: "औरैया",
        name: "हाफिजपुर"
    },
    {
        district: "औरैया",
        name: "सेऊपुर"
    },
    {
        district: "औरैया",
        name: "क्योंटरा"
    },
    {
        district: "औरैया",
        name: "मढ़ापुर"
    },
    {
        district: "औरैया",
        name: "जरूहौलीया"
    },
    {
        district: "औरैया",
        name: "मिहौली"
    },
    {
        district: "औरैया",
        name: "कस्बा खानपुर"
    },
    {
        district: "औरैया",
        name: "गोहना"
    },
    {
        district: "औरैया",
        name: "खरका"
    },
    {
        district: "औरैया",
        name: "मई"
    },
    {
        district: "औरैया",
        name: "करमपुर"
    },
    {
        district: "औरैया",
        name: "दासपुर"
    },
    {
        district: "औरैया",
        name: "भासौन"
    },
    {
        district: "औरैया",
        name: "पन्हर"
    },
    {
        district: "औरैया",
        name: "रसूलपुर हुलासराय"
    },
    {
        district: "औरैया",
        name: "चैकी"
    },
    {
        district: "औरैया",
        name: "फतेहपुर करम"
    },
    {
        district: "बदायूँ",
        name: "हुसैनपुर पुख्ता"
    },
    {
        district: "बदायूँ",
        name: "पिपरौल पुख्ता"
    },
    {
        district: "बदायूँ",
        name: "खजुरारा खाम"
    },
    {
        district: "बदायूँ",
        name: "अल्लीपुर मढैया"
    },
    {
        district: "बदायूँ",
        name: "पलिया पुख्ता"
    },
    {
        district: "बदायूँ",
        name: "ननाखेडा पुख्ता"
    },
    {
        district: "बदायूँ",
        name: "इन्द्राई"
    },
    {
        district: "बदायूँ",
        name: "जैतपुर खाम"
    },
    {
        district: "बदायूँ",
        name: "कुढा शाहपुर"
    },
    {
        district: "बदायूँ",
        name: "ककोडा"
    },
    {
        district: "बदायूँ",
        name: "सुन्द्रायन"
    },
    {
        district: "बदायूँ",
        name: "चन्दनपुर"
    },
    {
        district: "बदायूँ",
        name: "भैंसोरा"
    },
    {
        district: "बदायूँ",
        name: "भूडा भदरौल"
    },
    {
        district: "बदायूँ",
        name: "मजरा गंगबरार"
    },
    {
        district: "बदायूँ",
        name: "गढिया गंगबरार"
    },
    {
        district: "बदायूँ",
        name: "मुगर्रा टटेई"
    },
    {
        district: "बदायूँ",
        name: "बरौरा"
    },
    {
        district: "बदायूँ",
        name: "भकोड़ा"
    },
    {
        district: "बदायूँ",
        name: "कादरचैक"
    },
    {
        district: "बदायूँ",
        name: "खिरकवारी मानपुर पुख्ता"
    },
    {
        district: "बदायूँ",
        name: "औरंगाबाद ८० जामनी"
    },
    {
        district: "बदायूँ",
        name: "नगला वरन"
    },
    {
        district: "बदायूँ",
        name: "सिठौलिया खाम"
    },
    {
        district: "बदायूँ",
        name: "टोंटपुर करसरी"
    },
    {
        district: "बदायूँ",
        name: "मालपुर ततेरा मु०"
    },
    {
        district: "बदायूँ",
        name: "धापड़"
    },
    {
        district: "बदायूँ",
        name: "भीकमपुर ८० जामनी"
    },
    {
        district: "बदायूँ",
        name: "सेरई उर्फ विजयगढी"
    },
    {
        district: "बदायूँ",
        name: "केरई सेरई"
    },
    {
        district: "बदायूँ",
        name: "कुंवरपुर चांदन"
    },
    {
        district: "बदायूँ",
        name: "दियोरा शेखपुर"
    },
    {
        district: "बदायूँ",
        name: "पडरिया"
    },
    {
        district: "बदायूँ",
        name: "सिरसा खुर्द"
    },
    {
        district: "बदायूँ",
        name: "ढेल"
    },
    {
        district: "बदायूँ",
        name: "बैरपुर मानपुर"
    },
    {
        district: "बदायूँ",
        name: "हरदत्तपुर"
    },
    {
        district: "बदायूँ",
        name: "हमूपुर चमरपुरा"
    },
    {
        district: "बदायूँ",
        name: "कस्बा भगता नगला"
    },
    {
        district: "बदायूँ",
        name: "मुडारी सिधारपुर"
    },
    {
        district: "बदायूँ",
        name: "सरेली पुख्ता"
    },
    {
        district: "बदायूँ",
        name: "कटरा सहादतगंज"
    },
    {
        district: "बदायूँ",
        name: "अस्मयारफतपुर"
    },
    {
        district: "बदायूँ",
        name: "बघेली ताराचन्द्र"
    },
    {
        district: "बदायूँ",
        name: "टिकाई पुख्ता"
    },
    {
        district: "बदायूँ",
        name: "नगला शिम्भू पुख्ता"
    },
    {
        district: "बदायूँ",
        name: "भुण्डी"
    },
    {
        district: "बदायूँ",
        name: "ललोमई"
    },
    {
        district: "बदायूँ",
        name: "सिठौली खाम"
    },
    {
        district: "बदायूँ",
        name: "खेडा जलालपुर खाम"
    },
    {
        district: "बलरामपुर",
        name: "कोलवा"
    },
    {
        district: "बलरामपुर",
        name: "राजघाट"
    },
    {
        district: "बलरामपुर",
        name: "भगवतपुर"
    },
    {
        district: "बलरामपुर",
        name: "परसियामाफी"
    },
    {
        district: "बलरामपुर",
        name: "सिरिया"
    },
    {
        district: "बलरामपुर",
        name: "मधवाजोत"
    },
    {
        district: "बलरामपुर",
        name: "भीखमपुर"
    },
    {
        district: "बलरामपुर",
        name: "ढोढारी"
    },
    {
        district: "बलरामपुर",
        name: "टेंगनहियामानकोट"
    },
    {
        district: "बलरामपुर",
        name: "सेमरहना"
    },
    {
        district: "बलरामपुर",
        name: "जबदही"
    },
    {
        district: "बलरामपुर",
        name: "झौहना"
    },
    {
        district: "बलरामपुर",
        name: "कलन्दरपुर"
    },
    {
        district: "बलरामपुर",
        name: "गौरात्रिकौलिया"
    },
    {
        district: "बलरामपुर",
        name: "नौबस्तामुड़िला"
    },
    {
        district: "बलरामपुर",
        name: "बेलवासुल्तानजोत"
    },
    {
        district: "बलरामपुर",
        name: "भगवानपुर"
    },
    {
        district: "बलरामपुर",
        name: "मिर्जापुर"
    },
    {
        district: "बलरामपुर",
        name: "लालाजोत"
    },
    {
        district: "बलरामपुर",
        name: "पैगापुर"
    },
    {
        district: "बलरामपुर",
        name: "रोंवारी"
    },
    {
        district: "बलरामपुर",
        name: "कटराशंकरनगर"
    },
    {
        district: "बलरामपुर",
        name: "सोनार"
    },
    {
        district: "बलरामपुर",
        name: "रामपुरबनघुसरा"
    },
    {
        district: "बलरामपुर",
        name: "सिसई"
    },
    {
        district: "बलरामपुर",
        name: "रछौडा"
    },
    {
        district: "बलरामपुर",
        name: "गंगापुरबांकी"
    },
    {
        district: "बलरामपुर",
        name: "अड़ारापाकड़"
    },
    {
        district: "बलरामपुर",
        name: "बहेरेकुईयां"
    },
    {
        district: "बलरामपुर",
        name: "गोदीपुर"
    },
    {
        district: "बलरामपुर",
        name: "पूरेछीटन"
    },
    {
        district: "बलरामपुर",
        name: "बुड़न्तपुर"
    },
    {
        district: "बलरामपुर",
        name: "साखीरेत"
    },
    {
        district: "बलरामपुर",
        name: "पटोहाकोट"
    },
    {
        district: "बलरामपुर",
        name: "लैवुड़वा"
    },
    {
        district: "बलरामपुर",
        name: "रूपनगर"
    },
    {
        district: "बलरामपुर",
        name: "मिर्जापुर"
    },
    {
        district: "बलरामपुर",
        name: "बेला"
    },
    {
        district: "बलरामपुर",
        name: "इटईमैदा"
    },
    {
        district: "बलरामपुर",
        name: "ढोवाडाबर"
    },
    {
        district: "बलरामपुर",
        name: "मनियारिया"
    },
    {
        district: "बलरामपुर",
        name: "भिठौढ़ी"
    },
    {
        district: "बलरामपुर",
        name: "बिरदाबनियाभारी"
    },
    {
        district: "बलरामपुर",
        name: "रुस्तमनगर"
    },
    {
        district: "बलरामपुर",
        name: "मोहनजोत"
    },
    {
        district: "बलरामपुर",
        name: "मिलौलीबाघाजोत"
    },
    {
        district: "बलरामपुर",
        name: "मटियरियाकर्मा"
    },
    {
        district: "बलरामपुर",
        name: "फत्तेपुर"
    },
    {
        district: "बलरामपुर",
        name: "नन्दौरी"
    },
    {
        district: "बलरामपुर",
        name: "बौड़िहार"
    },
    {
        district: "बांदा",
        name: "मऊ"
    },
    {
        district: "बांदा",
        name: "इंगुवा"
    },
    {
        district: "बांदा",
        name: "परसौली"
    },
    {
        district: "बांदा",
        name: "कमासिन"
    },
    {
        district: "बांदा",
        name: "बंथरी"
    },
    {
        district: "बांदा",
        name: "तिलौसा"
    },
    {
        district: "बांदा",
        name: "घूरी"
    },
    {
        district: "बांदा",
        name: "ओरन ग्रामीण"
    },
    {
        district: "बांदा",
        name: "बल्लान"
    },
    {
        district: "बांदा",
        name: "दफ्तरा"
    },
    {
        district: "बांदा",
        name: "कोर्रही"
    },
    {
        district: "बांदा",
        name: "उतरवॉ"
    },
    {
        district: "बांदा",
        name: "पिपरगवॉ"
    },
    {
        district: "बांदा",
        name: "सॉडी"
    },
    {
        district: "बांदा",
        name: "छिरहुँटा"
    },
    {
        district: "बांदा",
        name: "मुगूँस"
    },
    {
        district: "बांदा",
        name: "पपरेन्दा"
    },
    {
        district: "बांदा",
        name: "खप्टिहाकलॉ"
    },
    {
        district: "बांदा",
        name: "गोयरामुगली"
    },
    {
        district: "बांदा",
        name: "जारी"
    },
    {
        district: "बांदा",
        name: "मटौंध ग्रमीण"
    },
    {
        district: "बांदा",
        name: "मवईबुजुर्ग"
    },
    {
        district: "बांदा",
        name: "अछरौड़"
    },
    {
        district: "बांदा",
        name: "छनेहरालालपुर"
    },
    {
        district: "बांदा",
        name: "बहादुरपुर कालिंजर"
    },
    {
        district: "बांदा",
        name: "दुबरिया"
    },
    {
        district: "बांदा",
        name: "गुढ़ा कलॉ"
    },
    {
        district: "बांदा",
        name: "महोतरा"
    },
    {
        district: "बांदा",
        name: "बघेलावारी"
    },
    {
        district: "बांदा",
        name: "पल्हरी"
    },
    {
        district: "बांदा",
        name: "डढ़वामानपुर"
    },
    {
        district: "बांदा",
        name: "रामपुर"
    },
    {
        district: "बांदा",
        name: "चन्दवारा"
    },
    {
        district: "बांदा",
        name: "बडागांव"
    },
    {
        district: "बांदा",
        name: "जसपुरा"
    },
    {
        district: "बांदा",
        name: "सिन्धनकला"
    },
    {
        district: "बांदा",
        name: "अलिहा"
    },
    {
        district: "बांदा",
        name: "पंडरी"
    },
    {
        district: "बांदा",
        name: "पतवन"
    },
    {
        district: "बांदा",
        name: "पिण्डारन"
    },
    {
        district: "बांदा",
        name: "समगरा"
    },
    {
        district: "बांदा",
        name: "शिव"
    },
    {
        district: "बांदा",
        name: "सिमौनी"
    },
    {
        district: "बांदा",
        name: "बहेरी"
    },
    {
        district: "बांदा",
        name: "पचोखर"
    },
    {
        district: "बांदा",
        name: "गुमाई"
    },
    {
        district: "बांदा",
        name: "मसुरी"
    },
    {
        district: "बांदा",
        name: "अर्जुनाह"
    },
    {
        district: "बांदा",
        name: "गिरवां"
    },
    {
        district: "बांदा",
        name: "बिलगॉव"
    },
    {
        district: "बरेली",
        name: "रजपुरा"
    },
    {
        district: "बरेली",
        name: "नरायनन Ûला"
    },
    {
        district: "बरेली",
        name: "राजूनÛला"
    },
    {
        district: "बरेली",
        name: "पनबड़िया"
    },
    {
        district: "बरेली",
        name: "सूदनपुर"
    },
    {
        district: "बरेली",
        name: "खजुहाई"
    },
    {
        district: "बरेली",
        name: "जितौर"
    },
    {
        district: "बरेली",
        name: "विरिया नारायनपुर"
    },
    {
        district: "बरेली",
        name: "हाजीपुर"
    },
    {
        district: "बरेली",
        name: "हाफिजÛंज"
    },
    {
        district: "बरेली",
        name: "झाऊनÛला"
    },
    {
        district: "बरेली",
        name: "बसन्तपुर"
    },
    {
        district: "बरेली",
        name: "भूड़ा"
    },
    {
        district: "बरेली",
        name: "Ûोरालोकनाथपुर"
    },
    {
        district: "बरेली",
        name: "Ûहबरा"
    },
    {
        district: "बरेली",
        name: "मोहम्मदÛंज"
    },
    {
        district: "बरेली",
        name: "समसपुर"
    },
    {
        district: "बरेली",
        name: "बहर जाÛीर"
    },
    {
        district: "बरेली",
        name: "अमीर नÛर"
    },
    {
        district: "बरेली",
        name: "जरपा मोहनपुर"
    },
    {
        district: "बरेली",
        name: "अधकटा रब्बानी बेÛम"
    },
    {
        district: "बरेली",
        name: "विहरीपुर अब्दुल रहमान"
    },
    {
        district: "बरेली",
        name: "मधुनÛला"
    },
    {
        district: "बरेली",
        name: "नवादा इमामाबाद"
    },
    {
        district: "बरेली",
        name: "ज्योति जाÛीर"
    },
    {
        district: "बरेली",
        name: "बबूरी बबूरा"
    },
    {
        district: "बरेली",
        name: "कुवरपुर तुलसीपटटी"
    },
    {
        district: "बरेली",
        name: "जिÛनिया भÛवंतपुर"
    },
    {
        district: "बरेली",
        name: "सुनौर देशनÛर"
    },
    {
        district: "बरेली",
        name: "मनौना"
    },
    {
        district: "बरेली",
        name: "जÛतीरा"
    },
    {
        district: "बरेली",
        name: "मऊचन्दपुर"
    },
    {
        district: "बरेली",
        name: "बारीखेड़ा"
    },
    {
        district: "बरेली",
        name: "सुल्तानपुर"
    },
    {
        district: "बरेली",
        name: "मदनापुर"
    },
    {
        district: "बरेली",
        name: "बिहारीपुर"
    },
    {
        district: "बरेली",
        name: "बंडिया खुर्द"
    },
    {
        district: "बरेली",
        name: "कादरÛंज"
    },
    {
        district: "बरेली",
        name: "बेहरा"
    },
    {
        district: "बरेली",
        name: "नÛरिया कलां"
    },
    {
        district: "बरेली",
        name: "पनबड़िया"
    },
    {
        district: "बरेली",
        name: "मनकरी"
    },
    {
        district: "बरेली",
        name: "ठिरिया खेतल"
    },
    {
        district: "बरेली",
        name: "तजपुरा नवदिया"
    },
    {
        district: "बरेली",
        name: "कोहनी प्रतापपुर"
    },
    {
        district: "बरेली",
        name: "सरदारनÛर"
    },
    {
        district: "बरेली",
        name: "बढ़रई कुईंयां"
    },
    {
        district: "बरेली",
        name: "चन्दौआ"
    },
    {
        district: "बरेली",
        name: "लहर"
    },
    {
        district: "बरेली",
        name: "फिरोजपुर"
    },
    {
        district: "भदोही",
        name: "बनकट उपरवार"
    },
    {
        district: "भदोही",
        name: "अमिलौर"
    },
    {
        district: "भदोही",
        name: "बदरी"
    },
    {
        district: "भदोही",
        name: "बारीपुर"
    },
    {
        district: "भदोही",
        name: "बेरासपुर"
    },
    {
        district: "भदोही",
        name: "छेछुआ"
    },
    {
        district: "भदोही",
        name: "दानीपट्टी"
    },
    {
        district: "भदोही",
        name: "दुगुना"
    },
    {
        district: "भदोही",
        name: "गोपालपुर"
    },
    {
        district: "भदोही",
        name: "हरिरामपुर"
    },
    {
        district: "भदोही",
        name: "इटहरा"
    },
    {
        district: "भदोही",
        name: "कलिकमवैया"
    },
    {
        district: "भदोही",
        name: "खेमापुर"
    },
    {
        district: "भदोही",
        name: "कुड़ीकला"
    },
    {
        district: "भदोही",
        name: "कुडीखुर्द"
    },
    {
        district: "भदोही",
        name: "मवैयाथानसिंह"
    },
    {
        district: "भदोही",
        name: "नगरदह"
    },
    {
        district: "भदोही",
        name: "पुरवॉ"
    },
    {
        district: "भदोही",
        name: "सेमराध"
    },
    {
        district: "भदोही",
        name: "तुलसीकला"
    },
    {
        district: "भदोही",
        name: "बहपुरा"
    },
    {
        district: "भदोही",
        name: "फुलवरियों"
    },
    {
        district: "भदोही",
        name: "बेरवॉपहाड़पुर"
    },
    {
        district: "भदोही",
        name: "ओझापुर"
    },
    {
        district: "भदोही",
        name: "गोपालापुर कलिंजरा"
    },
    {
        district: "भदोही",
        name: "इब्राहिमपुर"
    },
    {
        district: "भदोही",
        name: "गुलौरी"
    },
    {
        district: "भदोही",
        name: "बिहरोजपुर"
    },
    {
        district: "भदोही",
        name: "केदारपुर"
    },
    {
        district: "भदोही",
        name: "डीघ"
    },
    {
        district: "भदोही",
        name: "अरई"
    },
    {
        district: "भदोही",
        name: "भभौरी"
    },
    {
        district: "भदोही",
        name: "धनतुलसी"
    },
    {
        district: "भदोही",
        name: "सागररायपुर"
    },
    {
        district: "भदोही",
        name: "नारेपार"
    },
    {
        district: "भदोही",
        name: "मूलापुर"
    },
    {
        district: "भदोही",
        name: "रामपुर"
    },
    {
        district: "भदोही",
        name: "चकनिरंजन"
    },
    {
        district: "भदोही",
        name: "भवानीपुर"
    },
    {
        district: "भदोही",
        name: "जहाँगीराबाद"
    },
    {
        district: "भदोही",
        name: "डेरवॉ"
    },
    {
        district: "भदोही",
        name: "बर्जीकला"
    },
    {
        district: "भदोही",
        name: "सहसेपुर"
    },
    {
        district: "भदोही",
        name: "उमापुर"
    },
    {
        district: "भदोही",
        name: "कठारी"
    },
    {
        district: "भदोही",
        name: "विठ्ठलपुर"
    },
    {
        district: "भदोही",
        name: "द्वारिकापुर"
    },
    {
        district: "भदोही",
        name: "ईटवॉ"
    },
    {
        district: "भदोही",
        name: "कसिदहाँ"
    },
    {
        district: "भदोही",
        name: "मुशीलाटपुर"
    },
    {
        district: "बागपत",
        name: "पाली"
    },
    {
        district: "बागपत",
        name: "सिसाना"
    },
    {
        district: "बागपत",
        name: "निवाडा"
    },
    {
        district: "बागपत",
        name: "गौरीपुर जवाहरनगर"
    },
    {
        district: "बागपत",
        name: "नैथला"
    },
    {
        district: "बागपत",
        name: "फैजुल्लापुर"
    },
    {
        district: "बागपत",
        name: "खेडा इस्लामपुर"
    },
    {
        district: "बागपत",
        name: "सुल्तानपुर हटाना"
    },
    {
        district: "बागपत",
        name: "पुरा"
    },
    {
        district: "बागपत",
        name: "मवीखुर्द"
    },
    {
        district: "बागपत",
        name: "अमीपुर बालैनी"
    },
    {
        district: "बागपत",
        name: "कहरका"
    },
    {
        district: "बागपत",
        name: "मुकारी"
    },
    {
        district: "बागपत",
        name: "घाटौली"
    },
    {
        district: "बागपत",
        name: "डौलचा"
    },
    {
        district: "बागपत",
        name: "शबगा"
    },
    {
        district: "बागपत",
        name: "जागोस"
    },
    {
        district: "बागपत",
        name: "टाण्डा"
    },
    {
        district: "बागपत",
        name: "बदरखा"
    },
    {
        district: "बागपत",
        name: "ककौर"
    },
    {
        district: "बागपत",
        name: "छपरौली"
    },
    {
        district: "बागपत",
        name: "नांगल"
    },
    {
        district: "बागपत",
        name: "कुर्डी"
    },
    {
        district: "बागपत",
        name: "गांगनौली"
    },
    {
        district: "बागपत",
        name: "मौजिजाबाद नांगल"
    },
    {
        district: "बागपत",
        name: "रंछाड"
    },
    {
        district: "बागपत",
        name: "सूजती"
    },
    {
        district: "बागपत",
        name: "हिम्मतपुर"
    },
    {
        district: "बागपत",
        name: "बरनावा"
    },
    {
        district: "बागपत",
        name: "खपराना"
    },
    {
        district: "बागपत",
        name: "मिलाना"
    },
    {
        district: "बागपत",
        name: "फखरपुर शेखपुरा"
    },
    {
        district: "बागपत",
        name: "गल्हैता"
    },
    {
        district: "बागपत",
        name: "शाहपुर बाण गंगा"
    },
    {
        district: "बागपत",
        name: "तवेलागढी"
    },
    {
        district: "बागपत",
        name: "काठा"
    },
    {
        district: "बागपत",
        name: "फुलैरा"
    },
    {
        district: "बागपत",
        name: "पूरनपुर नवादा"
    },
    {
        district: "बागपत",
        name: "सिंगोलीतगा"
    },
    {
        district: "बागपत",
        name: "शरफाबाद"
    },
    {
        district: "बागपत",
        name: "गढीकलंजरी"
    },
    {
        district: "बागपत",
        name: "ललियाना"
    },
    {
        district: "बागपत",
        name: "गौना शहवानपुर"
    },
    {
        district: "बागपत",
        name: "सांकरौद"
    },
    {
        district: "बागपत",
        name: "सुभानपुर"
    },
    {
        district: "बागपत",
        name: "हसनपुर मसूरी"
    },
    {
        district: "बागपत",
        name: "डुन्डाहैडा"
    },
    {
        district: "बागपत",
        name: "मवीकलां"
    },
    {
        district: "बागपत",
        name: "बसी"
    },
    {
        district: "बागपत",
        name: "खेकडा"
    },
    {
        district: "बिजनौर",
        name: "रवाना शिकारपुर"
    },
    {
        district: "बिजनौर",
        name: "पित्थापुर"
    },
    {
        district: "बिजनौर",
        name: "बाजीपुर जाÛीर"
    },
    {
        district: "बिजनौर",
        name: "लाम्बा खेड़ा"
    },
    {
        district: "बिजनौर",
        name: "अमीनाबाद"
    },
    {
        district: "बिजनौर",
        name: "अजीमुल्लानÛर"
    },
    {
        district: "बिजनौर",
        name: "हरेवली"
    },
    {
        district: "बिजनौर",
        name: "रामनÛर"
    },
    {
        district: "बिजनौर",
        name: "भटपुरा"
    },
    {
        district: "बिजनौर",
        name: "खारी"
    },
    {
        district: "बिजनौर",
        name: "दारानÛर"
    },
    {
        district: "बिजनौर",
        name: "पृथ्वीपुर"
    },
    {
        district: "बिजनौर",
        name: "हरदासपुर Ûढी"
    },
    {
        district: "बिजनौर",
        name: "अÛरी"
    },
    {
        district: "बिजनौर",
        name: "तिबडी"
    },
    {
        district: "बिजनौर",
        name: "हाफिजाबाद शर्की"
    },
    {
        district: "बिजनौर",
        name: "पीपलसाना"
    },
    {
        district: "बिजनौर",
        name: "लतीफपुर"
    },
    {
        district: "बिजनौर",
        name: "सरकथल माधो"
    },
    {
        district: "बिजनौर",
        name: "रसूलपुर नंÛला"
    },
    {
        district: "बिजनौर",
        name: "रेहरा"
    },
    {
        district: "बिजनौर",
        name: "कमालपुर"
    },
    {
        district: "बिजनौर",
        name: "पीपलीजट"
    },
    {
        district: "बिजनौर",
        name: "सरकथल"
    },
    {
        district: "बिजनौर",
        name: "अकबराबाद"
    },
    {
        district: "बिजनौर",
        name: "मेमनसादात"
    },
    {
        district: "बिजनौर",
        name: "बुडÛरा"
    },
    {
        district: "बिजनौर",
        name: "Ûनौरा"
    },
    {
        district: "बिजनौर",
        name: "शहलीपुर कोटरा"
    },
    {
        district: "बिजनौर",
        name: "पूरैनी"
    },
    {
        district: "बिजनौर",
        name: "शादीपुर"
    },
    {
        district: "बिजनौर",
        name: "कस्बा कोटरा"
    },
    {
        district: "बिजनौर",
        name: "मौज्जमपुर हरवंश"
    },
    {
        district: "बिजनौर",
        name: "तैमूरपुर दीपा"
    },
    {
        district: "बिजनौर",
        name: "काजीवाला"
    },
    {
        district: "बिजनौर",
        name: "अमीरपुरदास उर्फ धर्मनÛरी"
    },
    {
        district: "बिजनौर",
        name: "मुकीमपुर धारू"
    },
    {
        district: "बिजनौर",
        name: "बसेड़ा खुर्द"
    },
    {
        district: "बिजनौर",
        name: "बेÛराजपुर"
    },
    {
        district: "बिजनौर",
        name: "पा.डली मा.डू"
    },
    {
        district: "बिजनौर",
        name: "पपसरा"
    },
    {
        district: "बिजनौर",
        name: "करीमपुर मुबारक"
    },
    {
        district: "बिजनौर",
        name: "शेखपुर Ûढू"
    },
    {
        district: "बिजनौर",
        name: "सराय आलम"
    },
    {
        district: "बिजनौर",
        name: "राहतपुर खुर्द"
    },
    {
        district: "बिजनौर",
        name: "शाहजहाॅपुर जसरथ"
    },
    {
        district: "बिजनौर",
        name: "पैजनियाॅ"
    },
    {
        district: "बिजनौर",
        name: "शिवाला कला"
    },
    {
        district: "बिजनौर",
        name: "मझौला बिल्लौच"
    },
    {
        district: "बिजनौर",
        name: "सेला"
    },
    {
        district: "बुलन्दशहर",
        name: "मुवारिकपुर बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "अहार बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "हसनपुर बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "सिरौरा बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "अकबरपुर उर्फ बच्चीखेडा बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "अनूपशहर बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "रूठ बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "भैरियाहरिद्वारपुर बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "उदयपुर खुर्द बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "कर्णवास बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "नयावास बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "बिलौनारूप बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "राजघाट बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "उदयगढी बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "नरौरा बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "नौदेई बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "रामघाट बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "कुराला"
    },
    {
        district: "बुलन्दशहर",
        name: "काहिरा"
    },
    {
        district: "बुलन्दशहर",
        name: "कोटा"
    },
    {
        district: "बुलन्दशहर",
        name: "लखावठी"
    },
    {
        district: "बुलन्दशहर",
        name: "लौहगरा"
    },
    {
        district: "बुलन्दशहर",
        name: "परताबपुर"
    },
    {
        district: "बुलन्दशहर",
        name: "चिंगराबठी"
    },
    {
        district: "बुलन्दशहर",
        name: "वैरा फिरोजपुर"
    },
    {
        district: "बुलन्दशहर",
        name: "देहरा"
    },
    {
        district: "बुलन्दशहर",
        name: "रवानी कटीरी बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "औरंगाबाद ताहरपुर बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "मांडू हसनगढी बांगर"
    },
    {
        district: "बुलन्दशहर",
        name: "फतहपुर गुर्जर"
    },
    {
        district: "बुलन्दशहर",
        name: "जावल"
    },
    {
        district: "बुलन्दशहर",
        name: "मुनी"
    },
    {
        district: "बुलन्दशहर",
        name: "करौरा"
    },
    {
        district: "बुलन्दशहर",
        name: "वाहनपुर"
    },
    {
        district: "बुलन्दशहर",
        name: "हसनपुर"
    },
    {
        district: "बुलन्दशहर",
        name: "धरपा चूहरपुर"
    },
    {
        district: "बुलन्दशहर",
        name: "अरनिया मौजपुर"
    },
    {
        district: "बुलन्दशहर",
        name: "आंचरू खुर्द"
    },
    {
        district: "बुलन्दशहर",
        name: "पूठरी खुर्द .."
    },
    {
        district: "बुलन्दशहर",
        name: "खैरपुर"
    },
    {
        district: "बुलन्दशहर",
        name: "रिवाडा"
    },
    {
        district: "बुलन्दशहर",
        name: "करीरां"
    },
    {
        district: "बुलन्दशहर",
        name: "हुर्थला"
    },
    {
        district: "बुलन्दशहर",
        name: "बरासऊ"
    },
    {
        district: "बुलन्दशहर",
        name: "इस्माईलपुर"
    },
    {
        district: "बुलन्दशहर",
        name: "चन्देरू"
    },
    {
        district: "बुलन्दशहर",
        name: "आशादेई उर्फ प्रानगढ़"
    },
    {
        district: "बुलन्दशहर",
        name: "भौखेडा"
    },
    {
        district: "बुलन्दशहर",
        name: "हसनपुर जागीर"
    },
    {
        district: "बुलन्दशहर",
        name: "सुनपेड़ा"
    },
    {
        district: "बुलन्दशहर",
        name: "निठारी"
    },
    {
        district: "चन्दौली",
        name: "भतीजा"
    },
    {
        district: "चन्दौली",
        name: "देवकली"
    },
    {
        district: "चन्दौली",
        name: "सोगाई"
    },
    {
        district: "चन्दौली",
        name: "ओयरचक"
    },
    {
        district: "चन्दौली",
        name: "जमुडा"
    },
    {
        district: "चन्दौली",
        name: "पानी"
    },
    {
        district: "चन्दौली",
        name: "धपरी"
    },
    {
        district: "चन्दौली",
        name: "भोजपुर"
    },
    {
        district: "चन्दौली",
        name: "गांधना"
    },
    {
        district: "चन्दौली",
        name: "जफरपुर"
    },
    {
        district: "चन्दौली",
        name: "गरछा"
    },
    {
        district: "चन्दौली",
        name: ""
    },
    {
        district: "चन्दौली",
        name: ""
    },
    {
        district: "चन्दौली",
        name: ""
    },
    {
        district: "चन्दौली",
        name: "रामगढ "
    },
    {
        district: "चन्दौली",
        name: "सेवरी "
    },
    {
        district: "चन्दौली",
        name: ""
    },
    {
        district: "चन्दौली",
        name: "टांडाकला"
    },
    {
        district: "चन्दौली",
        name: ""
    },
    {
        district: "चन्दौली",
        name: "बाघी"
    },
    {
        district: "चन्दौली",
        name: "जगमोहनी"
    },
    {
        district: "चन्दौली",
        name: "सोनवर"
    },
    {
        district: "चन्दौली",
        name: "पीतपुर"
    },
    {
        district: "चन्दौली",
        name: "गनेशपुर"
    },
    {
        district: "चन्दौली",
        name: "पुरानाडीह"
    },
    {
        district: "चन्दौली",
        name: "हिनौता द०"
    },
    {
        district: "चन्दौली",
        name: "मुजपफरपुर"
    },
    {
        district: "चन्दौली",
        name: "हंतिमपुर"
    },
    {
        district: "चन्दौली",
        name: "परासीकला"
    },
    {
        district: "चन्दौली",
        name: "मद्भूपुर"
    },
    {
        district: "चन्दौली",
        name: "पड्या"
    },
    {
        district: "चन्दौली",
        name: "पैतुआरू"
    },
    {
        district: "चन्दौली",
        name: "चनहटा"
    },
    {
        district: "चन्दौली",
        name: "भिखारीपुर"
    },
    {
        district: "चन्दौली",
        name: "खास"
    },
    {
        district: "चन्दौली",
        name: "सरिंगपुर"
    },
    {
        district: "चन्दौली",
        name: "करनील"
    },
    {
        district: "चन्दौली",
        name: "जैगुरी"
    },
    {
        district: "चन्दौली",
        name: "सेमरा"
    },
    {
        district: "चन्दौली",
        name: "नौडिहा"
    },
    {
        district: "चन्दौली",
        name: "पसहटा"
    },
    {
        district: "चन्दौली",
        name: "सेनहुलीरू"
    },
    {
        district: "चन्दौली",
        name: "बुद्धपुर"
    },
    {
        district: "चन्दौली",
        name: "प्रहलादपुर"
    },
    {
        district: "चन्दौली",
        name: "पंचगंगापुर"
    },
    {
        district: "चन्दौली",
        name: "कवलपुरा"
    },
    {
        district: "चन्दौली",
        name: "धरहरा"
    },
    {
        district: "चन्दौली",
        name: "कम्हारी"
    },
    {
        district: "चन्दौली",
        name: "फुल्ली"
    },
    {
        district: "चन्दौली",
        name: "डेढगावां"
    },
    {
        district: "देवरिया",
        name: "नगवा खास"
    },
    {
        district: "देवरिया",
        name: "गायधार"
    },
    {
        district: "देवरिया",
        name: "कृतपुरा"
    },
    {
        district: "देवरिया",
        name: "धरमपुर"
    },
    {
        district: "देवरिया",
        name: "पचरुखा"
    },
    {
        district: "देवरिया",
        name: "एकौना"
    },
    {
        district: "देवरिया",
        name: "इस्लामाबाद"
    },
    {
        district: "देवरिया",
        name: "टिघरा खैरवा"
    },
    {
        district: "देवरिया",
        name: "रतनपुर"
    },
    {
        district: "देवरिया",
        name: "होली बलिया"
    },
    {
        district: "देवरिया",
        name: "हड़हा"
    },
    {
        district: "देवरिया",
        name: "माझा नारायण"
    },
    {
        district: "देवरिया",
        name: "कोडर"
    },
    {
        district: "देवरिया",
        name: "परसिया देवार"
    },
    {
        district: "देवरिया",
        name: "विशुनपुर देवार"
    },
    {
        district: "देवरिया",
        name: "भदिला प्रथम"
    },
    {
        district: "देवरिया",
        name: "गौरा कटइलवा"
    },
    {
        district: "देवरिया",
        name: "कपरवार"
    },
    {
        district: "देवरिया",
        name: "बेलडाड"
    },
    {
        district: "देवरिया",
        name: "परसिया तिवारी"
    },
    {
        district: "देवरिया",
        name: "माहेन"
    },
    {
        district: "देवरिया",
        name: "मोहरा"
    },
    {
        district: "देवरिया",
        name: "समोगर"
    },
    {
        district: "देवरिया",
        name: "भदिला दोयम"
    },
    {
        district: "देवरिया",
        name: "कोटवा"
    },
    {
        district: "देवरिया",
        name: "पैना"
    },
    {
        district: "देवरिया",
        name: "नवापार"
    },
    {
        district: "देवरिया",
        name: "लवकनी गंगा"
    },
    {
        district: "देवरिया",
        name: "देईडिंहा"
    },
    {
        district: "देवरिया",
        name: "मईल"
    },
    {
        district: "देवरिया",
        name: "मौना गढ़वा"
    },
    {
        district: "देवरिया",
        name: "तेलिया अफगान"
    },
    {
        district: "देवरिया",
        name: "बकुची 2"
    },
    {
        district: "देवरिया",
        name: "नरियावं"
    },
    {
        district: "देवरिया",
        name: "देवसिया"
    },
    {
        district: "देवरिया",
        name: "छित्तूपुर"
    },
    {
        district: "देवरिया",
        name: "नरसिंहडाड़"
    },
    {
        district: "देवरिया",
        name: "भागलपुर"
    },
    {
        district: "देवरिया",
        name: "पिपराबांध"
    },
    {
        district: "देवरिया",
        name: "तेलिया कलां"
    },
    {
        district: "देवरिया",
        name: "बढौना हरदो"
    },
    {
        district: "देवरिया",
        name: "देउबारी"
    },
    {
        district: "देवरिया",
        name: "डुमरी"
    },
    {
        district: "देवरिया",
        name: "खरवनिया"
    },
    {
        district: "देवरिया",
        name: "पिण्डी"
    },
    {
        district: "देवरिया",
        name: "महलमंझरिया"
    },
    {
        district: "देवरिया",
        name: "तकिया धरहरा"
    },
    {
        district: "देवरिया",
        name: "सहियागढ़"
    },
    {
        district: "देवरिया",
        name: "चुरिया"
    },
    {
        district: "देवरिया",
        name: "नेमा"
    },
    {
        district: "एटा",
        name: "सराय अघात"
    },
    {
        district: "एटा",
        name: "अलीपुर"
    },
    {
        district: "एटा",
        name: "अंगराया जमुनाई"
    },
    {
        district: "एटा",
        name: "खरसुलिया"
    },
    {
        district: "एटा",
        name: "उभै असादनगर"
    },
    {
        district: "एटा",
        name: "अलीगंज देहात "
    },
    {
        district: "एटा",
        name: "सिकंदर सलवार"
    },
    {
        district: "एटा",
        name: "नादरला"
    },
    {
        district: "एटा",
        name: "राया"
    },
    {
        district: "एटा",
        name: "रोहिणा मिर्जापुर"
    },
    {
        district: "एटा",
        name: "मजराजत अवागढ़"
    },
    {
        district: "एटा",
        name: "टीकाथर"
    },
    {
        district: "एटा",
        name: "जरानी खुर्द कलॉं"
    },
    {
        district: "एटा",
        name: "बोर्रा कलां "
    },
    {
        district: "एटा",
        name: "नूह खाश "
    },
    {
        district: "एटा",
        name: "बारै कल्यानपुर"
    },
    {
        district: "एटा",
        name: "माजरा जट्ट जैथरा "
    },
    {
        district: "एटा",
        name: "धुमरी "
    },
    {
        district: "एटा",
        name: "तरिगवां "
    },
    {
        district: "एटा",
        name: "पारोली सुहागपुर "
    },
    {
        district: "एटा",
        name: "सहोरी "
    },
    {
        district: "एटा",
        name: "किरिया नगर शाह "
    },
    {
        district: "एटा",
        name: "फागनोल "
    },
    {
        district: "एटा",
        name: "बाहगो "
    },
    {
        district: "एटा",
        name: "भौपुरा "
    },
    {
        district: "एटा",
        name: "शकरोली "
    },
    {
        district: "एटा",
        name: "साह नगर तिमरुआ "
    },
    {
        district: "एटा",
        name: "विचपुरी "
    },
    {
        district: "एटा",
        name: "ऊचा गॉंव"
    },
    {
        district: "एटा",
        name: "चिरगवान"
    },
    {
        district: "एटा",
        name: "महामाई"
    },
    {
        district: "एटा",
        name: "नगल मिठान "
    },
    {
        district: "एटा",
        name: "अहमद नगर बमनोई "
    },
    {
        district: "एटा",
        name: "बारै "
    },
    {
        district: "एटा",
        name: "पिलुआ "
    },
    {
        district: "एटा",
        name: "ढोलेश्वर "
    },
    {
        district: "एटा",
        name: "बबसा "
    },
    {
        district: "एटा",
        name: "गहेतु "
    },
    {
        district: "एटा",
        name: "सोगरा "
    },
    {
        district: "एटा",
        name: "बसुधारा "
    },
    {
        district: "एटा",
        name: "रफत नगर सैथरा "
    },
    {
        district: "एटा",
        name: "खेड़ा निजामपुर "
    },
    {
        district: "एटा",
        name: "मनोरा "
    },
    {
        district: "एटा",
        name: "मलावन "
    },
    {
        district: "एटा",
        name: "चिलसनी "
    },
    {
        district: "एटा",
        name: "जिरसामी "
    },
    {
        district: "एटा",
        name: "भागीपुर "
    },
    {
        district: "एटा",
        name: "बर्थर "
    },
    {
        district: "एटा",
        name: "रारपट्टी "
    },
    {
        district: "एटा",
        name: "शीतलपुर"
    },
    {
        district: "इटावा",
        name: "टकरूपुरा"
    },
    {
        district: "इटावा",
        name: "पुरावली"
    },
    {
        district: "इटावा",
        name: "नन्दगवां"
    },
    {
        district: "इटावा",
        name: "नंगला बिलहटी"
    },
    {
        district: "इटावा",
        name: "इकनौर"
    },
    {
        district: "इटावा",
        name: "दिलीप नगर"
    },
    {
        district: "इटावा",
        name: "अन्दावा"
    },
    {
        district: "इटावा",
        name: "चिण्डौली"
    },
    {
        district: "इटावा",
        name: "बहादुरपुर घार"
    },
    {
        district: "इटावा",
        name: "ईश्वरीपुरा"
    },
    {
        district: "इटावा",
        name: "हरौली बहादुरपुर"
    },
    {
        district: "इटावा",
        name: "गढ़ा कास्दा"
    },
    {
        district: "इटावा",
        name: "भरेह"
    },
    {
        district: "इटावा",
        name: "अनेठा"
    },
    {
        district: "इटावा",
        name: "छिबरौली"
    },
    {
        district: "इटावा",
        name: "पथर्रा"
    },
    {
        district: "इटावा",
        name: "कंधेसीघार"
    },
    {
        district: "इटावा",
        name: "चॉदई"
    },
    {
        district: "इटावा",
        name: "कुन्दौल"
    },
    {
        district: "इटावा",
        name: "तेजपुरा"
    },
    {
        district: "इटावा",
        name: "ददरा"
    },
    {
        district: "इटावा",
        name: "कचहरी"
    },
    {
        district: "इटावा",
        name: "नीमरी"
    },
    {
        district: "इटावा",
        name: "बिडौरी"
    },
    {
        district: "इटावा",
        name: "अनेठा"
    },
    {
        district: "इटावा",
        name: "विठौली"
    },
    {
        district: "इटावा",
        name: "बंसरी"
    },
    {
        district: "इटावा",
        name: "चैरेला"
    },
    {
        district: "इटावा",
        name: "सहसों"
    },
    {
        district: "इटावा",
        name: "विहार"
    },
    {
        district: "इटावा",
        name: "सिण्डौस"
    },
    {
        district: "इटावा",
        name: "गोहानी"
    },
    {
        district: "इटावा",
        name: "नौगवां"
    },
    {
        district: "इटावा",
        name: "खिरीटी"
    },
    {
        district: "इटावा",
        name: "जगतौली"
    },
    {
        district: "इटावा",
        name: "हनुमन्तपुरा"
    },
    {
        district: "इटावा",
        name: "कुन्दौल"
    },
    {
        district: "इटावा",
        name: "बरेछा"
    },
    {
        district: "इटावा",
        name: "महुआ सूँडा"
    },
    {
        district: "इटावा",
        name: "पछांयगांव"
    },
    {
        district: "इटावा",
        name: "बसबारा"
    },
    {
        district: "इटावा",
        name: "धमना"
    },
    {
        district: "इटावा",
        name: "बढ़पुरा"
    },
    {
        district: "इटावा",
        name: "जरहौली"
    },
    {
        district: "इटावा",
        name: "मुसावली"
    },
    {
        district: "इटावा",
        name: "मिहौली"
    },
    {
        district: "इटावा",
        name: "गाती"
    },
    {
        district: "इटावा",
        name: "प्रतापनेर"
    },
    {
        district: "इटावा",
        name: "सितौरा"
    },
    {
        district: "इटावा",
        name: "बराखेडा"
    },
    {
        district: "फर्रुखाबाद",
        name: "रजलामई"
    },
    {
        district: "फर्रुखाबाद",
        name: "शरीफपुर छिछनी"
    },
    {
        district: "फर्रुखाबाद",
        name: "रमापुरजसू"
    },
    {
        district: "फर्रुखाबाद",
        name: "मंझना"
    },
    {
        district: "फर्रुखाबाद",
        name: "नटवारा"
    },
    {
        district: "फर्रुखाबाद",
        name: "हुसेनपुर तराई"
    },
    {
        district: "फर्रुखाबाद",
        name: "उखरा"
    },
    {
        district: "फर्रुखाबाद",
        name: "सिरौली"
    },
    {
        district: "फर्रुखाबाद",
        name: "पखना"
    },
    {
        district: "फर्रुखाबाद",
        name: "सितवनपुर पिथू"
    },
    {
        district: "फर्रुखाबाद",
        name: "बिहार"
    },
    {
        district: "फर्रुखाबाद",
        name: "बनकटी"
    },
    {
        district: "फर्रुखाबाद",
        name: "जाजपुर बंजारा"
    },
    {
        district: "फर्रुखाबाद",
        name: "रूखौया खालिकदादपुर"
    },
    {
        district: "फर्रुखाबाद",
        name: "कोकापुर"
    },
    {
        district: "फर्रुखाबाद",
        name: "रसीदपुर मई"
    },
    {
        district: "फर्रुखाबाद",
        name: "भटासा"
    },
    {
        district: "फर्रुखाबाद",
        name: "रमापुर दवीर"
    },
    {
        district: "फर्रुखाबाद",
        name: "पहाडपुर"
    },
    {
        district: "फर्रुखाबाद",
        name: "जनैया सितैया"
    },
    {
        district: "फर्रुखाबाद",
        name: "बसेली"
    },
    {
        district: "फर्रुखाबाद",
        name: "रम्पुरा"
    },
    {
        district: "फर्रुखाबाद",
        name: "भाऊपुर खुर्द"
    },
    {
        district: "फर्रुखाबाद",
        name: "हैवतपुर गढिया"
    },
    {
        district: "फर्रुखाबाद",
        name: "कुबेरपुर घाट"
    },
    {
        district: "फर्रुखाबाद",
        name: "घारमपुर"
    },
    {
        district: "फर्रुखाबाद",
        name: "अजमतपुर"
    },
    {
        district: "फर्रुखाबाद",
        name: "पिथूपुर मेहदिया"
    },
    {
        district: "फर्रुखाबाद",
        name: "अताईपुर कोहना"
    },
    {
        district: "फर्रुखाबाद",
        name: "अल्हापुर"
    },
    {
        district: "फर्रुखाबाद",
        name: "रूटौल"
    },
    {
        district: "फर्रुखाबाद",
        name: "जिजपुरा"
    },
    {
        district: "फर्रुखाबाद",
        name: "चिलौली"
    },
    {
        district: "फर्रुखाबाद",
        name: "त्योरखास"
    },
    {
        district: "फर्रुखाबाद",
        name: "बिल्हा"
    },
    {
        district: "फर्रुखाबाद",
        name: "शाहीपुर"
    },
    {
        district: "फर्रुखाबाद",
        name: "सवासी"
    },
    {
        district: "फर्रुखाबाद",
        name: "भरखा"
    },
    {
        district: "फर्रुखाबाद",
        name: "बदनपुर"
    },
    {
        district: "फर्रुखाबाद",
        name: "कमालुददीनपुर"
    },
    {
        district: "फर्रुखाबाद",
        name: "सथरा"
    },
    {
        district: "फर्रुखाबाद",
        name: "राई"
    },
    {
        district: "फर्रुखाबाद",
        name: "कडक्का"
    },
    {
        district: "फर्रुखाबाद",
        name: "जैनापुर महेशपुर"
    },
    {
        district: "फर्रुखाबाद",
        name: "भुवनपुर ताजपुर"
    },
    {
        district: "फर्रुखाबाद",
        name: "हिसामपुर"
    },
    {
        district: "फर्रुखाबाद",
        name: "चैकी महमदपुर"
    },
    {
        district: "फर्रुखाबाद",
        name: "बन्दरखेडा"
    },
    {
        district: "फर्रुखाबाद",
        name: "अहमदपुर देवरिया"
    },
    {
        district: "फर्रुखाबाद",
        name: "नसरतपुर नौंगवा"
    },
    {
        district: "फिरोजाबाद",
        name: "बामई"
    },
    {
        district: "फिरोजाबाद",
        name: "करहरा"
    },
    {
        district: "फिरोजाबाद",
        name: "पीथेपुर"
    },
    {
        district: "फिरोजाबाद",
        name: "विनेय कुमार"
    },
    {
        district: "फिरोजाबाद",
        name: "कारीखेडा"
    },
    {
        district: "फिरोजाबाद",
        name: "पतारा"
    },
    {
        district: "फिरोजाबाद",
        name: "कादीपुर"
    },
    {
        district: "फिरोजाबाद",
        name: "भदाना"
    },
    {
        district: "फिरोजाबाद",
        name: "भगनेर"
    },
    {
        district: "फिरोजाबाद",
        name: "रामपुर"
    },
    {
        district: "फिरोजाबाद",
        name: "बिलहना"
    },
    {
        district: "फिरोजाबाद",
        name: "इटौरा"
    },
    {
        district: "फिरोजाबाद",
        name: "सेंगई"
    },
    {
        district: "फिरोजाबाद",
        name: "विजयपुर न० भावसिंह"
    },
    {
        district: "फिरोजाबाद",
        name: "मोहम्मदपुर बिहारीपुर"
    },
    {
        district: "फिरोजाबाद",
        name: "आलमपुर जारखी"
    },
    {
        district: "फिरोजाबाद",
        name: "इटाहरी"
    },
    {
        district: "फिरोजाबाद",
        name: "कनवारा"
    },
    {
        district: "फिरोजाबाद",
        name: "कटैना हर्षा"
    },
    {
        district: "फिरोजाबाद",
        name: "शेखनपुर"
    },
    {
        district: "फिरोजाबाद",
        name: "रीवा"
    },
    {
        district: "फिरोजाबाद",
        name: "रानीपुर"
    },
    {
        district: "फिरोजाबाद",
        name: "उपेन्द्र कुमार"
    },
    {
        district: "फिरोजाबाद",
        name: "बिठवारा"
    },
    {
        district: "फिरोजाबाद",
        name: "जाजुमई"
    },
    {
        district: "फिरोजाबाद",
        name: "खेरीया पटिकरा"
    },
    {
        district: "फिरोजाबाद",
        name: "सलेमपुर कर्खा"
    },
    {
        district: "फिरोजाबाद",
        name: "पुनच्छा"
    },
    {
        district: "फिरोजाबाद",
        name: "केसरी"
    },
    {
        district: "फिरोजाबाद",
        name: "कुतुकपुर शेरपुर"
    },
    {
        district: "फिरोजाबाद",
        name: "आंख खुर्द"
    },
    {
        district: "फिरोजाबाद",
        name: "कठफोरी"
    },
    {
        district: "फिरोजाबाद",
        name: "बछगांव"
    },
    {
        district: "फिरोजाबाद",
        name: "दौलतपुर"
    },
    {
        district: "फिरोजाबाद",
        name: "गांगनी"
    },
    {
        district: "फिरोजाबाद",
        name: "गोंछ"
    },
    {
        district: "फिरोजाबाद",
        name: "जौधरी"
    },
    {
        district: "फिरोजाबाद",
        name: "कार्तिकी"
    },
    {
        district: "फिरोजाबाद",
        name: "असुवा"
    },
    {
        district: "फिरोजाबाद",
        name: "वासुदेवमई"
    },
    {
        district: "फिरोजाबाद",
        name: "हरिया"
    },
    {
        district: "फिरोजाबाद",
        name: "साडुपुर"
    },
    {
        district: "फिरोजाबाद",
        name: "रुपसपुर"
    },
    {
        district: "फिरोजाबाद",
        name: "जाफराबाद"
    },
    {
        district: "फिरोजाबाद",
        name: "हरगनपुर"
    },
    {
        district: "फिरोजाबाद",
        name: "देवखेडा"
    },
    {
        district: "फिरोजाबाद",
        name: "गढ़ी निर्भय"
    },
    {
        district: "फिरोजाबाद",
        name: "कोटकी"
    },
    {
        district: "फिरोजाबाद",
        name: "रसुलाबाद"
    },
    {
        district: "फिरोजाबाद",
        name: "शेरपुर मण्डनपुर"
    },
    {
        district: "फतेहपुर",
        name: "अल्लीपुर बहेरा"
    },
    {
        district: "फतेहपुर",
        name: "अल्लीपुर "
    },
    {
        district: "फतेहपुर",
        name: "बुदवन"
    },
    {
        district: "फतेहपुर",
        name: "विक्रमपुर"
    },
    {
        district: "फतेहपुर",
        name: "बेहटा"
    },
    {
        district: "फतेहपुर",
        name: "अमौली"
    },
    {
        district: "फतेहपुर",
        name: "सराय धर्मपुर"
    },
    {
        district: "फतेहपुर",
        name: "कोठा बखरिया"
    },
    {
        district: "फतेहपुर",
        name: "ललौली"
    },
    {
        district: "फतेहपुर",
        name: "कौण्डर"
    },
    {
        district: "फतेहपुर",
        name: "देवलान"
    },
    {
        district: "फतेहपुर",
        name: "धरमपुर सुसवन खुर्द"
    },
    {
        district: "फतेहपुर",
        name: "खटौली"
    },
    {
        district: "फतेहपुर",
        name: "शाह"
    },
    {
        district: "फतेहपुर",
        name: "लदिगवा"
    },
    {
        district: "फतेहपुर",
        name: "समियाना"
    },
    {
        district: "फतेहपुर",
        name: "देवरानार"
    },
    {
        district: "फतेहपुर",
        name: "लखपुरा"
    },
    {
        district: "फतेहपुर",
        name: "सेनपुर"
    },
    {
        district: "फतेहपुर",
        name: "जमरावा"
    },
    {
        district: "फतेहपुर",
        name: "देवमई"
    },
    {
        district: "फतेहपुर",
        name: "गलाथा"
    },
    {
        district: "फतेहपुर",
        name: "करचलपुर"
    },
    {
        district: "फतेहपुर",
        name: "टिकरा"
    },
    {
        district: "फतेहपुर",
        name: "डेंडासाही"
    },
    {
        district: "फतेहपुर",
        name: "हाकिमपुर खन्तवा"
    },
    {
        district: "फतेहपुर",
        name: "कुसुम्भा"
    },
    {
        district: "फतेहपुर",
        name: "जाम"
    },
    {
        district: "फतेहपुर",
        name: "हसवा"
    },
    {
        district: "फतेहपुर",
        name: "टेक्सारी बुजुर्ग "
    },
    {
        district: "फतेहपुर",
        name: "कुसुम्भी"
    },
    {
        district: "फतेहपुर",
        name: "सातोपीत"
    },
    {
        district: "फतेहपुर",
        name: "गोपालपुर तेल्हा"
    },
    {
        district: "फतेहपुर",
        name: "छिवलहा"
    },
    {
        district: "फतेहपुर",
        name: "बैगांव"
    },
    {
        district: "फतेहपुर",
        name: "सुरजीपुर"
    },
    {
        district: "फतेहपुर",
        name: "सिजौली"
    },
    {
        district: "फतेहपुर",
        name: "बागबादशाही"
    },
    {
        district: "फतेहपुर",
        name: "टिकरी मनौटी"
    },
    {
        district: "फतेहपुर",
        name: "शहबाजपुर"
    },
    {
        district: "फतेहपुर",
        name: "कंसपुर गुगौली"
    },
    {
        district: "फतेहपुर",
        name: "मलवां"
    },
    {
        district: "फतेहपुर",
        name: "मौहार"
    },
    {
        district: "फतेहपुर",
        name: "जाफराबाद"
    },
    {
        district: "फतेहपुर",
        name: "सरायं सहिजादा"
    },
    {
        district: "फतेहपुर",
        name: "हसनापुर सानी"
    },
    {
        district: "फतेहपुर",
        name: "मन्झूपुर"
    },
    {
        district: "फतेहपुर",
        name: "त्रिलोचनपुर"
    },
    {
        district: "फतेहपुर",
        name: "खासमऊ"
    },
    {
        district: "फतेहपुर",
        name: "विजयीपुर"
    },
    {
        district: "फतेहपुर",
        name: "गोदौरा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "आकलपुर जागीर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "बिश्नोली"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "दुरियाई"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "गिरधरपुर सुनारसी"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "बादलपुर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "कचेंड़ा वारसाबाद"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "खंगोड़ा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "सादीपुर छीड़ोली"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "ऊँचामीरपुर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "बिसहड़ा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "रनोली लतीफपुर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "सलारपुर कलॉ"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "इस्लामाबाद कल्दा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "आनन्दपुर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "द्यछौलस"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "दतावली"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "कैमराला चकसेनपुर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "कलोंदा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "कटहैरा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "खटाना धीरखेड़ा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "नंगला नेनसुख"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "नूरपुर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "फूलपुर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "शहापुर खुर्द"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "रामगढ़ चमरावली"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "समाऊद्दीनपुर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "सैंथली"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "मुठियानी"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "जॉरचा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "कोट"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "लुहारली"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "चिटेहरा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "चीती"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "मण्डपा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "राजपुर कलॉ"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "शहपुर खुर्द"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "लोदाना"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "माहरा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "भवोकरा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "भगवन्तपुर छातंगा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "जेवर खादर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "झुप्पा"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "मेवला गोपालगढ़"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "कानीगढ़ी"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "अलियाबाद उर्फ महन्दीपुर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "जहांगीरपुर देहात"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "चुहणपुर बांगर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "सालेपुर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "जमालपुर"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "बांजरपुर"
    },
    {
        district: "गाजियाबाद",
        name: "मण्डौला"
    },
    {
        district: "गाजियाबाद",
        name: "नानू"
    },
    {
        district: "गाजियाबाद",
        name: "बदरपुर"
    },
    {
        district: "गाजियाबाद",
        name: "मीरपुर हिन्दू"
    },
    {
        district: "गाजियाबाद",
        name: "पचायरा"
    },
    {
        district: "गाजियाबाद",
        name: "अल्लीपुर नोरसपुर"
    },
    {
        district: "गाजियाबाद",
        name: "सिरौरा सलेमपुर"
    },
    {
        district: "गाजियाबाद",
        name: "मुर्तजाबाद भूपखेडी"
    },
    {
        district: "गाजियाबाद",
        name: "महमूदपुर"
    },
    {
        district: "गाजियाबाद",
        name: "भनैडा खुर्द"
    },
    {
        district: "गाजियाबाद",
        name: "अफजलपुर"
    },
    {
        district: "गाजियाबाद",
        name: "शरीफाबाद राजपुर"
    },
    {
        district: "गाजियाबाद",
        name: "असालतपुर फर्रुखनगर"
    },
    {
        district: "गाजियाबाद",
        name: "चिरौडी"
    },
    {
        district: "गाजियाबाद",
        name: "शकलपुरा"
    },
    {
        district: "गाजियाबाद",
        name: "जाफराबाद गनौली"
    },
    {
        district: "गाजियाबाद",
        name: "बादशाहपुर सिरौली"
    },
    {
        district: "गाजियाबाद",
        name: "अटोर"
    },
    {
        district: "गाजियाबाद",
        name: "बहादुरपुर"
    },
    {
        district: "गाजियाबाद",
        name: "भीकनपुर"
    },
    {
        district: "गाजियाबाद",
        name: "भोवापुर"
    },
    {
        district: "गाजियाबाद",
        name: "डासना देहात"
    },
    {
        district: "गाजियाबाद",
        name: "जलालाबाद"
    },
    {
        district: "गाजियाबाद",
        name: "मकरेडा"
    },
    {
        district: "गाजियाबाद",
        name: "मसूरी"
    },
    {
        district: "गाजियाबाद",
        name: "मथुरापुर"
    },
    {
        district: "गाजियाबाद",
        name: "मोरटी"
    },
    {
        district: "गाजियाबाद",
        name: "मोहिउददीन ढबारसी"
    },
    {
        district: "गाजियाबाद",
        name: "नंगला फिरोज मोहनपुर"
    },
    {
        district: "गाजियाबाद",
        name: "शाहपुरनिज मोरटा"
    },
    {
        district: "गाजियाबाद",
        name: "शमशेर"
    },
    {
        district: "गाजियाबाद",
        name: "नूरपुर"
    },
    {
        district: "गाजियाबाद",
        name: "कनौजा"
    },
    {
        district: "गाजियाबाद",
        name: "मतौर"
    },
    {
        district: "गाजियाबाद",
        name: "सुराना"
    },
    {
        district: "गाजियाबाद",
        name: "नेकपुर साबितनगर"
    },
    {
        district: "गाजियाबाद",
        name: "खौराजपुर विहंग"
    },
    {
        district: "गाजियाबाद",
        name: "भदौली"
    },
    {
        district: "गाजियाबाद",
        name: "मिलक चाकरपुर"
    },
    {
        district: "गाजियाबाद",
        name: "बडका आरिफपुर"
    },
    {
        district: "गाजियाबाद",
        name: "रेवडी रेवडा"
    },
    {
        district: "गाजियाबाद",
        name: "युसुफपुर ईशापुर"
    },
    {
        district: "गाजियाबाद",
        name: "लतीफपुर तिवडा"
    },
    {
        district: "गाजियाबाद",
        name: "औरंगाबाद गदाना"
    },
    {
        district: "गाजियाबाद",
        name: "नंगोला अमीरपुर"
    },
    {
        district: "गाजियाबाद",
        name: "नाहली"
    },
    {
        district: "गाजियाबाद",
        name: "अमराला"
    },
    {
        district: "गाजियाबाद",
        name: "मकरम्मतपुर सिखैडा"
    },
    {
        district: "गाजियाबाद",
        name: "चुडियाला"
    },
    {
        district: "गाजियाबाद",
        name: "सारा"
    },
    {
        district: "हाथरस",
        name: "नगरिया पटटी देवरी"
    },
    {
        district: "हाथरस",
        name: "दौकेली"
    },
    {
        district: "हाथरस",
        name: "नगला बरी पट्टी देवरी"
    },
    {
        district: "हाथरस",
        name: "बरसामई"
    },
    {
        district: "हाथरस",
        name: "सिचावली कदीम"
    },
    {
        district: "हाथरस",
        name: "सिहोरी."
    },
    {
        district: "हाथरस",
        name: "फतेहपुरबछेड़ा"
    },
    {
        district: "हाथरस",
        name: "बघना"
    },
    {
        district: "हाथरस",
        name: "कटैलिया"
    },
    {
        district: "हाथरस",
        name: "अल्हैपुर चुर्सेन"
    },
    {
        district: "हाथरस",
        name: "महौं"
    },
    {
        district: "हाथरस",
        name: "वीरनगर"
    },
    {
        district: "हाथरस",
        name: "लाडपुर"
    },
    {
        district: "हाथरस",
        name: "परसारा"
    },
    {
        district: "हाथरस",
        name: "तामसी"
    },
    {
        district: "हाथरस",
        name: "करकौली"
    },
    {
        district: "हाथरस",
        name: "नसीरपुर"
    },
    {
        district: "हाथरस",
        name: "कजरोठी"
    },
    {
        district: "हाथरस",
        name: "मई"
    },
    {
        district: "हाथरस",
        name: "अभयपुरा"
    },
    {
        district: "हाथरस",
        name: "बरोस"
    },
    {
        district: "हाथरस",
        name: "रामपुर"
    },
    {
        district: "हाथरस",
        name: "सलेमपुर"
    },
    {
        district: "हाथरस",
        name: "विर्स"
    },
    {
        district: "हाथरस",
        name: "बाधनु"
    },
    {
        district: "हाथरस",
        name: "रूहेरी"
    },
    {
        district: "हाथरस",
        name: "महमूदपुर बरसे"
    },
    {
        district: "हाथरस",
        name: "समामई"
    },
    {
        district: "हाथरस",
        name: "नगला गोपी"
    },
    {
        district: "हाथरस",
        name: "खेडा बरामई"
    },
    {
        district: "हाथरस",
        name: "रामगढ़"
    },
    {
        district: "हाथरस",
        name: "जटोई."
    },
    {
        district: "हाथरस",
        name: "टुकसान"
    },
    {
        district: "हाथरस",
        name: "नगलादया"
    },
    {
        district: "हाथरस",
        name: "रायक"
    },
    {
        district: "हाथरस",
        name: "खेड़ापरसोली"
    },
    {
        district: "हाथरस",
        name: "समदपुद"
    },
    {
        district: "हाथरस",
        name: "सलेमपुर"
    },
    {
        district: "हाथरस",
        name: "हसनपुर बारू"
    },
    {
        district: "हाथरस",
        name: "गुतहरा"
    },
    {
        district: "हाथरस",
        name: "धाधऊ"
    },
    {
        district: "हाथरस",
        name: "उधैना"
    },
    {
        district: "हाथरस",
        name: "महरारा"
    },
    {
        district: "हाथरस",
        name: "अगराना जरारा"
    },
    {
        district: "हाथरस",
        name: "मीरपुर"
    },
    {
        district: "हाथरस",
        name: "महमूदपुर"
    },
    {
        district: "हाथरस",
        name: "सुजावलपुर"
    },
    {
        district: "हाथरस",
        name: "मऊचिरायल"
    },
    {
        district: "हाथरस",
        name: "खेडिया खुर्द"
    },
    {
        district: "हाथरस",
        name: "पंचों"
    },
    {
        district: "हमीरपुर",
        name: "बरूआ"
    },
    {
        district: "हमीरपुर",
        name: "बेरी"
    },
    {
        district: "हमीरपुर",
        name: "देवीगंज"
    },
    {
        district: "हमीरपुर",
        name: "कण्डौर"
    },
    {
        district: "हमीरपुर",
        name: "कनौटा"
    },
    {
        district: "हमीरपुर",
        name: "मनकीखुर्द"
    },
    {
        district: "हमीरपुर",
        name: "मनकीकला"
    },
    {
        district: "हमीरपुर",
        name: "बिलौटा"
    },
    {
        district: "हमीरपुर",
        name: "सिकरोढी"
    },
    {
        district: "हमीरपुर",
        name: "गिमुहाडांडा"
    },
    {
        district: "हमीरपुर",
        name: "भौलीडांडा"
    },
    {
        district: "हमीरपुर",
        name: "भटपुरा"
    },
    {
        district: "हमीरपुर",
        name: "पारा"
    },
    {
        district: "हमीरपुर",
        name: "पतारा"
    },
    {
        district: "हमीरपुर",
        name: "उमराहट"
    },
    {
        district: "हमीरपुर",
        name: "चन्दूपुर"
    },
    {
        district: "हमीरपुर",
        name: "जमरेहीऊपर"
    },
    {
        district: "हमीरपुर",
        name: "जल्ला"
    },
    {
        district: "हमीरपुर",
        name: "बरूआ"
    },
    {
        district: "हमीरपुर",
        name: "अमिरता"
    },
    {
        district: "हमीरपुर",
        name: "बरदहा सहजना"
    },
    {
        district: "हमीरपुर",
        name: "बडागांव"
    },
    {
        district: "हमीरपुर",
        name: "कुम्हऊपुर"
    },
    {
        district: "हमीरपुर",
        name: "मोराकांदर"
    },
    {
        district: "हमीरपुर",
        name: "टिकरौली"
    },
    {
        district: "हमीरपुर",
        name: "भौरा"
    },
    {
        district: "हमीरपुर",
        name: "पाराओझी"
    },
    {
        district: "हमीरपुर",
        name: "पौथिया"
    },
    {
        district: "हमीरपुर",
        name: "पत्योरा"
    },
    {
        district: "हमीरपुर",
        name: "सहुरापुर"
    },
    {
        district: "हमीरपुर",
        name: "सुरौली बुजुर्ग"
    },
    {
        district: "हमीरपुर",
        name: "देवगांव"
    },
    {
        district: "हमीरपुर",
        name: "बहदीना अछपुरा"
    },
    {
        district: "हमीरपुर",
        name: "भुजपुर"
    },
    {
        district: "हमीरपुर",
        name: "बजेहटा"
    },
    {
        district: "हमीरपुर",
        name: "चन्दवारीडांडा"
    },
    {
        district: "हमीरपुर",
        name: "मगरौठ"
    },
    {
        district: "हमीरपुर",
        name: "चिकासी"
    },
    {
        district: "हमीरपुर",
        name: "इस्लामपुर"
    },
    {
        district: "हमीरपुर",
        name: "इछौरा"
    },
    {
        district: "हमीरपुर",
        name: "बसरिया"
    },
    {
        district: "हमीरपुर",
        name: "बन्धौली"
    },
    {
        district: "हमीरपुर",
        name: "बिरहट"
    },
    {
        district: "हमीरपुर",
        name: "रिरूआ बुजर्ग"
    },
    {
        district: "हमीरपुर",
        name: "जिटकरी"
    },
    {
        district: "हमीरपुर",
        name: "हरदुआ"
    },
    {
        district: "हमीरपुर",
        name: "भेडीडांडा"
    },
    {
        district: "हमीरपुर",
        name: "चण्डौत"
    },
    {
        district: "हमीरपुर",
        name: "जमौडीडांडा"
    },
    {
        district: "हमीरपुर",
        name: "अतरौली"
    },
    {
        district: "हापुड़",
        name: "देहरा  "
    },
    {
        district: "हापुड़",
        name: "हसनपुर लोधा "
    },
    {
        district: "हापुड़",
        name: "नगलाउदैरामपुर "
    },
    {
        district: "हापुड़",
        name: "भजेदा कलां "
    },
    {
        district: "हापुड़",
        name: "कमरुद्दीन नगर "
    },
    {
        district: "हापुड़",
        name: "नगला गज्जू "
    },
    {
        district: "हापुड़",
        name: "नगला कशी "
    },
    {
        district: "हापुड़",
        name: "परपा "
    },
    {
        district: "हापुड़",
        name: "पीपलदा "
    },
    {
        district: "हापुड़",
        name: "शेखपुर खिचरा "
    },
    {
        district: "हापुड़",
        name: "कटोरा जाफराबाद "
    },
    {
        district: "हापुड़",
        name: "नेकनामपुर ननई "
    },
    {
        district: "हापुड़",
        name: "अल्लाबक्सपुर "
    },
    {
        district: "हापुड़",
        name: "कटी उर्फ शंकर टीला "
    },
    {
        district: "हापुड़",
        name: "लाथीरा "
    },
    {
        district: "हापुड़",
        name: "रेहरवा किरावली "
    },
    {
        district: "हापुड़",
        name: "इनायतपुर "
    },
    {
        district: "हापुड़",
        name: "बहादुर गढ़ "
    },
    {
        district: "हापुड़",
        name: "भद्स्याना "
    },
    {
        district: "हापुड़",
        name: "मोहम्मदपुर रुस्तमपुर "
    },
    {
        district: "हापुड़",
        name: "पूठ शंकरातीला "
    },
    {
        district: "हापुड़",
        name: "आलमगीरपुर "
    },
    {
        district: "हापुड़",
        name: "बाबूगढ़ ग्राम "
    },
    {
        district: "हापुड़",
        name: "बछलौता "
    },
    {
        district: "हापुड़",
        name: "भामेदा "
    },
    {
        district: "हापुड़",
        name: "लखरादा "
    },
    {
        district: "हापुड़",
        name: "नूरपुर "
    },
    {
        district: "हापुड़",
        name: "शेखपुर "
    },
    {
        district: "हापुड़",
        name: "अयादनगर उत्तर "
    },
    {
        district: "हापुड़",
        name: "अयादनगर दक्षिण "
    },
    {
        district: "हापुड़",
        name: "भडंगपुर "
    },
    {
        district: "हापुड़",
        name: "भटैल "
    },
    {
        district: "हापुड़",
        name: "भीकनपुर "
    },
    {
        district: "हापुड़",
        name: "छपकौली "
    },
    {
        district: "हापुड़",
        name: "सिकंदरपुर ककौड़ी "
    },
    {
        district: "हापुड़",
        name: "बनखंडा "
    },
    {
        district: "हापुड़",
        name: "फतेहपुर "
    },
    {
        district: "हापुड़",
        name: "कटुता "
    },
    {
        district: "हापुड़",
        name: "हवासपुर बिगस "
    },
    {
        district: "हापुड़",
        name: "हिम्मतपुर "
    },
    {
        district: "हापुड़",
        name: "कनिया  कल्याणपुर "
    },
    {
        district: "हापुड़",
        name: "पीर नगर "
    },
    {
        district: "हापुड़",
        name: "राजपुर माजरा राजपुर "
    },
    {
        district: "हापुड़",
        name: "बक्सर "
    },
    {
        district: "हापुड़",
        name: "गोशमाहीउद्दीनपुर आजमपुर "
    },
    {
        district: "हापुड़",
        name: "मुबारकपुर "
    },
    {
        district: "हापुड़",
        name: "मुहम्मदपुर आजमपुर "
    },
    {
        district: "हापुड़",
        name: "टिगरी "
    },
    {
        district: "हापुड़",
        name: "अकबरपुर बुकलाना "
    },
    {
        district: "हापुड़",
        name: "भगवानपुर"
    },
    {
        district: "हरदोई",
        name: "अहिरी "
    },
    {
        district: "हरदोई",
        name: "अहिरोरी "
    },
    {
        district: "हरदोई",
        name: "एकघरा माघोला "
    },
    {
        district: "हरदोई",
        name: "ऐजा"
    },
    {
        district: "हरदोई",
        name: "अल्लीपुर "
    },
    {
        district: "हरदोई",
        name: "अनंग बेहटा "
    },
    {
        district: "हरदोई",
        name: "अकबरपुर तलहुँ "
    },
    {
        district: "हरदोई",
        name: "आखोहरा निवादा "
    },
    {
        district: "हरदोई",
        name: "अलदादपुर निवादा "
    },
    {
        district: "हरदोई",
        name: "जाजूपुर "
    },
    {
        district: "हरदोई",
        name: "कौड़िया "
    },
    {
        district: "हरदोई",
        name: "मजगाँव "
    },
    {
        district: "हरदोई",
        name: "अमिरता मार्खेरा "
    },
    {
        district: "हरदोई",
        name: "अमतरा "
    },
    {
        district: "हरदोई",
        name: "अनंगपुर "
    },
    {
        district: "हरदोई",
        name: "अकबरपुर पसनामऊ "
    },
    {
        district: "हरदोई",
        name: "अलीगढ "
    },
    {
        district: "हरदोई",
        name: "अतर्छा बुजुंर्ग "
    },
    {
        district: "हरदोई",
        name: "अछुआपुर "
    },
    {
        district: "हरदोई",
        name: "अगुलापुर "
    },
    {
        district: "हरदोई",
        name: "अहम्दी "
    },
    {
        district: "हरदोई",
        name: "अलीगंज नानखेरिआ "
    },
    {
        district: "हरदोई",
        name: "अर्जुनपुर "
    },
    {
        district: "हरदोई",
        name: "अरवल"
    },
    {
        district: "हरदोई",
        name: "बान "
    },
    {
        district: "हरदोई",
        name: "बभोरा "
    },
    {
        district: "हरदोई",
        name: "बालामऊ "
    },
    {
        district: "हरदोई",
        name: "आदमपुर "
    },
    {
        district: "हरदोई",
        name: "आइमा "
    },
    {
        district: "हरदोई",
        name: "अंट संत"
    },
    {
        district: "हरदोई",
        name: "अतवाअली मर्दनपुर "
    },
    {
        district: "हरदोई",
        name: "बाबतमऊ "
    },
    {
        district: "हरदोई",
        name: "बाघोरहा "
    },
    {
        district: "हरदोई",
        name: "अकबरपुर "
    },
    {
        district: "हरदोई",
        name: "अल्लापुर"
    },
    {
        district: "हरदोई",
        name: "अब्दुल्लानगर "
    },
    {
        district: "हरदोई",
        name: "अहमी "
    },
    {
        district: "हरदोई",
        name: "आदमपुर "
    },
    {
        district: "हरदोई",
        name: "ऐराभदर "
    },
    {
        district: "हरदोई",
        name: "एरका "
    },
    {
        district: "हरदोई",
        name: "आलमपुर "
    },
    {
        district: "हरदोई",
        name: "अतामउ "
    },
    {
        district: "हरदोई",
        name: "अब्दुल्लापुर "
    },
    {
        district: "हरदोई",
        name: "अगमपुर धुरहि "
    },
    {
        district: "हरदोई",
        name: "अचरामऊ"
    },
    {
        district: "हरदोई",
        name: "ऐंचामऊ"
    },
    {
        district: "हरदोई",
        name: "आदमपुर "
    },
    {
        district: "हरदोई",
        name: "अलीनगर "
    },
    {
        district: "हरदोई",
        name: "आलमनगर "
    },
    {
        district: "हरदोई",
        name: "अन्डउवा"
    },
    {
        district: "जालौन",
        name: "ऐर"
    },
    {
        district: "जालौन",
        name: "अतरिया "
    },
    {
        district: "जालौन",
        name: "बोहड़पुरा "
    },
    {
        district: "जालौन",
        name: "धमनी बुजुर्ग "
    },
    {
        district: "जालौन",
        name: "हरदोई गुजर "
    },
    {
        district: "जालौन",
        name: "जैपुरा "
    },
    {
        district: "जालौन",
        name: "कपासी "
    },
    {
        district: "जालौन",
        name: "कर्वी बुजुर्ग "
    },
    {
        district: "जालौन",
        name: "मोहम्मदाबाद "
    },
    {
        district: "जालौन",
        name: "रगोली "
    },
    {
        district: "जालौन",
        name: "अकोढ़ी "
    },
    {
        district: "जालौन",
        name: "भदवां "
    },
    {
        district: "जालौन",
        name: "रूरा मल्लू "
    },
    {
        district: "जालौन",
        name: "सहाओ"
    },
    {
        district: "जालौन",
        name: "सिकरा राजा "
    },
    {
        district: "जालौन",
        name: "आता "
    },
    {
        district: "जालौन",
        name: "अकबरपुर "
    },
    {
        district: "जालौन",
        name: "चमरी"
    },
    {
        district: "जालौन",
        name: "मसगाव "
    },
    {
        district: "जालौन",
        name: "पिपराय "
    },
    {
        district: "जालौन",
        name: "अमिता "
    },
    {
        district: "जालौन",
        name: "चमरी"
    },
    {
        district: "जालौन",
        name: "जखौली "
    },
    {
        district: "जालौन",
        name: "लोना "
    },
    {
        district: "जालौन",
        name: "पहारगॉंव "
    },
    {
        district: "जालौन",
        name: "अजितापुर "
    },
    {
        district: "जालौन",
        name: "हरसिंगपुर "
    },
    {
        district: "जालौन",
        name: "ईटों "
    },
    {
        district: "जालौन",
        name: "मदारीपुर "
    },
    {
        district: "जालौन",
        name: "सलेमपुर काल्पी "
    },
    {
        district: "जालौन",
        name: "अहेता "
    },
    {
        district: "जालौन",
        name: "गोहानि "
    },
    {
        district: "जालौन",
        name: "महाराजपुरा "
    },
    {
        district: "जालौन",
        name: "रूपापुर "
    },
    {
        district: "जालौन",
        name: "सिरसाडोगरहि "
    },
    {
        district: "जालौन",
        name: "देवकाली मुस्तकिल "
    },
    {
        district: "जालौन",
        name: "हीरापुर मुस्तकिल "
    },
    {
        district: "जालौन",
        name: "महेवा "
    },
    {
        district: "जालौन",
        name: "निआ मातपुर "
    },
    {
        district: "जालौन",
        name: "सरसेला "
    },
    {
        district: "जालौन",
        name: "भेंड "
    },
    {
        district: "जालौन",
        name: "गिद्धावसा "
    },
    {
        district: "जालौन",
        name: "कमसेरा "
    },
    {
        district: "जालौन",
        name: "कुदारी माधोगढ़ "
    },
    {
        district: "जालौन",
        name: "परावर"
    },
    {
        district: "जालौन",
        name: "बिलौहा "
    },
    {
        district: "जालौन",
        name: "जैघा "
    },
    {
        district: "जालौन",
        name: "माजिथ "
    },
    {
        district: "जालौन",
        name: "पृथ्वीपुरा "
    },
    {
        district: "जालौन",
        name: "तिहाड़"
    },
    {
        district: "जौनपुर",
        name: "नेहरूनगर"
    },
    {
        district: "जौनपुर",
        name: "गयासपुर"
    },
    {
        district: "जौनपुर",
        name: "जलालुद्दीनपुर"
    },
    {
        district: "जौनपुर",
        name: "कबूलपुर"
    },
    {
        district: "जौनपुर",
        name: "हुँसेपुर"
    },
    {
        district: "जौनपुर",
        name: "शिवपुर"
    },
    {
        district: "जौनपुर",
        name: "केशवपुर"
    },
    {
        district: "जौनपुर",
        name: "बीबीपुर"
    },
    {
        district: "जौनपुर",
        name: "सुंगुलपुर"
    },
    {
        district: "जौनपुर",
        name: "सलखापुर"
    },
    {
        district: "जौनपुर",
        name: "परियावा"
    },
    {
        district: "जौनपुर",
        name: "गोपीपुर."
    },
    {
        district: "जौनपुर",
        name: "बेलवा रामसागर"
    },
    {
        district: "जौनपुर",
        name: "अमदहांरू"
    },
    {
        district: "जौनपुर",
        name: "धनेजा।"
    },
    {
        district: "जौनपुर",
        name: "बिशुनपुर मझवार"
    },
    {
        district: "जौनपुर",
        name: "विशुनपुर पुलगुजर"
    },
    {
        district: "जौनपुर",
        name: "बढौली अहिरान"
    },
    {
        district: "जौनपुर",
        name: "सब्बेपुर"
    },
    {
        district: "जौनपुर",
        name: "चैरासन्तदास"
    },
    {
        district: "जौनपुर",
        name: "देऊपर"
    },
    {
        district: "जौनपुर",
        name: "बेलसड़ी"
    },
    {
        district: "जौनपुर",
        name: "भरसवां"
    },
    {
        district: "जौनपुर",
        name: "हरीगांव"
    },
    {
        district: "जौनपुर",
        name: "भुवाखुर्द"
    },
    {
        district: "जौनपुर",
        name: "लखेसर"
    },
    {
        district: "जौनपुर",
        name: "उचैरा"
    },
    {
        district: "जौनपुर",
        name: "वीरपालपुर"
    },
    {
        district: "जौनपुर",
        name: "कन्धरपुर"
    },
    {
        district: "जौनपुर",
        name: "इन्दरिया"
    },
    {
        district: "जौनपुर",
        name: "फूलपुर"
    },
    {
        district: "जौनपुर",
        name: "कुवरदा।"
    },
    {
        district: "जौनपुर",
        name: "रतनूपुर"
    },
    {
        district: "जौनपुर",
        name: "उमरवार"
    },
    {
        district: "जौनपुर",
        name: "गोबरा"
    },
    {
        district: "जौनपुर",
        name: "बरैछावीर"
    },
    {
        district: "जौनपुर",
        name: "बरामनपुर"
    },
    {
        district: "जौनपुर",
        name: "गुलरा"
    },
    {
        district: "जौनपुर",
        name: "जगुआ"
    },
    {
        district: "जौनपुर",
        name: "पारापाटी"
    },
    {
        district: "जौनपुर",
        name: "देवराई"
    },
    {
        district: "जौनपुर",
        name: "बलतरी"
    },
    {
        district: "जौनपुर",
        name: "बरइछ"
    },
    {
        district: "जौनपुर",
        name: "घुस्तौंद "
    },
    {
        district: "जौनपुर",
        name: "भैसा "
    },
    {
        district: "जौनपुर",
        name: "उन्हानी कलां"
    },
    {
        district: "जौनपुर",
        name: "मर्थानी "
    },
    {
        district: "जौनपुर",
        name: "रामपुरनद्दी "
    },
    {
        district: "जौनपुर",
        name: "सिकंदरपुर"
    },
    {
        district: "कन्नौज",
        name: "रोहली"
    },
    {
        district: "कन्नौज",
        name: "इन्दुइयागंज"
    },
    {
        district: "कन्नौज",
        name: "गंगागंज गुरौली"
    },
    {
        district: "कन्नौज",
        name: "तेरारब्बू"
    },
    {
        district: "कन्नौज",
        name: "सिघनापुर"
    },
    {
        district: "कन्नौज",
        name: "मौरा"
    },
    {
        district: "कन्नौज",
        name: "तमियामऊ"
    },
    {
        district: "कन्नौज",
        name: "अतरौली"
    },
    {
        district: "कन्नौज",
        name: "बिबियाजलालपुर"
    },
    {
        district: "कन्नौज",
        name: "बिर्रा"
    },
    {
        district: "कन्नौज",
        name: "मेरापुर गढ़िया"
    },
    {
        district: "कन्नौज",
        name: "पंथरा"
    },
    {
        district: "कन्नौज",
        name: "बहादुरपुर मझिगवां"
    },
    {
        district: "कन्नौज",
        name: "शंकरपुर"
    },
    {
        district: "कन्नौज",
        name: "देवीपुर"
    },
    {
        district: "कन्नौज",
        name: "आरिफपुर"
    },
    {
        district: "कन्नौज",
        name: "खानपुर"
    },
    {
        district: "कन्नौज",
        name: "बझेड़ी"
    },
    {
        district: "कन्नौज",
        name: "रसूलपुर विकूपुर"
    },
    {
        district: "कन्नौज",
        name: "सिकन्दरपुर"
    },
    {
        district: "कन्नौज",
        name: "वीलमपुर"
    },
    {
        district: "कन्नौज",
        name: "नुनारी"
    },
    {
        district: "कन्नौज",
        name: "नौरंगपुर नगरिया"
    },
    {
        district: "कन्नौज",
        name: "पाला"
    },
    {
        district: "कन्नौज",
        name: "सखौली"
    },
    {
        district: "कन्नौज",
        name: "सिकरोरी"
    },
    {
        district: "कन्नौज",
        name: "कनौली"
    },
    {
        district: "कन्नौज",
        name: "भखरा"
    },
    {
        district: "कन्नौज",
        name: "सरसई"
    },
    {
        district: "कन्नौज",
        name: "खैरनगर"
    },
    {
        district: "कन्नौज",
        name: "राजपुरकरना"
    },
    {
        district: "कन्नौज",
        name: "चपुन्ना"
    },
    {
        district: "कन्नौज",
        name: "जरिहापुर"
    },
    {
        district: "कन्नौज",
        name: "कुवरपुरकाशीदीन"
    },
    {
        district: "कन्नौज",
        name: "रेरीरामपुर"
    },
    {
        district: "कन्नौज",
        name: "दाईपुर"
    },
    {
        district: "कन्नौज",
        name: "गदनपुर नराहा"
    },
    {
        district: "कन्नौज",
        name: "चैराचॉदपुर"
    },
    {
        district: "कन्नौज",
        name: "सैयदपुर सकरी"
    },
    {
        district: "कन्नौज",
        name: "महमूदपुर बीजा"
    },
    {
        district: "कन्नौज",
        name: "कटरी गंगपुर"
    },
    {
        district: "कन्नौज",
        name: "अकबरपुर"
    },
    {
        district: "कन्नौज",
        name: "मनोरथपुर"
    },
    {
        district: "कन्नौज",
        name: "चियासर"
    },
    {
        district: "कन्नौज",
        name: "अलीपुर जलेसर"
    },
    {
        district: "कन्नौज",
        name: "गढ़िया बलीदादपुर"
    },
    {
        district: "कन्नौज",
        name: "गुगरापुर"
    },
    {
        district: "कन्नौज",
        name: "जसौरा"
    },
    {
        district: "कन्नौज",
        name: "कूलापुर"
    },
    {
        district: "कन्नौज",
        name: "बनपुरा नरायनपुर"
    },
    {
        district: "कानपुर देहात",
        name: "अहरौली घाट बांगर"
    },
    {
        district: "कानपुर देहात",
        name: "ट्योगा"
    },
    {
        district: "कानपुर देहात",
        name: "नगीना बांगर"
    },
    {
        district: "कानपुर देहात",
        name: "नगसिया"
    },
    {
        district: "कानपुर देहात",
        name: "चपरेहटा"
    },
    {
        district: "कानपुर देहात",
        name: "चपरघटा"
    },
    {
        district: "कानपुर देहात",
        name: "देवराहट"
    },
    {
        district: "कानपुर देहात",
        name: "दौलतपुर"
    },
    {
        district: "कानपुर देहात",
        name: "उदईपुर"
    },
    {
        district: "कानपुर देहात",
        name: "सलारपुर"
    },
    {
        district: "कानपुर देहात",
        name: "हलिया"
    },
    {
        district: "कानपुर देहात",
        name: "दिबैर"
    },
    {
        district: "कानपुर देहात",
        name: "डिलौलिया बांगर"
    },
    {
        district: "कानपुर देहात",
        name: "किशुनपुर"
    },
    {
        district: "कानपुर देहात",
        name: "कथरी"
    },
    {
        district: "कानपुर देहात",
        name: "करियापुर भोगनीपुर"
    },
    {
        district: "कानपुर देहात",
        name: "कृपालपुर"
    },
    {
        district: "कानपुर देहात",
        name: "बम्हरौली घाट बांगर"
    },
    {
        district: "कानपुर देहात",
        name: "बरौला"
    },
    {
        district: "कानपुर देहात",
        name: "मुक्तापुर"
    },
    {
        district: "कानपुर देहात",
        name: "गहोलिया"
    },
    {
        district: "कानपुर देहात",
        name: "कम्भी"
    },
    {
        district: "कानपुर देहात",
        name: "किशवाखेडा"
    },
    {
        district: "कानपुर देहात",
        name: "खजुरी"
    },
    {
        district: "कानपुर देहात",
        name: "खल्ला"
    },
    {
        district: "कानपुर देहात",
        name: "परौख"
    },
    {
        district: "कानपुर देहात",
        name: "इन्द्ररूख"
    },
    {
        district: "कानपुर देहात",
        name: "सिहुठा"
    },
    {
        district: "कानपुर देहात",
        name: "साहनीपुर"
    },
    {
        district: "कानपुर देहात",
        name: "जमौरा"
    },
    {
        district: "कानपुर देहात",
        name: "पिण्डाथू"
    },
    {
        district: "कानपुर देहात",
        name: "रायपुर"
    },
    {
        district: "कानपुर देहात",
        name: "हवासपुर"
    },
    {
        district: "कानपुर देहात",
        name: "गढिया सिकन्दरा"
    },
    {
        district: "कानपुर देहात",
        name: "पल्हनापुर"
    },
    {
        district: "कानपुर देहात",
        name: "नैपलापुर"
    },
    {
        district: "कानपुर देहात",
        name: "खोजारामपुर"
    },
    {
        district: "कानपुर देहात",
        name: "बैजामऊ बांगर"
    },
    {
        district: "कानपुर देहात",
        name: "सिहुरा"
    },
    {
        district: "कानपुर देहात",
        name: "जैसलपुर महदेवा"
    },
    {
        district: "कानपुर देहात",
        name: "गौहानी बांगर"
    },
    {
        district: "कानपुर देहात",
        name: "गौरीरतन बांगर"
    },
    {
        district: "कानपुर देहात",
        name: "जटियापुर"
    },
    {
        district: "कानपुर देहात",
        name: "महमूदपुर"
    },
    {
        district: "कानपुर देहात",
        name: "भरतौली"
    },
    {
        district: "कानपुर देहात",
        name: "सरौटा"
    },
    {
        district: "कानपुर देहात",
        name: "जगदीशपुर"
    },
    {
        district: "कानपुर देहात",
        name: "मलासा"
    },
    {
        district: "कानपुर देहात",
        name: "निगोही"
    },
    {
        district: "कानपुर देहात",
        name: "मावर"
    },
    {
        district: "कानपुर नगर",
        name: "अकबरपुर सेंग"
    },
    {
        district: "कानपुर नगर",
        name: "आंकिन"
    },
    {
        district: "कानपुर नगर",
        name: "ददिखा"
    },
    {
        district: "कानपुर नगर",
        name: "गदनपुर आहार"
    },
    {
        district: "कानपुर नगर",
        name: "गिलवट अमीनाबाद"
    },
    {
        district: "कानपुर नगर",
        name: "हसौली काजीगंज"
    },
    {
        district: "कानपुर नगर",
        name: "महिगवां"
    },
    {
        district: "कानपुर नगर",
        name: "मोहउद्दीनपुर"
    },
    {
        district: "कानपुर नगर",
        name: "नानामऊ"
    },
    {
        district: "कानपुर नगर",
        name: "राढा"
    },
    {
        district: "कानपुर नगर",
        name: "राढन"
    },
    {
        district: "कानपुर नगर",
        name: "संजती बादशाहपुर"
    },
    {
        district: "कानपुर नगर",
        name: "बाजिदपुर"
    },
    {
        district: "कानपुर नगर",
        name: "बाल्हीपुर"
    },
    {
        district: "कानपुर नगर",
        name: "बेहटा"
    },
    {
        district: "कानपुर नगर",
        name: "दुर्गापुर"
    },
    {
        district: "कानपुर नगर",
        name: "क्योना"
    },
    {
        district: "कानपुर नगर",
        name: "राजारामपुर"
    },
    {
        district: "कानपुर नगर",
        name: "सुनौढा"
    },
    {
        district: "कानपुर नगर",
        name: "तरी पाठकपुर"
    },
    {
        district: "कानपुर नगर",
        name: "विल्हन"
    },
    {
        district: "कानपुर नगर",
        name: "काकूपुर सीताराम"
    },
    {
        district: "कानपुर नगर",
        name: "मुहपोछा"
    },
    {
        district: "कानपुर नगर",
        name: "निवादा दरिया"
    },
    {
        district: "कानपुर नगर",
        name: "हिन्दुपुर"
    },
    {
        district: "कानपुर नगर",
        name: "हिंगुपुर बांगर"
    },
    {
        district: "कानपुर नगर",
        name: "हृदयपुर"
    },
    {
        district: "कानपुर नगर",
        name: "ईश्वरीगंज"
    },
    {
        district: "कानपुर नगर",
        name: "कटरी लोधवाखेडा"
    },
    {
        district: "कानपुर नगर",
        name: "कटरी शंकरपुर सराय"
    },
    {
        district: "कानपुर नगर",
        name: "ख्योरा कटरी"
    },
    {
        district: "कानपुर नगर",
        name: "मोहम्मदपुर"
    },
    {
        district: "कानपुर नगर",
        name: "प्रतापपुर हरी"
    },
    {
        district: "कानपुर नगर",
        name: "रमेलनगर बांगर"
    },
    {
        district: "कानपुर नगर",
        name: "ऐमा"
    },
    {
        district: "कानपुर नगर",
        name: "विपौसी"
    },
    {
        district: "कानपुर नगर",
        name: "मथुराखेड़ा"
    },
    {
        district: "कानपुर नगर",
        name: "नागापुर"
    },
    {
        district: "कानपुर नगर",
        name: "नारायनपुर सलेमपुर"
    },
    {
        district: "कानपुर नगर",
        name: "डोमनपुर"
    },
    {
        district: "कानपुर नगर",
        name: "समूही"
    },
    {
        district: "कानपुर नगर",
        name: "रामपुर"
    },
    {
        district: "कानपुर नगर",
        name: "मऊनखत"
    },
    {
        district: "कानपुर नगर",
        name: "कटरी"
    },
    {
        district: "कानपुर नगर",
        name: "कोटरा"
    },
    {
        district: "कानपुर नगर",
        name: "पनका बहादुरनगर"
    },
    {
        district: "कानपुर नगर",
        name: "भौती प्रतापपुर"
    },
    {
        district: "कानपुर नगर",
        name: "भौती खेड़ा"
    },
    {
        district: "कानपुर नगर",
        name: "टिकरा"
    },
    {
        district: "कानपुर नगर",
        name: "मर्दनपुर"
    },
    {
        district: "कासगंज",
        name: "दतलाना पुख्ता"
    },
    {
        district: "कासगंज",
        name: "मानपुर नगरिया"
    },
    {
        district: "कासगंज",
        name: "उढैरपुख्ता"
    },
    {
        district: "कासगंज",
        name: "मौजमपुर हुसैनपुर"
    },
    {
        district: "कासगंज",
        name: "बघेला पुख्ता"
    },
    {
        district: "कासगंज",
        name: "कादरवाडी"
    },
    {
        district: "कासगंज",
        name: "तारापुर नसीर"
    },
    {
        district: "कासगंज",
        name: "बनूपुर पुख्ता"
    },
    {
        district: "कासगंज",
        name: "उकुरी"
    },
    {
        district: "कासगंज",
        name: "अल्लीपुर बरवारा"
    },
    {
        district: "कासगंज",
        name: "महमूदपुर पुख्ता"
    },
    {
        district: "कासगंज",
        name: "अभयपुर रामपुर पुख्ता"
    },
    {
        district: "कासगंज",
        name: "बरौना"
    },
    {
        district: "कासगंज",
        name: "गठोरा"
    },
    {
        district: "कासगंज",
        name: "कादरगंज खाम"
    },
    {
        district: "कासगंज",
        name: "न्यौली फतुआबाद"
    },
    {
        district: "कासगंज",
        name: "हिम्मतनगर बझेरा"
    },
    {
        district: "कासगंज",
        name: "कादरगंज पुख्ता"
    },
    {
        district: "कासगंज",
        name: "बगवास"
    },
    {
        district: "कासगंज",
        name: "इन्द्राजशनपुर"
    },
    {
        district: "कासगंज",
        name: "तरसी"
    },
    {
        district: "कासगंज",
        name: "उलाईखेडा"
    },
    {
        district: "कासगंज",
        name: "शहवाजपुर पुख्ता"
    },
    {
        district: "कासगंज",
        name: "राजपुर कुर्रा"
    },
    {
        district: "कासगंज",
        name: "सुन्नगढी"
    },
    {
        district: "कासगंज",
        name: "मोहनीमामूरगंज"
    },
    {
        district: "कासगंज",
        name: "अहरौली"
    },
    {
        district: "कासगंज",
        name: "ब्राहीमपुर"
    },
    {
        district: "कासगंज",
        name: "नरौली"
    },
    {
        district: "कासगंज",
        name: "मोहम्मदपुर"
    },
    {
        district: "कासगंज",
        name: "ढोलना"
    },
    {
        district: "कासगंज",
        name: "ततारपुर माफी"
    },
    {
        district: "कासगंज",
        name: "मनिकापुर पुख्ता"
    },
    {
        district: "कासगंज",
        name: "चंदवापुख्ता"
    },
    {
        district: "कासगंज",
        name: "नगला बदन"
    },
    {
        district: "कासगंज",
        name: "बस्तरमऊ"
    },
    {
        district: "कासगंज",
        name: "बोंदर"
    },
    {
        district: "कासगंज",
        name: "जासमई"
    },
    {
        district: "कासगंज",
        name: "पावलढेरा"
    },
    {
        district: "कासगंज",
        name: "ढूंढरा"
    },
    {
        district: "कासगंज",
        name: "बसई"
    },
    {
        district: "कासगंज",
        name: "फकौता"
    },
    {
        district: "कासगंज",
        name: "रसूलवा सुलहपुर"
    },
    {
        district: "कासगंज",
        name: "भ्रगवासिनी"
    },
    {
        district: "कासगंज",
        name: "नरई"
    },
    {
        district: "कासगंज",
        name: "बढौला"
    },
    {
        district: "कासगंज",
        name: "हथौडावन"
    },
    {
        district: "कासगंज",
        name: "थाना दरियावगंज"
    },
    {
        district: "कासगंज",
        name: "नरथर"
    },
    {
        district: "कासगंज",
        name: "सिकंदरपुर मनौना"
    },
    {
        district: "कौशाम्बी",
        name: "रैयादेह माफी"
    },
    {
        district: "कौशाम्बी",
        name: "हरदुआ"
    },
    {
        district: "कौशाम्बी",
        name: "शेखपुर रसूलपुर"
    },
    {
        district: "कौशाम्बी",
        name: "पन्सौर"
    },
    {
        district: "कौशाम्बी",
        name: "गौसपुर"
    },
    {
        district: "कौशाम्बी",
        name: "सिकन्दरपुर बजहा"
    },
    {
        district: "कौशाम्बी",
        name: "बरई सलेम"
    },
    {
        district: "कौशाम्बी",
        name: "सिरियायां कला"
    },
    {
        district: "कौशाम्बी",
        name: "महगांव"
    },
    {
        district: "कौशाम्बी",
        name: "श्यामपुर उर्फ मल्हीपुर"
    },
    {
        district: "कौशाम्बी",
        name: "चित्तापुर"
    },
    {
        district: "कौशाम्बी",
        name: "पुरखास"
    },
    {
        district: "कौशाम्बी",
        name: "बसुहार"
    },
    {
        district: "कौशाम्बी",
        name: "बरियावां"
    },
    {
        district: "कौशाम्बी",
        name: "शेरगढ़"
    },
    {
        district: "कौशाम्बी",
        name: "भोपतपुर"
    },
    {
        district: "कौशाम्बी",
        name: "दुर्गापुर"
    },
    {
        district: "कौशाम्बी",
        name: "अम्बावां पूरव"
    },
    {
        district: "कौशाम्बी",
        name: "गुवारा तैयबपुर"
    },
    {
        district: "कौशाम्बी",
        name: "इब्राहिमपुर"
    },
    {
        district: "कौशाम्बी",
        name: "तैयबपुर मंगौरा"
    },
    {
        district: "कौशाम्बी",
        name: "टेनशाहआलमाबाद"
    },
    {
        district: "कौशाम्बी",
        name: "मवई"
    },
    {
        district: "कौशाम्बी",
        name: "मुबारकपुर"
    },
    {
        district: "कौशाम्बी",
        name: "अढ़ौली"
    },
    {
        district: "कौशाम्बी",
        name: "अलवारा"
    },
    {
        district: "कौशाम्बी",
        name: "बौली"
    },
    {
        district: "कौशाम्बी",
        name: "डकशरीरा"
    },
    {
        district: "कौशाम्बी",
        name: "बारा"
    },
    {
        district: "कौशाम्बी",
        name: "भकन्दा"
    },
    {
        district: "कौशाम्बी",
        name: "बेरौचा"
    },
    {
        district: "कौशाम्बी",
        name: "अर्का फतेहपुर"
    },
    {
        district: "कौशाम्बी",
        name: "जुगराजपुर"
    },
    {
        district: "कौशाम्बी",
        name: "रसूलपुर सोनी"
    },
    {
        district: "कौशाम्बी",
        name: "लौगावां"
    },
    {
        district: "कौशाम्बी",
        name: "कसिया"
    },
    {
        district: "कौशाम्बी",
        name: "मोंगरी कड़ा"
    },
    {
        district: "कौशाम्बी",
        name: "राला"
    },
    {
        district: "कौशाम्बी",
        name: "कोखराज"
    },
    {
        district: "कौशाम्बी",
        name: "कुण्ड्रावी"
    },
    {
        district: "कौशाम्बी",
        name: "गोविन्दपुर गोरियो"
    },
    {
        district: "कौशाम्बी",
        name: "बिछौरा"
    },
    {
        district: "कौशाम्बी",
        name: "बरी पुर"
    },
    {
        district: "कौशाम्बी",
        name: "कैमा"
    },
    {
        district: "कौशाम्बी",
        name: "गनपा"
    },
    {
        district: "कौशाम्बी",
        name: "तरसौरा"
    },
    {
        district: "कौशाम्बी",
        name: "अम्बाई बुजुर्ग"
    },
    {
        district: "कौशाम्बी",
        name: "अलीपुर जीता"
    },
    {
        district: "कौशाम्बी",
        name: "अलावलपुर टिकरी"
    },
    {
        district: "कौशाम्बी",
        name: "कनवार"
    },
    {
        district: "ललितपुर",
        name: "गैलवाराखुर्द"
    },
    {
        district: "ललितपुर",
        name: "ऐरा"
    },
    {
        district: "ललितपुर",
        name: "बछलापुर"
    },
    {
        district: "ललितपुर",
        name: "डुगरिया"
    },
    {
        district: "ललितपुर",
        name: "कचनौदाकलां"
    },
    {
        district: "ललितपुर",
        name: "बेटना"
    },
    {
        district: "ललितपुर",
        name: "बंगरिया"
    },
    {
        district: "ललितपुर",
        name: "सलैया"
    },
    {
        district: "ललितपुर",
        name: "बरौदियाराईन"
    },
    {
        district: "ललितपुर",
        name: "धुरवारा"
    },
    {
        district: "ललितपुर",
        name: "अडवाहा"
    },
    {
        district: "ललितपुर",
        name: "सौरई"
    },
    {
        district: "ललितपुर",
        name: "करगन"
    },
    {
        district: "ललितपुर",
        name: "खडोवरा"
    },
    {
        district: "ललितपुर",
        name: "सतगता"
    },
    {
        district: "ललितपुर",
        name: "मडवारी"
    },
    {
        district: "ललितपुर",
        name: "निवाई"
    },
    {
        district: "ललितपुर",
        name: "महरौनीखुर्द"
    },
    {
        district: "ललितपुर",
        name: "गुलेन्दा"
    },
    {
        district: "ललितपुर",
        name: "कन्धारीकलां"
    },
    {
        district: "ललितपुर",
        name: "कन्धारीखुर्द"
    },
    {
        district: "ललितपुर",
        name: "उदगुवां"
    },
    {
        district: "ललितपुर",
        name: "बम्होरीसर"
    },
    {
        district: "ललितपुर",
        name: "रारा"
    },
    {
        district: "ललितपुर",
        name: "टेटा"
    },
    {
        district: "ललितपुर",
        name: "बिगारी"
    },
    {
        district: "ललितपुर",
        name: "किसरदा"
    },
    {
        district: "ललितपुर",
        name: "खटोरा"
    },
    {
        district: "ललितपुर",
        name: "सतवांसा"
    },
    {
        district: "ललितपुर",
        name: "सिलावन"
    },
    {
        district: "ललितपुर",
        name: "निवारी"
    },
    {
        district: "ललितपुर",
        name: "नैनवारा"
    },
    {
        district: "ललितपुर",
        name: "सैदपुर"
    },
    {
        district: "ललितपुर",
        name: "कुम्हेंडी"
    },
    {
        district: "ललितपुर",
        name: "गौना"
    },
    {
        district: "ललितपुर",
        name: "धवा"
    },
    {
        district: "ललितपुर",
        name: "मडावरा"
    },
    {
        district: "ललितपुर",
        name: "मदनपुर"
    },
    {
        district: "ललितपुर",
        name: "नीमखेरा"
    },
    {
        district: "ललितपुर",
        name: "पटनामड़ावरा"
    },
    {
        district: "ललितपुर",
        name: "बमराना"
    },
    {
        district: "ललितपुर",
        name: "बहादुरपुर"
    },
    {
        district: "ललितपुर",
        name: "बानौनी"
    },
    {
        district: "ललितपुर",
        name: "सुनवाहा"
    },
    {
        district: "ललितपुर",
        name: "चंदावली"
    },
    {
        district: "ललितपुर",
        name: "ऊमरी"
    },
    {
        district: "ललितपुर",
        name: "गैदौरा"
    },
    {
        district: "ललितपुर",
        name: "मरौली"
    },
    {
        district: "ललितपुर",
        name: "भैलोनीलोध"
    },
    {
        district: "ललितपुर",
        name: "टीला"
    },
    {
        district: "महोबा",
        name: "सिजरिया"
    },
    {
        district: "महोबा",
        name: "बन्नी"
    },
    {
        district: "महोबा",
        name: "पुरा"
    },
    {
        district: "महोबा",
        name: "डंअंप ज्ञीनतक"
    },
    {
        district: "महोबा",
        name: "सिरसीकलां"
    },
    {
        district: "महोबा",
        name: "चिचारा"
    },
    {
        district: "महोबा",
        name: "खम्हरिया"
    },
    {
        district: "महोबा",
        name: "लिलवाही"
    },
    {
        district: "महोबा",
        name: "खना"
    },
    {
        district: "महोबा",
        name: "धरान"
    },
    {
        district: "महोबा",
        name: "परराहा"
    },
    {
        district: "महोबा",
        name: "गहरा"
    },
    {
        district: "महोबा",
        name: "सुरहा"
    },
    {
        district: "महोबा",
        name: "सिचारा"
    },
    {
        district: "महोबा",
        name: "छानीकला"
    },
    {
        district: "महोबा",
        name: "जैतपुर"
    },
    {
        district: "महोबा",
        name: "जैलवारा"
    },
    {
        district: "महोबा",
        name: "बछेछर खुर्द"
    },
    {
        district: "महोबा",
        name: "बडखेरा"
    },
    {
        district: "महोबा",
        name: "पुरवा जैतपुर"
    },
    {
        district: "महोबा",
        name: "स्यावन"
    },
    {
        district: "महोबा",
        name: "रजपुरा"
    },
    {
        district: "महोबा",
        name: "मगरिया"
    },
    {
        district: "महोबा",
        name: "रजौनी"
    },
    {
        district: "महोबा",
        name: "विहार"
    },
    {
        district: "महोबा",
        name: "नकरा"
    },
    {
        district: "महोबा",
        name: "दादरी"
    },
    {
        district: "महोबा",
        name: "महुआइटौरा"
    },
    {
        district: "महोबा",
        name: "तैझ्या"
    },
    {
        district: "महोबा",
        name: "खैरोकला"
    },
    {
        district: "महोबा",
        name: "उमरई"
    },
    {
        district: "महोबा",
        name: "मडवारी"
    },
    {
        district: "महोबा",
        name: "लौलारा"
    },
    {
        district: "महोबा",
        name: "नैपुरा"
    },
    {
        district: "महोबा",
        name: "भुजपुरा"
    },
    {
        district: "महोबा",
        name: "बल्लॉय"
    },
    {
        district: "महोबा",
        name: "कुड़ार"
    },
    {
        district: "महोबा",
        name: "चन्दौली"
    },
    {
        district: "महोबा",
        name: "अनघौरा"
    },
    {
        district: "महोबा",
        name: "कमलखेड़ा"
    },
    {
        district: "महोबा",
        name: "बम्हौरीकलॉ"
    },
    {
        district: "महोबा",
        name: "बरदा"
    },
    {
        district: "महोबा",
        name: "लुहारी"
    },
    {
        district: "महोबा",
        name: "अकठौहा"
    },
    {
        district: "महोबा",
        name: "गोरखा"
    },
    {
        district: "महोबा",
        name: "बम्हौरीबेलदारन"
    },
    {
        district: "महोबा",
        name: "सूपा"
    },
    {
        district: "महोबा",
        name: "गुढ़ा"
    },
    {
        district: "महोबा",
        name: "गौरहरी"
    },
    {
        district: "महोबा",
        name: "करहराखुर्द"
    },
    {
        district: "मैनपुरी ",
        name: "मांसरपुर"
    },
    {
        district: "मैनपुरी ",
        name: "नवाटेड़ा"
    },
    {
        district: "मैनपुरी ",
        name: "सुनूपुर"
    },
    {
        district: "मैनपुरी ",
        name: "भूरूपुर"
    },
    {
        district: "मैनपुरी ",
        name: "ढकपुरा"
    },
    {
        district: "मैनपुरी ",
        name: "भैंसरोली"
    },
    {
        district: "मैनपुरी ",
        name: "जसराजपुर"
    },
    {
        district: "मैनपुरी ",
        name: "जिलही"
    },
    {
        district: "मैनपुरी ",
        name: "चन्दनपुर"
    },
    {
        district: "मैनपुरी ",
        name: "नसीरपुर"
    },
    {
        district: "मैनपुरी ",
        name: "मद्दापुरखास"
    },
    {
        district: "मैनपुरी ",
        name: "रामनगरिया"
    },
    {
        district: "मैनपुरी ",
        name: "नगला ऊसर"
    },
    {
        district: "मैनपुरी ",
        name: "पनवाह"
    },
    {
        district: "मैनपुरी ",
        name: "बरखेड़ा"
    },
    {
        district: "मैनपुरी ",
        name: "हाफिजपुर"
    },
    {
        district: "मैनपुरी ",
        name: "गंगापुर"
    },
    {
        district: "मैनपुरी ",
        name: "लहरा"
    },
    {
        district: "मैनपुरी ",
        name: "ललूपुर"
    },
    {
        district: "मैनपुरी ",
        name: "पुसैना"
    },
    {
        district: "मैनपुरी ",
        name: "इटौरा"
    },
    {
        district: "मैनपुरी ",
        name: "बलारपुर"
    },
    {
        district: "मैनपुरी ",
        name: "दिवनपुरचैधरी"
    },
    {
        district: "मैनपुरी ",
        name: "हन्नूखेड़ा"
    },
    {
        district: "मैनपुरी ",
        name: "भाॅंवत"
    },
    {
        district: "मैनपुरी ",
        name: "अघार"
    },
    {
        district: "मैनपुरी ",
        name: "ब्यौंतीकलाॅ"
    },
    {
        district: "मैनपुरी ",
        name: "द्वारिकापुर"
    },
    {
        district: "मैनपुरी ",
        name: "वीरपुरकलाॅं"
    },
    {
        district: "मैनपुरी ",
        name: "पडुरा"
    },
    {
        district: "मैनपुरी ",
        name: "तखरऊ"
    },
    {
        district: "मैनपुरी ",
        name: "ररूआ"
    },
    {
        district: "मैनपुरी ",
        name: "नदरैला"
    },
    {
        district: "मैनपुरी ",
        name: "अल्लीपुर"
    },
    {
        district: "मैनपुरी ",
        name: "अलीपुरकेशोंपुर"
    },
    {
        district: "मैनपुरी ",
        name: "गंगदासपुर"
    },
    {
        district: "मैनपुरी ",
        name: "पहाड़पुर"
    },
    {
        district: "मैनपुरी ",
        name: "रंगपुर"
    },
    {
        district: "मैनपुरी ",
        name: "हिरौली"
    },
    {
        district: "मैनपुरी ",
        name: "बुढर्रा"
    },
    {
        district: "मैनपुरी ",
        name: "गढियाकुरावली"
    },
    {
        district: "मैनपुरी ",
        name: "पचावर"
    },
    {
        district: "मैनपुरी ",
        name: "विक्रमपुर"
    },
    {
        district: "मैनपुरी ",
        name: "नगला सलेही"
    },
    {
        district: "मैनपुरी ",
        name: "दरवाह"
    },
    {
        district: "मैनपुरी ",
        name: "ईकरी"
    },
    {
        district: "मैनपुरी ",
        name: "बडे़री"
    },
    {
        district: "मैनपुरी ",
        name: "शिवसिंहपुर नवादा"
    },
    {
        district: "मैनपुरी ",
        name: "लोधीपुरबहादुरपुर"
    },
    {
        district: "मैनपुरी ",
        name: "बुर्रा"
    },
    {
        district: "मथुरा",
        name: "सकराया"
    },
    {
        district: "मथुरा",
        name: "आटस"
    },
    {
        district: "मथुरा",
        name: "सुनरख"
    },
    {
        district: "मथुरा",
        name: "घौरेरा"
    },
    {
        district: "मथुरा",
        name: "वृन्दावन"
    },
    {
        district: "मथुरा",
        name: "जयसिंहपुरा"
    },
    {
        district: "मथुरा",
        name: "मथुरा"
    },
    {
        district: "मथुरा",
        name: "औरंगाबाद"
    },
    {
        district: "मथुरा",
        name: "राँची"
    },
    {
        district: "मथुरा",
        name: "गढाया लतीफपुर"
    },
    {
        district: "मथुरा",
        name: "छिनपारई"
    },
    {
        district: "मथुरा",
        name: "देदना बांगर"
    },
    {
        district: "मथुरा",
        name: "बरौठ"
    },
    {
        district: "मथुरा",
        name: "बाघर्रा"
    },
    {
        district: "मथुरा",
        name: "ओहावा"
    },
    {
        district: "मथुरा",
        name: "डांगौली"
    },
    {
        district: "मथुरा",
        name: "बेगमपुर"
    },
    {
        district: "मथुरा",
        name: "जहांगीरपुर"
    },
    {
        district: "मथुरा",
        name: "पानीगांव"
    },
    {
        district: "मथुरा",
        name: "मांट राजा"
    },
    {
        district: "मथुरा",
        name: "धानौता"
    },
    {
        district: "मथुरा",
        name: "शाहपुर"
    },
    {
        district: "मथुरा",
        name: "शेरनगर"
    },
    {
        district: "मथुरा",
        name: "मझोई"
    },
    {
        district: "मथुरा",
        name: "शेरगढ"
    },
    {
        district: "मथुरा",
        name: "धीमरी"
    },
    {
        district: "मथुरा",
        name: "रामपुर"
    },
    {
        district: "मथुरा",
        name: "भौगांव"
    },
    {
        district: "मथुरा",
        name: "दलौता"
    },
    {
        district: "मथुरा",
        name: "पीरपुर"
    },
    {
        district: "मथुरा",
        name: "महमदपुर"
    },
    {
        district: "मथुरा",
        name: "मगोर्रा"
    },
    {
        district: "मथुरा",
        name: "फोंडर"
    },
    {
        district: "मथुरा",
        name: "रसूलपुर"
    },
    {
        district: "मथुरा",
        name: "मल्हू"
    },
    {
        district: "मथुरा",
        name: "पाडल"
    },
    {
        district: "मथुरा",
        name: "हाथिया"
    },
    {
        district: "मथुरा",
        name: "पलसों"
    },
    {
        district: "मथुरा",
        name: "मुड़सेरस"
    },
    {
        district: "मथुरा",
        name: "नैनूपट्टी"
    },
    {
        district: "मथुरा",
        name: "हंशगज (नगर - निगम)"
    },
    {
        district: "मथुरा",
        name: "विशनगंज (नगर-निगम)"
    },
    {
        district: "मथुरा",
        name: "ईशापुर (नगर-निगम)"
    },
    {
        district: "मथुरा",
        name: "न०कोल्हू (नगर-निगम)"
    },
    {
        district: "मथुरा",
        name: "रमण रेती आश्रम-महावन"
    },
    {
        district: "मथुरा",
        name: "लहरौली घाट"
    },
    {
        district: "मथुरा",
        name: "न० अकोस"
    },
    {
        district: "मथुरा",
        name: "नेरा"
    },
    {
        district: "मथुरा",
        name: "मौहपई- नेरा"
    },
    {
        district: "मथुरा",
        name: "सेहत"
    },
    {
        district: "मऊ",
        name: "चक्की मूसाडोहि"
    },
    {
        district: "मऊ",
        name: "गजियापुर"
    },
    {
        district: "मऊ",
        name: "परसिया जयरामगिरि"
    },
    {
        district: "मऊ",
        name: "तिघरा"
    },
    {
        district: "मऊ",
        name: "अहिरूपुर"
    },
    {
        district: "मऊ",
        name: "भेड़कुल सुल्तानपुर"
    },
    {
        district: "मऊ",
        name: "कुवरपरुवाँ"
    },
    {
        district: "मऊ",
        name: "मोलनापुर"
    },
    {
        district: "मऊ",
        name: "दुबारी"
    },
    {
        district: "मऊ",
        name: "धर्मपुर विशुनपुर"
    },
    {
        district: "मऊ",
        name: "लोकया"
    },
    {
        district: "मऊ",
        name: "मठिया"
    },
    {
        district: "मऊ",
        name: "सुवाह"
    },
    {
        district: "मऊ",
        name: "हरधौली"
    },
    {
        district: "मऊ",
        name: "चक भगवानदास"
    },
    {
        district: "मऊ",
        name: "नवली"
    },
    {
        district: "मऊ",
        name: "चिऊटीडाड"
    },
    {
        district: "मऊ",
        name: "लामी"
    },
    {
        district: "मऊ",
        name: "तारनपुर"
    },
    {
        district: "मऊ",
        name: "भैसा खरग (नई बजार)"
    },
    {
        district: "मऊ",
        name: "बीबीपुर"
    },
    {
        district: "मऊ",
        name: "गौरीडीह"
    },
    {
        district: "मऊ",
        name: "नुरूल्लाहपुर"
    },
    {
        district: "मऊ",
        name: "सुरजपुर"
    },
    {
        district: "मऊ",
        name: "रसूलपुर इनामुद्दीनपुर"
    },
    {
        district: "मऊ",
        name: "बहरामपुर"
    },
    {
        district: "मऊ",
        name: "सिसवा"
    },
    {
        district: "मऊ",
        name: "भैरोपुर"
    },
    {
        district: "मऊ",
        name: "पतनई बुजुर्ग"
    },
    {
        district: "मऊ",
        name: "बखरिया"
    },
    {
        district: "मऊ",
        name: "बहादुरपुर"
    },
    {
        district: "मऊ",
        name: "धनौली रामपुर"
    },
    {
        district: "मऊ",
        name: "कटघरा महलू"
    },
    {
        district: "मऊ",
        name: "भैसा खरग (जमीन सरहरा)"
    },
    {
        district: "मऊ",
        name: "किशुनपुर उर्फ सुग्गीचैरी"
    },
    {
        district: "मऊ",
        name: "टौस नदी"
    },
    {
        district: "मऊ",
        name: "टौंस नदी"
    },
    {
        district: "मऊ",
        name: "टौंस नदी"
    },
    {
        district: "मऊ",
        name: "टौंस नदी"
    },
    {
        district: "मऊ",
        name: "गाढ़ा ताल"
    },
    {
        district: "मऊ",
        name: "गाढ़ा ताल"
    },
    {
        district: "मऊ",
        name: "गाढ़ा ताल"
    },
    {
        district: "मऊ",
        name: "गालीबपुर"
    },
    {
        district: "मऊ",
        name: "नरौनी"
    },
    {
        district: "मऊ",
        name: "अतरारी"
    },
    {
        district: "मऊ",
        name: "भातकोल"
    },
    {
        district: "मऊ",
        name: "सरया"
    },
    {
        district: "मऊ",
        name: "बंदीघाट"
    },
    {
        district: "मऊ",
        name: "अमरहट"
    },
    {
        district: "मऊ",
        name: "जमालपुर"
    },
    {
        district: "मेरठ",
        name: "मानपुर"
    },
    {
        district: "मेरठ",
        name: "रठौरा कलां"
    },
    {
        district: "मेरठ",
        name: "सिरजेपुर"
    },
    {
        district: "मेरठ",
        name: "दूधली खादर"
    },
    {
        district: "मेरठ",
        name: "बस्तौरा नारंग"
    },
    {
        district: "मेरठ",
        name: "मखदूमपुर"
    },
    {
        district: "मेरठ",
        name: "किशोरपुर"
    },
    {
        district: "मेरठ",
        name: "जलालपुर जोहरा"
    },
    {
        district: "मेरठ",
        name: "कुन्हैडा"
    },
    {
        district: "मेरठ",
        name: "तारापुर"
    },
    {
        district: "मेरठ",
        name: "रठौरा खुर्द"
    },
    {
        district: "मेरठ",
        name: "डाडाका"
    },
    {
        district: "मेरठ",
        name: "लतीफपुर"
    },
    {
        district: "मेरठ",
        name: "सैफपुर फिरोजपुर"
    },
    {
        district: "मेरठ",
        name: "महमूदपुर सिखैडा"
    },
    {
        district: "मेरठ",
        name: "खोडराय"
    },
    {
        district: "मेरठ",
        name: "अलीपुर मोरना"
    },
    {
        district: "मेरठ",
        name: "सैफपुर कर्मचन्दपुर"
    },
    {
        district: "मेरठ",
        name: "निडावली"
    },
    {
        district: "मेरठ",
        name: "दरियापुर"
    },
    {
        district: "मेरठ",
        name: "नंगला गौरााई"
    },
    {
        district: "मेरठ",
        name: "आसिफाबाद"
    },
    {
        district: "मेरठ",
        name: "समरापुर"
    },
    {
        district: "मेरठ",
        name: "नीमका"
    },
    {
        district: "मेरठ",
        name: "सिकन्दरपुर"
    },
    {
        district: "मेरठ",
        name: "तरबियतपुर जनूवी"
    },
    {
        district: "मेरठ",
        name: "अगवानपुर"
    },
    {
        district: "मेरठ",
        name: "सालौर रसूलपुर पनाह"
    },
    {
        district: "मेरठ",
        name: "असीतपुर"
    },
    {
        district: "मेरठ",
        name: "गेसूपुर उत्त्तरी"
    },
    {
        district: "मेरठ",
        name: "तिगरी"
    },
    {
        district: "मेरठ",
        name: "गुबारिकपुर"
    },
    {
        district: "मेरठ",
        name: "खेडी मनिहार"
    },
    {
        district: "मेरठ",
        name: ""
    },
    {
        district: "मेरठ",
        name: "सांधन"
    },
    {
        district: "मेरठ",
        name: "अटौरा"
    },
    {
        district: "मेरठ",
        name: "खेडकी जदीद"
    },
    {
        district: "मेरठ",
        name: "मवानाखुर्द"
    },
    {
        district: "मेरठ",
        name: "राफन"
    },
    {
        district: "मेरठ",
        name: "फिटकरी"
    },
    {
        district: "मेरठ",
        name: "शोल्दा"
    },
    {
        district: "मेरठ",
        name: "भडौली"
    },
    {
        district: "मेरठ",
        name: "महलवाला"
    },
    {
        district: "मेरठ",
        name: "कैलीरामपुर"
    },
    {
        district: "मेरठ",
        name: "रार्धना"
    },
    {
        district: "मेरठ",
        name: "किठौर देहात"
    },
    {
        district: "मेरठ",
        name: "नंगली किठौर"
    },
    {
        district: "मेरठ",
        name: "बहरोडा"
    },
    {
        district: "मेरठ",
        name: "कायस्थ बडढा"
    },
    {
        district: "मेरठ",
        name: "पसवाडा"
    },
    {
        district: "मीरजापुर",
        name: "रायपुर  "
    },
    {
        district: "मीरजापुर",
        name: "कलना गहरवार "
    },
    {
        district: "मीरजापुर",
        name: "सुमतीअ "
    },
    {
        district: "मीरजापुर",
        name: "विजयपुर "
    },
    {
        district: "मीरजापुर",
        name: "बसुहारा "
    },
    {
        district: "मीरजापुर",
        name: "भटवारी "
    },
    {
        district: "मीरजापुर",
        name: "गलारा "
    },
    {
        district: "मीरजापुर",
        name: "महूगढ़ी "
    },
    {
        district: "मीरजापुर",
        name: "हरदी सहिजनि "
    },
    {
        district: "मीरजापुर",
        name: "मिर्जापुर खुर्द "
    },
    {
        district: "मीरजापुर",
        name: "जायपट्टी कला "
    },
    {
        district: "मीरजापुर",
        name: "सुरहा "
    },
    {
        district: "मीरजापुर",
        name: "जगापट्टी "
    },
    {
        district: "मीरजापुर",
        name: "महंगीपुर "
    },
    {
        district: "मीरजापुर",
        name: "मनौआ "
    },
    {
        district: "मीरजापुर",
        name: "तिलथी "
    },
    {
        district: "मीरजापुर",
        name: "अतरैला राजा "
    },
    {
        district: "मीरजापुर",
        name: "बामी "
    },
    {
        district: "मीरजापुर",
        name: "दूबर कला "
    },
    {
        district: "मीरजापुर",
        name: "महादेव "
    },
    {
        district: "मीरजापुर",
        name: "गाडौली "
    },
    {
        district: "मीरजापुर",
        name: "कनक सराई "
    },
    {
        district: "मीरजापुर",
        name: "मटियारी "
    },
    {
        district: "मीरजापुर",
        name: "रामापुर "
    },
    {
        district: "मीरजापुर",
        name: "बेरजी मुकुंदपुर "
    },
    {
        district: "मीरजापुर",
        name: "भेवार करमनपुर "
    },
    {
        district: "मीरजापुर",
        name: "गुरुसंडी "
    },
    {
        district: "मीरजापुर",
        name: "महेवा "
    },
    {
        district: "मीरजापुर",
        name: "पड़रा हनुमान "
    },
    {
        district: "मीरजापुर",
        name: "शाहपुर चैसा "
    },
    {
        district: "मीरजापुर",
        name: "बाघी "
    },
    {
        district: "मीरजापुर",
        name: "जलालपुर माफी "
    },
    {
        district: "मीरजापुर",
        name: "सोनई "
    },
    {
        district: "मीरजापुर",
        name: "टेढ़ुवा "
    },
    {
        district: "मीरजापुर",
        name: "अक्सौली "
    },
    {
        district: "मीरजापुर",
        name: "भरपुरा "
    },
    {
        district: "मीरजापुर",
        name: "चेंडुली "
    },
    {
        district: "मीरजापुर",
        name: "सिंधोरा "
    },
    {
        district: "मीरजापुर",
        name: "अमोई पुरवा "
    },
    {
        district: "मीरजापुर",
        name: "बसहि "
    },
    {
        district: "मीरजापुर",
        name: "ककरद "
    },
    {
        district: "मीरजापुर",
        name: "मलुआ "
    },
    {
        district: "मीरजापुर",
        name: "धनसिरिया "
    },
    {
        district: "मीरजापुर",
        name: "गोल्हनपुर "
    },
    {
        district: "मीरजापुर",
        name: "खोरडीह "
    },
    {
        district: "मीरजापुर",
        name: "सीरिया "
    },
    {
        district: "मीरजापुर",
        name: "सुल्तानपुर "
    },
    {
        district: "मीरजापुर",
        name: "बाघा "
    },
    {
        district: "मीरजापुर",
        name: "खैरा "
    },
    {
        district: "मीरजापुर",
        name: "रुदौली"
    },
    {
        district: "मुजपफरनगर",
        name: "धौलरी"
    },
    {
        district: "मुजपफरनगर",
        name: "बुडीना कलां"
    },
    {
        district: "मुजपफरनगर",
        name: "तितावी"
    },
    {
        district: "मुजपफरनगर",
        name: "लखान"
    },
    {
        district: "मुजपफरनगर",
        name: "कुटबा"
    },
    {
        district: "मुजपफरनगर",
        name: "कुटबी"
    },
    {
        district: "मुजपफरनगर",
        name: "शिकारपुर"
    },
    {
        district: "मुजपफरनगर",
        name: "बहरामगढ"
    },
    {
        district: "मुजपफरनगर",
        name: "खानपुर"
    },
    {
        district: "मुजपफरनगर",
        name: "बवाना"
    },
    {
        district: "मुजपफरनगर",
        name: "गढी नौआबाद"
    },
    {
        district: "मुजपफरनगर",
        name: "बाननगर"
    },
    {
        district: "मुजपफरनगर",
        name: "दीदाहेडी"
    },
    {
        district: "मुजपफरनगर",
        name: "रूकनपुर"
    },
    {
        district: "मुजपफरनगर",
        name: "नंगला राई"
    },
    {
        district: "मुजपफरनगर",
        name: "रोहाना खुर्द"
    },
    {
        district: "मुजपफरनगर",
        name: "स्याली"
    },
    {
        district: "मुजपफरनगर",
        name: "रहडया"
    },
    {
        district: "मुजपफरनगर",
        name: "जलालपुर नीला"
    },
    {
        district: "मुजपफरनगर",
        name: "हुसैनपुर"
    },
    {
        district: "मुजपफरनगर",
        name: "रामराज"
    },
    {
        district: "मुजपफरनगर",
        name: "जीवनपुरी"
    },
    {
        district: "मुजपफरनगर",
        name: "हंसावाला"
    },
    {
        district: "मुजपफरनगर",
        name: "अम्बरपुर"
    },
    {
        district: "मुजपफरनगर",
        name: "चन्दसीना"
    },
    {
        district: "मुजपफरनगर",
        name: "समौली"
    },
    {
        district: "मुजपफरनगर",
        name: "रतनपुरी"
    },
    {
        district: "मुजपफरनगर",
        name: "शुकताल"
    },
    {
        district: "मुजपफरनगर",
        name: "शुकतारी"
    },
    {
        district: "मुजपफरनगर",
        name: "फिरोजपुर"
    },
    {
        district: "मुजपफरनगर",
        name: "भुवापुर"
    },
    {
        district: "मुजपफरनगर",
        name: "इलाहाबास"
    },
    {
        district: "मुजपफरनगर",
        name: "रामपुर"
    },
    {
        district: "मुजपफरनगर",
        name: "बढींवाला"
    },
    {
        district: "मुजपफरनगर",
        name: "बामनहेडी"
    },
    {
        district: "मुजपफरनगर",
        name: "मिमलाना"
    },
    {
        district: "मुजपफरनगर",
        name: "शेरपुर"
    },
    {
        district: "मुजपफरनगर",
        name: "रजकल्लापुर"
    },
    {
        district: "मुजपफरनगर",
        name: "भदौला मारकपुर"
    },
    {
        district: "मुजपफरनगर",
        name: "बढीवाला"
    },
    {
        district: "मुजपफरनगर",
        name: "चमरावाला."
    },
    {
        district: "मुजपफरनगर",
        name: "शेरपुर"
    },
    {
        district: "मुजपफरनगर",
        name: "मोरकुक्का"
    },
    {
        district: "मुजपफरनगर",
        name: "आदमपुर"
    },
    {
        district: "मुजपफरनगर",
        name: "सावटू"
    },
    {
        district: "मुजपफरनगर",
        name: "दुल्हेरा"
    },
    {
        district: "मुजपफरनगर",
        name: "पलडी"
    },
    {
        district: "मुजपफरनगर",
        name: "ढिढांवली"
    },
    {
        district: "प्रयागराज ",
        name: "असरावे खुर्द"
    },
    {
        district: "प्रयागराज ",
        name: "जलालपुर घोषी"
    },
    {
        district: "प्रयागराज ",
        name: "असरावे कला"
    },
    {
        district: "प्रयागराज ",
        name: "मनौरी"
    },
    {
        district: "प्रयागराज ",
        name: "करेंहदा"
    },
    {
        district: "प्रयागराज ",
        name: "अकबरपुर मिर्जापुर"
    },
    {
        district: "प्रयागराज ",
        name: "मन्दरदेह माफी"
    },
    {
        district: "प्रयागराज ",
        name: "अहमदपुर पावन"
    },
    {
        district: "प्रयागराज ",
        name: "चफरी उपरहार"
    },
    {
        district: "प्रयागराज ",
        name: "मुबारकपुर उपरहार"
    },
    {
        district: "प्रयागराज ",
        name: "अकबरपुर गंगागंज"
    },
    {
        district: "प्रयागराज ",
        name: "मुबारकपुर पूरन कछार"
    },
    {
        district: "प्रयागराज ",
        name: "दादनपूर"
    },
    {
        district: "प्रयागराज ",
        name: "सुल्तानपुर"
    },
    {
        district: "प्रयागराज ",
        name: "रैपुरा"
    },
    {
        district: "प्रयागराज ",
        name: "अरई"
    },
    {
        district: "प्रयागराज ",
        name: "बघेड़ा"
    },
    {
        district: "प्रयागराज ",
        name: "मछहर उफ्र्र पुरवा"
    },
    {
        district: "प्रयागराज ",
        name: "सुलमई"
    },
    {
        district: "प्रयागराज ",
        name: "झीरी लच्छीपुर"
    },
    {
        district: "प्रयागराज ",
        name: "बड़ोंखरा "
    },
    {
        district: "प्रयागराज ",
        name: "संेमरी बाघराय"
    },
    {
        district: "प्रयागराज ",
        name: "कांेसफराकला"
    },
    {
        district: "प्रयागराज ",
        name: "बैदवारकला"
    },
    {
        district: "प्रयागराज ",
        name: "महुली"
    },
    {
        district: "प्रयागराज ",
        name: "रत्योरा करपिया"
    },
    {
        district: "प्रयागराज ",
        name: "दुमदुमा "
    },
    {
        district: "प्रयागराज ",
        name: "हरिहरपुर"
    },
    {
        district: "प्रयागराज ",
        name: "उसमापुर"
    },
    {
        district: "प्रयागराज ",
        name: "बढौली"
    },
    {
        district: "प्रयागराज ",
        name: "कसौधन उफृ् लक्षागृह"
    },
    {
        district: "प्रयागराज ",
        name: "कन्दला मवैया"
    },
    {
        district: "प्रयागराज ",
        name: "हरभानपुर"
    },
    {
        district: "प्रयागराज ",
        name: "चकअपराध"
    },
    {
        district: "प्रयागराज ",
        name: "मैलहन"
    },
    {
        district: "प्रयागराज ",
        name: "बीरभानपुर"
    },
    {
        district: "प्रयागराज ",
        name: "सराय शेखपुर उर्फ सलमापुर"
    },
    {
        district: "प्रयागराज ",
        name: "कुतुबद्दीनपुर उर्फ अहियापुर"
    },
    {
        district: "प्रयागराज ",
        name: "पकरी सेवार"
    },
    {
        district: "प्रयागराज ",
        name: "परनीपुर "
    },
    {
        district: "प्रयागराज ",
        name: "जेवनिया "
    },
    {
        district: "प्रयागराज ",
        name: "ओनौर"
    },
    {
        district: "प्रयागराज ",
        name: "समहन"
    },
    {
        district: "प्रयागराज ",
        name: "बंधवा"
    },
    {
        district: "प्रयागराज ",
        name: "चिल्ला गौहानी"
    },
    {
        district: "प्रयागराज ",
        name: "सुजौना"
    },
    {
        district: "प्रयागराज ",
        name: "कंजासा उपरहार"
    },
    {
        district: "प्रयागराज ",
        name: "टिकरी कला "
    },
    {
        district: "प्रयागराज ",
        name: "सेंहुड़ा"
    },
    {
        district: "प्रयागराज",
        name: "कांटी"
    },
    {
        district: "पीलीभीत",
        name: "मगरासा"
    },
    {
        district: "पीलीभीत",
        name: "कुकरीखेडा"
    },
    {
        district: "पीलीभीत",
        name: "संडिया मुस्तकिल"
    },
    {
        district: "पीलीभीत",
        name: "रायपुर मुस्तकिल"
    },
    {
        district: "पीलीभीत",
        name: "बेहरी"
    },
    {
        district: "पीलीभीत",
        name: "नांवकुंड"
    },
    {
        district: "पीलीभीत",
        name: "चन्दोई"
    },
    {
        district: "पीलीभीत",
        name: "सिमरिया ताराचन्द"
    },
    {
        district: "पीलीभीत",
        name: "प्यास"
    },
    {
        district: "पीलीभीत",
        name: "चिनौरा मुस्तकिल"
    },
    {
        district: "पीलीभीत",
        name: "मुडिया कुण्डरी"
    },
    {
        district: "पीलीभीत",
        name: "शेखापुर"
    },
    {
        district: "पीलीभीत",
        name: "खानपुर. उर्फ. वीरमपुर"
    },
    {
        district: "पीलीभीत",
        name: "भैसटा जलालपुर"
    },
    {
        district: "पीलीभीत",
        name: "कबूलपुर"
    },
    {
        district: "पीलीभीत",
        name: "अर्जुनपुर"
    },
    {
        district: "पीलीभीत",
        name: "किशनी"
    },
    {
        district: "पीलीभीत",
        name: "चैसर हरदौपट्टी"
    },
    {
        district: "पीलीभीत",
        name: "टिहुलिया"
    },
    {
        district: "पीलीभीत",
        name: "गजीपुर कुण्डा"
    },
    {
        district: "पीलीभीत",
        name: "गभिया सहराई"
    },
    {
        district: "पीलीभीत",
        name: "नौजलहा नकटहा"
    },
    {
        district: "पीलीभीत",
        name: "महाराजपुर"
    },
    {
        district: "पीलीभीत",
        name: "नगरिया खुर्द कलां"
    },
    {
        district: "पीलीभीत",
        name: "रमनगरा"
    },
    {
        district: "पीलीभीत",
        name: "पुरैना ता० महाराजपुर"
    },
    {
        district: "पीलीभीत",
        name: "सेल्हा"
    },
    {
        district: "पीलीभीत",
        name: "मटैया लालपुर"
    },
    {
        district: "पीलीभीत",
        name: "मझारा. ता० महाराजपुर"
    },
    {
        district: "पीलीभीत",
        name: "धुरिया पलिया"
    },
    {
        district: "पीलीभीत",
        name: "उण्डरा"
    },
    {
        district: "पीलीभीत",
        name: "भूडा"
    },
    {
        district: "पीलीभीत",
        name: "नगरिया मुस्तकिल"
    },
    {
        district: "पीलीभीत",
        name: "सुस्वार"
    },
    {
        district: "पीलीभीत",
        name: "अंडरायन मुस्तकिल"
    },
    {
        district: "पीलीभीत",
        name: "निवाढ ऐठपुर"
    },
    {
        district: "पीलीभीत",
        name: "भगा मोहम्मद गंज"
    },
    {
        district: "पीलीभीत",
        name: "कुरी"
    },
    {
        district: "पीलीभीत",
        name: "टोडरपुर सहराई"
    },
    {
        district: "पीलीभीत",
        name: "तुर्कपुरा उर्फ बढवार"
    },
    {
        district: "पीलीभीत",
        name: "चंदिया हजारा"
    },
    {
        district: "पीलीभीत",
        name: "नहरोसा"
    },
    {
        district: "पीलीभीत",
        name: "राणा प्रताप नगर"
    },
    {
        district: "पीलीभीत",
        name: "अशोक नगर"
    },
    {
        district: "पीलीभीत",
        name: "कबीरगंज"
    },
    {
        district: "पीलीभीत",
        name: "मौरनियां गांधीनगर"
    },
    {
        district: "पीलीभीत",
        name: "शास्त्रीनगर"
    },
    {
        district: "पीलीभीत",
        name: "सिद्धनगर"
    },
    {
        district: "पीलीभीत",
        name: "बैल्हा"
    },
    {
        district: "पीलीभीत",
        name: "श्रीनगर"
    },
    {
        district: "प्रतापगढ़",
        name: "अतरौरा मीरपुर "
    },
    {
        district: "प्रतापगढ़",
        name: "महदौरा "
    },
    {
        district: "प्रतापगढ़",
        name: "रत्तीपुर "
    },
    {
        district: "प्रतापगढ़",
        name: "चैखरा "
    },
    {
        district: "प्रतापगढ़",
        name: "गोई "
    },
    {
        district: "प्रतापगढ़",
        name: "खाभौर "
    },
    {
        district: "प्रतापगढ़",
        name: "खुघि कलां "
    },
    {
        district: "प्रतापगढ़",
        name: "यहियापुर"
    },
    {
        district: "प्रतापगढ़",
        name: "बकोल "
    },
    {
        district: "प्रतापगढ़",
        name: "गोगाहर "
    },
    {
        district: "प्रतापगढ़",
        name: "खानवारी "
    },
    {
        district: "प्रतापगढ़",
        name: "आइमा जातुपुर "
    },
    {
        district: "प्रतापगढ़",
        name: "औतारपुर "
    },
    {
        district: "प्रतापगढ़",
        name: "कोड़राजीत "
    },
    {
        district: "प्रतापगढ़",
        name: "अतौलिया "
    },
    {
        district: "प्रतापगढ़",
        name: "रामपुर गरौली "
    },
    {
        district: "प्रतापगढ़",
        name: "जहानाबाद "
    },
    {
        district: "प्रतापगढ़",
        name: "मौड़रा "
    },
    {
        district: "प्रतापगढ़",
        name: "देओली "
    },
    {
        district: "प्रतापगढ़",
        name: "कटैया नेवादा "
    },
    {
        district: "प्रतापगढ़",
        name: "तिलौरी "
    },
    {
        district: "प्रतापगढ़",
        name: "पहाड़पुर "
    },
    {
        district: "प्रतापगढ़",
        name: "सामापुर "
    },
    {
        district: "प्रतापगढ़",
        name: "चंदोका "
    },
    {
        district: "प्रतापगढ़",
        name: "परसरामपुर "
    },
    {
        district: "प्रतापगढ़",
        name: "सरौली "
    },
    {
        district: "प्रतापगढ़",
        name: "बजहा "
    },
    {
        district: "प्रतापगढ़",
        name: "नूरपुर "
    },
    {
        district: "प्रतापगढ़",
        name: "पुरेला "
    },
    {
        district: "प्रतापगढ़",
        name: "गड़ाई चकडैया "
    },
    {
        district: "प्रतापगढ़",
        name: "कटका बाली "
    },
    {
        district: "प्रतापगढ़",
        name: "मझिलहा "
    },
    {
        district: "प्रतापगढ़",
        name: "पुरे रैजु "
    },
    {
        district: "प्रतापगढ़",
        name: "असोग "
    },
    {
        district: "प्रतापगढ़",
        name: "पुरे धना टिकरिया"
    },
    {
        district: "प्रतापगढ़",
        name: "संग्रामगढ़ "
    },
    {
        district: "प्रतापगढ़",
        name: "जलालपुर "
    },
    {
        district: "प्रतापगढ़",
        name: "कोडरा मदूपुर "
    },
    {
        district: "प्रतापगढ़",
        name: "कोल बजारडीहा "
    },
    {
        district: "प्रतापगढ़",
        name: "कोठांवरिया "
    },
    {
        district: "प्रतापगढ़",
        name: "मवैया कलां "
    },
    {
        district: "प्रतापगढ़",
        name: "बादशाहपुर "
    },
    {
        district: "प्रतापगढ़",
        name: "भवानीगढ़ "
    },
    {
        district: "प्रतापगढ़",
        name: "चाहिन "
    },
    {
        district: "प्रतापगढ़",
        name: "दर्रा "
    },
    {
        district: "प्रतापगढ़",
        name: "मुस्तफाबाद "
    },
    {
        district: "प्रतापगढ़",
        name: "पिंजरी "
    },
    {
        district: "प्रतापगढ़",
        name: "रहतीकर "
    },
    {
        district: "प्रतापगढ़",
        name: "सरुआ "
    },
    {
        district: "प्रतापगढ़",
        name: "सिलौधी"
    },
    {
        district: "रायबरेली जं.",
        name: "रूपा मऊ  "
    },
    {
        district: "रायबरेली जं.",
        name: "इचैली "
    },
    {
        district: "रायबरेली जं.",
        name: "रामपुर सुडौली "
    },
    {
        district: "रायबरेली जं.",
        name: "नीमतीकार "
    },
    {
        district: "रायबरेली जं.",
        name: "थुलेण्डी "
    },
    {
        district: "रायबरेली जं.",
        name: "राजा मऊ "
    },
    {
        district: "रायबरेली जं.",
        name: "कुंवर मऊ "
    },
    {
        district: "रायबरेली जं.",
        name: "बरारा बुजुर्ग "
    },
    {
        district: "रायबरेली जं.",
        name: "तेरुखा "
    },
    {
        district: "रायबरेली जं.",
        name: "ऐहार "
    },
    {
        district: "रायबरेली जं.",
        name: "गौरहरदो "
    },
    {
        district: "रायबरेली जं.",
        name: "जलालपुर धाइ "
    },
    {
        district: "रायबरेली जं.",
        name: "बिरनवान "
    },
    {
        district: "रायबरेली जं.",
        name: "मऊ "
    },
    {
        district: "रायबरेली जं.",
        name: "डीह "
    },
    {
        district: "रायबरेली जं.",
        name: "पोथाई "
    },
    {
        district: "रायबरेली जं.",
        name: "कठवारा "
    },
    {
        district: "रायबरेली जं.",
        name: "जोहवा शर्की "
    },
    {
        district: "रायबरेली जं.",
        name: "जगतपुर "
    },
    {
        district: "रायबरेली जं.",
        name: "खिरोन "
    },
    {
        district: "रायबरेली जं.",
        name: "सामरी"
    },
    {
        district: "रायबरेली जं.",
        name: " पाहो "
    },
    {
        district: "रायबरेली जं.",
        name: "भीतर गाओं"
    },
    {
        district: "रायबरेली जं.",
        name: "अम्बर पश्चिम "
    },
    {
        district: "रायबरेली जं.",
        name: "सेमर पहा "
    },
    {
        district: "रायबरेली जं.",
        name: "बेहटा कलां "
    },
    {
        district: "रायबरेली जं.",
        name: "आलमपुर "
    },
    {
        district: "रायबरेली जं.",
        name: "मऊ "
    },
    {
        district: "रायबरेली जं.",
        name: "अतरेहटा"
    },
    {
        district: "रायबरेली जं.",
        name: "राही "
    },
    {
        district: "रायबरेली जं.",
        name: "बेला गुसीसी "
    },
    {
        district: "रायबरेली जं.",
        name: "बेला भेला "
    },
    {
        district: "रायबरेली जं.",
        name: "लोधवारी "
    },
    {
        district: "रायबरेली जं.",
        name: "इटौरा बुजुर्ग "
    },
    {
        district: "रायबरेली जं.",
        name: "रसूलपुर "
    },
    {
        district: "रायबरेली जं.",
        name: "धरई "
    },
    {
        district: "रायबरेली जं.",
        name: "बेवली "
    },
    {
        district: "रायबरेली जं.",
        name: "किठनवा "
    },
    {
        district: "रायबरेली जं.",
        name: "सूचि "
    },
    {
        district: "रायबरेली जं.",
        name: "मनेहरो "
    },
    {
        district: "रायबरेली जं.",
        name: "अतौरा बुजुर्ग "
    },
    {
        district: "रायबरेली जं.",
        name: "हाजीपुर "
    },
    {
        district: "रायबरेली जं.",
        name: "गोझारी "
    },
    {
        district: "रायबरेली जं.",
        name: "कौड़िहार "
    },
    {
        district: "रायबरेली जं.",
        name: "ओनई पहाड़पुर "
    },
    {
        district: "रायबरेली जं.",
        name: "कैलौली "
    },
    {
        district: "रायबरेली जं.",
        name: "बॉर्डर "
    },
    {
        district: "रायबरेली जं.",
        name: "गुढ़ा "
    },
    {
        district: "रायबरेली जं.",
        name: "पट्टी रहस कैथवाल मुस्तकिल "
    },
    {
        district: "रायबरेली जं.",
        name: "कन्दरावां"
    },
    {
        district: "रामपुर",
        name: "चंदायन  "
    },
    {
        district: "रामपुर",
        name: "चैकोनी "
    },
    {
        district: "रामपुर",
        name: "संकरा "
    },
    {
        district: "रामपुर",
        name: "कछनाल "
    },
    {
        district: "रामपुर",
        name: "बल खेड़ा "
    },
    {
        district: "रामपुर",
        name: "जोरासी "
    },
    {
        district: "रामपुर",
        name: "चंदेला "
    },
    {
        district: "रामपुर",
        name: "मुँदीआं कला "
    },
    {
        district: "रामपुर",
        name: "तालबपुर "
    },
    {
        district: "रामपुर",
        name: "चमरपुरा "
    },
    {
        district: "रामपुर",
        name: "चिकना "
    },
    {
        district: "रामपुर",
        name: "अफजलपुर पट्टी "
    },
    {
        district: "रामपुर",
        name: "इंडरी "
    },
    {
        district: "रामपुर",
        name: "मंडियां जोलपुर "
    },
    {
        district: "रामपुर",
        name: "फत्तेहपुर "
    },
    {
        district: "रामपुर",
        name: "आगापुर "
    },
    {
        district: "रामपुर",
        name: "गंगापुर कदिम "
    },
    {
        district: "रामपुर",
        name: "राजपुरा "
    },
    {
        district: "रामपुर",
        name: "करीमगंज "
    },
    {
        district: "रामपुर",
        name: "रास डांडिए "
    },
    {
        district: "रामपुर",
        name: "पशु पूरा "
    },
    {
        district: "रामपुर",
        name: "पतिआ "
    },
    {
        district: "रामपुर",
        name: "खानपुर जदीद "
    },
    {
        district: "रामपुर",
        name: "कृपया हप्पू"
    },
    {
        district: "रामपुर",
        name: "कुम्हारिया "
    },
    {
        district: "रामपुर",
        name: "परछाई "
    },
    {
        district: "रामपुर",
        name: "बेनजीर उर्फ घाटमपुर "
    },
    {
        district: "रामपुर",
        name: "मेहँदी नगर"
    },
    {
        district: "रामपुर",
        name: "शुमाली"
    },
    {
        district: "रामपुर",
        name: "बगरखा "
    },
    {
        district: "रामपुर",
        name: "करनपुर "
    },
    {
        district: "रामपुर",
        name: "भटपुरा "
    },
    {
        district: "रामपुर",
        name: "रवन्ना "
    },
    {
        district: "रामपुर",
        name: "सहविआ खुर्द "
    },
    {
        district: "रामपुर",
        name: "गढ़मरपट्टी मोती सिंह "
    },
    {
        district: "रामपुर",
        name: "थिरिया जदीद "
    },
    {
        district: "रामपुर",
        name: "मित्तरपुर अहरौला भुरसी "
    },
    {
        district: "रामपुर",
        name: "दनियापुर "
    },
    {
        district: "रामपुर",
        name: "हुसैनगंज "
    },
    {
        district: "रामपुर",
        name: "रायपुर "
    },
    {
        district: "रामपुर",
        name: "शिव नगर लोहरी "
    },
    {
        district: "रामपुर",
        name: "धीरज नगर "
    },
    {
        district: "रामपुर",
        name: "मिलक ताज खान "
    },
    {
        district: "रामपुर",
        name: "परशुपुरा"
    },
    {
        district: "रामपुर",
        name: "शहजादनगर "
    },
    {
        district: "रामपुर",
        name: "भोत भक्कल "
    },
    {
        district: "रामपुर",
        name: "चंदपुरा सीकमपुर "
    },
    {
        district: "रामपुर",
        name: "लालपुर "
    },
    {
        district: "रामपुर",
        name: "लम्बा खेड़ा "
    },
    {
        district: "रामपुर",
        name: "सरकारी"
    },
    {
        district: "शाहजहाँपुर",
        name: "कोला"
    },
    {
        district: "शाहजहाँपुर",
        name: "अल्हदादपुर बैहारी"
    },
    {
        district: "शाहजहाँपुर",
        name: "गुनारा"
    },
    {
        district: "शाहजहाँपुर",
        name: "निजामपुर नगरिया"
    },
    {
        district: "शाहजहाँपुर",
        name: "रघुनाथपुर"
    },
    {
        district: "शाहजहाँपुर",
        name: "जेरारहीमपुर"
    },
    {
        district: "शाहजहाँपुर",
        name: "भरथौली"
    },
    {
        district: "शाहजहाँपुर",
        name: "चैरासी"
    },
    {
        district: "शाहजहाँपुर",
        name: "फत्तेपुर बिरिया"
    },
    {
        district: "शाहजहाँपुर",
        name: "रामपुर ढकाडांडी"
    },
    {
        district: "शाहजहाँपुर",
        name: "बिहारीपुर"
    },
    {
        district: "शाहजहाँपुर",
        name: "रटा"
    },
    {
        district: "शाहजहाँपुर",
        name: "सरसवां"
    },
    {
        district: "शाहजहाँपुर",
        name: "विकमपुर चकौरा"
    },
    {
        district: "शाहजहाँपुर",
        name: "कूकहा महमूदपुर"
    },
    {
        district: "शाहजहाँपुर",
        name: "बमिहाना"
    },
    {
        district: "शाहजहाँपुर",
        name: "कुरगांव"
    },
    {
        district: "शाहजहाँपुर",
        name: "धुवला करीमनगर"
    },
    {
        district: "शाहजहाँपुर",
        name: "मरेना"
    },
    {
        district: "शाहजहाँपुर",
        name: "नगला देहातमाली"
    },
    {
        district: "शाहजहाँपुर",
        name: "पैलानी उत्तर"
    },
    {
        district: "शाहजहाँपुर",
        name: "चैराबगरखेत"
    },
    {
        district: "शाहजहाँपुर",
        name: "जरीनपुर"
    },
    {
        district: "शाहजहाँपुर",
        name: "वीघापुर सिठौली"
    },
    {
        district: "शाहजहाँपुर",
        name: "परौर"
    },
    {
        district: "शाहजहाँपुर",
        name: "दहिलिया"
    },
    {
        district: "शाहजहाँपुर",
        name: "कुनियाशाह नजीरपुर"
    },
    {
        district: "शाहजहाँपुर",
        name: "पृथ्वीपुर ढाई"
    },
    {
        district: "शाहजहाँपुर",
        name: "दोषपुर थोक"
    },
    {
        district: "शाहजहाँपुर",
        name: "मझारा"
    },
    {
        district: "शाहजहाँपुर",
        name: "इन्दलपुर"
    },
    {
        district: "शाहजहाँपुर",
        name: "इनायतपुर"
    },
    {
        district: "शाहजहाँपुर",
        name: "बिलन्दापुर"
    },
    {
        district: "शाहजहाँपुर",
        name: "सिंधौली"
    },
    {
        district: "शाहजहाँपुर",
        name: "गोलारायपुर"
    },
    {
        district: "शाहजहाँपुर",
        name: "कोरोकुइंया"
    },
    {
        district: "शाहजहाँपुर",
        name: "ररूआ"
    },
    {
        district: "शाहजहाँपुर",
        name: "महासिर"
    },
    {
        district: "शाहजहाँपुर",
        name: "नवावपुर गंगा"
    },
    {
        district: "शाहजहाँपुर",
        name: "सुनासर"
    },
    {
        district: "शाहजहाँपुर",
        name: "चैडेरा"
    },
    {
        district: "शाहजहाँपुर",
        name: "कारीमुकआपुर"
    },
    {
        district: "शाहजहाँपुर",
        name: "नागरपाल"
    },
    {
        district: "शाहजहाँपुर",
        name: "गुवारी"
    },
    {
        district: "शाहजहाँपुर",
        name: "सरौरा"
    },
    {
        district: "शाहजहाँपुर",
        name: "चांदा बरकतपुर"
    },
    {
        district: "शाहजहाँपुर",
        name: "धन्यौरा"
    },
    {
        district: "शाहजहाँपुर",
        name: "रहमाननगर उर्फ राईखेड़ा"
    },
    {
        district: "शाहजहाँपुर",
        name: "चैहनापुर"
    },
    {
        district: "शाहजहाँपुर",
        name: "भरगवां"
    },
    {
        district: "सहारनपुर",
        name: "असलपुर बस्था"
    },
    {
        district: "सहारनपुर",
        name: "नुनियारी"
    },
    {
        district: "सहारनपुर",
        name: "घौलरा"
    },
    {
        district: "सहारनपुर",
        name: "कस्वागढ"
    },
    {
        district: "सहारनपुर",
        name: "हबीबपुर उर्फ तपोवन"
    },
    {
        district: "सहारनपुर",
        name: "मढ़ती"
    },
    {
        district: "सहारनपुर",
        name: "बडकला"
    },
    {
        district: "सहारनपुर",
        name: "जसमौर"
    },
    {
        district: "सहारनपुर",
        name: "पाजराना"
    },
    {
        district: "सहारनपुर",
        name: "मिर्जापुर पोल"
    },
    {
        district: "सहारनपुर",
        name: "दबकोरा"
    },
    {
        district: "सहारनपुर",
        name: "जंघेडी"
    },
    {
        district: "सहारनपुर",
        name: "हसनपुर नौगावा"
    },
    {
        district: "सहारनपुर",
        name: "शाहबुद्दीनपुर"
    },
    {
        district: "सहारनपुर",
        name: "शाहपुर"
    },
    {
        district: "सहारनपुर",
        name: "नानोली"
    },
    {
        district: "सहारनपुर",
        name: "कासमपुर मजरा पाडली"
    },
    {
        district: "सहारनपुर",
        name: "मिरगपुर पाजूवाला"
    },
    {
        district: "सहारनपुर",
        name: "जंतपुर खुर्द"
    },
    {
        district: "सहारनपुर",
        name: "भाण्डूवाला"
    },
    {
        district: "सहारनपुर",
        name: "खुशहालीपुर"
    },
    {
        district: "सहारनपुर",
        name: "थापुल ईसमाईलपुर"
    },
    {
        district: "सहारनपुर",
        name: "टाबर अहतभाल"
    },
    {
        district: "सहारनपुर",
        name: "रानीपुर बरसी"
    },
    {
        district: "सहारनपुर",
        name: "सहसपुर जट्ट (डाल्लेवाला)"
    },
    {
        district: "सहारनपुर",
        name: "मन्धोर"
    },
    {
        district: "सहारनपुर",
        name: "नागल राजपूत"
    },
    {
        district: "सहारनपुर",
        name: "कुण्डा कलों"
    },
    {
        district: "सहारनपुर",
        name: "सनौली"
    },
    {
        district: "सहारनपुर",
        name: "कुण्डा खुर्द"
    },
    {
        district: "सहारनपुर",
        name: "रसूलपुर बल्लामाजरा"
    },
    {
        district: "सहारनपुर",
        name: "लखनौती अहतमाल जदीद"
    },
    {
        district: "सहारनपुर",
        name: "कल्लरहेडी"
    },
    {
        district: "सहारनपुर",
        name: "सौधेबास"
    },
    {
        district: "सहारनपुर",
        name: "ग्याजुद्दीनपुर"
    },
    {
        district: "सहारनपुर",
        name: "टोडरपुर"
    },
    {
        district: "सहारनपुर",
        name: "मझार"
    },
    {
        district: "सहारनपुर",
        name: "आल्हणपुर अहतमाल"
    },
    {
        district: "सहारनपुर",
        name: "दुमझडा अहतमाल"
    },
    {
        district: "सहारनपुर",
        name: "धाघाण्ड अहतमाल"
    },
    {
        district: "सहारनपुर",
        name: "नारगपुर"
    },
    {
        district: "सहारनपुर",
        name: "दुसनपुर अहतमाल"
    },
    {
        district: "सहारनपुर",
        name: "हसनपुर भलस्वा अहतमाल"
    },
    {
        district: "सहारनपुर",
        name: "दुधली दुखारा अहत्तमाल"
    },
    {
        district: "सहारनपुर",
        name: "गांगलहंडी अहतगाल"
    },
    {
        district: "सहारनपुर",
        name: "तिवाया अहतमाल"
    },
    {
        district: "सहारनपुर",
        name: "बडगाव"
    },
    {
        district: "सहारनपुर",
        name: "चकवाली"
    },
    {
        district: "सहारनपुर",
        name: "महतोली"
    },
    {
        district: "सहारनपुर",
        name: "सापली बत्री"
    },
    {
        district: "सम्भल",
        name: "बिलालपत"
    },
    {
        district: "सम्भल",
        name: "दुगावर"
    },
    {
        district: "सम्भल",
        name: "ओवरी"
    },
    {
        district: "सम्भल",
        name: "खासपुर"
    },
    {
        district: "सम्भल",
        name: "मनौटा"
    },
    {
        district: "सम्भल",
        name: "अकबरपुर गहरा"
    },
    {
        district: "सम्भल",
        name: "केशोपुर रसैटा"
    },
    {
        district: "सम्भल",
        name: "बहादुरनगर"
    },
    {
        district: "सम्भल",
        name: "पाठकपुर"
    },
    {
        district: "सम्भल",
        name: "धामपुर कान्हू"
    },
    {
        district: "सम्भल",
        name: "आनन्दपुर"
    },
    {
        district: "सम्भल",
        name: "चाटन"
    },
    {
        district: "सम्भल",
        name: "मझावली"
    },
    {
        district: "सम्भल",
        name: "नेहटा"
    },
    {
        district: "सम्भल",
        name: "पुरा"
    },
    {
        district: "सम्भल",
        name: "बेहटा साहू"
    },
    {
        district: "सम्भल",
        name: "भुलावई"
    },
    {
        district: "सम्भल",
        name: "अकरौली"
    },
    {
        district: "सम्भल",
        name: "कुहेरा"
    },
    {
        district: "सम्भल",
        name: "बहलोलपुर"
    },
    {
        district: "सम्भल",
        name: "सुनवर सराय"
    },
    {
        district: "सम्भल",
        name: "जयरामनगर"
    },
    {
        district: "सम्भल",
        name: "मानकपुर"
    },
    {
        district: "सम्भल",
        name: "उधरनपुर अजमतनगर"
    },
    {
        district: "सम्भल",
        name: "झुकैरा"
    },
    {
        district: "सम्भल",
        name: "दुवारी कला"
    },
    {
        district: "सम्भल",
        name: "चबूतरा"
    },
    {
        district: "सम्भल",
        name: "चिरवारी"
    },
    {
        district: "सम्भल",
        name: "सुल्तानगढ़"
    },
    {
        district: "सम्भल",
        name: "गढ़ी विचैला"
    },
    {
        district: "सम्भल",
        name: "कुदारसी"
    },
    {
        district: "सम्भल",
        name: "खगूपुरा"
    },
    {
        district: "सम्भल",
        name: "रामपुर मोहकम"
    },
    {
        district: "सम्भल",
        name: "डुप्टा खुर्द"
    },
    {
        district: "सम्भल",
        name: "पवसरा"
    },
    {
        district: "सम्भल",
        name: "मलिकपुर"
    },
    {
        district: "सम्भल",
        name: "सैफखां सराय"
    },
    {
        district: "सम्भल",
        name: "शेरखां सराय"
    },
    {
        district: "सम्भल",
        name: "गोविन्दपुर"
    },
    {
        district: "सम्भल",
        name: "जोगीपुर"
    },
    {
        district: "सम्भल",
        name: "बिछौली"
    },
    {
        district: "सम्भल",
        name: "अफजलपुर डरौली"
    },
    {
        district: "सम्भल",
        name: "कासमपुर जगरूप"
    },
    {
        district: "सम्भल",
        name: "शाहपुर चामरान"
    },
    {
        district: "सम्भल",
        name: "रहटौल"
    },
    {
        district: "सम्भल",
        name: "अतौरा"
    },
    {
        district: "सम्भल",
        name: "बटौआ"
    },
    {
        district: "सम्भल",
        name: "सदीरनपुर"
    },
    {
        district: "सम्भल",
        name: "मवई ठाकुरान"
    },
    {
        district: "सम्भल",
        name: "भारतल सिरसी"
    },
    {
        district: "संत कबीर नगर",
        name: "भक्टी"
    },
    {
        district: "संत कबीर नगर",
        name: "सांगठ"
    },
    {
        district: "संत कबीर नगर",
        name: "भगवानपुर"
    },
    {
        district: "संत कबीर नगर",
        name: "भैसठ"
    },
    {
        district: "संत कबीर नगर",
        name: "बिहारे"
    },
    {
        district: "संत कबीर नगर",
        name: "फेउसी"
    },
    {
        district: "संत कबीर नगर",
        name: "बकहा"
    },
    {
        district: "संत कबीर नगर",
        name: "बालूशासन"
    },
    {
        district: "संत कबीर नगर",
        name: "चन्दनी"
    },
    {
        district: "संत कबीर नगर",
        name: "उतरावल"
    },
    {
        district: "संत कबीर नगर",
        name: "कोल्हुआ लकडा"
    },
    {
        district: "संत कबीर नगर",
        name: "चैरी"
    },
    {
        district: "संत कबीर नगर",
        name: "कुस्फर उर्फ उर्दहवा"
    },
    {
        district: "संत कबीर नगर",
        name: "जंगलऊन"
    },
    {
        district: "संत कबीर नगर",
        name: "मोहम्मदपुर कठार"
    },
    {
        district: "संत कबीर नगर",
        name: "मोहद्दीपुर"
    },
    {
        district: "संत कबीर नगर",
        name: "रसूलाबाद"
    },
    {
        district: "संत कबीर नगर",
        name: "साड़ेकला"
    },
    {
        district: "संत कबीर नगर",
        name: "साड़ेखुर्द"
    },
    {
        district: "संत कबीर नगर",
        name: "जोरवा"
    },
    {
        district: "संत कबीर नगर",
        name: "बेलौहा"
    },
    {
        district: "संत कबीर नगर",
        name: "भरथुआ"
    },
    {
        district: "संत कबीर नगर",
        name: "नौगो"
    },
    {
        district: "संत कबीर नगर",
        name: "बढ़या"
    },
    {
        district: "संत कबीर नगर",
        name: "कुसौना कला"
    },
    {
        district: "संत कबीर नगर",
        name: "जमोहरा"
    },
    {
        district: "संत कबीर नगर",
        name: "बरईपुर"
    },
    {
        district: "संत कबीर नगर",
        name: "हरपुर"
    },
    {
        district: "संत कबीर नगर",
        name: "सरफरा"
    },
    {
        district: "संत कबीर नगर",
        name: "पिडारीकला"
    },
    {
        district: "संत कबीर नगर",
        name: "बेलौली"
    },
    {
        district: "संत कबीर नगर",
        name: "डुमरियाबाबू"
    },
    {
        district: "संत कबीर नगर",
        name: "ढोढ"
    },
    {
        district: "संत कबीर नगर",
        name: "ओझापट्टी"
    },
    {
        district: "संत कबीर नगर",
        name: "सिसवनिया"
    },
    {
        district: "संत कबीर नगर",
        name: "शनिचरा चैबे"
    },
    {
        district: "संत कबीर नगर",
        name: "गोरयाघाट"
    },
    {
        district: "संत कबीर नगर",
        name: "बेलहरा"
    },
    {
        district: "संत कबीर नगर",
        name: "सांखी"
    },
    {
        district: "संत कबीर नगर",
        name: "तेजपुर"
    },
    {
        district: "संत कबीर नगर",
        name: "भोतहा खास"
    },
    {
        district: "संत कबीर नगर",
        name: "पड़रिया"
    },
    {
        district: "संत कबीर नगर",
        name: "लौकिया"
    },
    {
        district: "संत कबीर नगर",
        name: "नकहा"
    },
    {
        district: "संत कबीर नगर",
        name: "चकिया"
    },
    {
        district: "संत कबीर नगर",
        name: "नरायनपुर"
    },
    {
        district: "संत कबीर नगर",
        name: "चपरा पूर्वी"
    },
    {
        district: "संत कबीर नगर",
        name: "रामपुर दक्षिणी"
    },
    {
        district: "संत कबीर नगर",
        name: "गायघाट दक्षिणि"
    },
    {
        district: "संत कबीर नगर",
        name: "बहराडाडी"
    },
    {
        district: "शामली",
        name: "नंगलाराई"
    },
    {
        district: "शामली",
        name: "मामौर"
    },
    {
        district: "शामली",
        name: "सहपत"
    },
    {
        district: "शामली",
        name: "मवी हैदरपुर"
    },
    {
        district: "शामली",
        name: "मवी अह०तिमाली"
    },
    {
        district: "शामली",
        name: "रामडा"
    },
    {
        district: "शामली",
        name: "इस्सोपुर खुरगान"
    },
    {
        district: "शामली",
        name: "बसेडा"
    },
    {
        district: "शामली",
        name: "मण्डावर"
    },
    {
        district: "शामली",
        name: "मौहम्मदपुर राई"
    },
    {
        district: "शामली",
        name: "चैसाना जदीद"
    },
    {
        district: "शामली",
        name: "भडी मुस्तफाबाद"
    },
    {
        district: "शामली",
        name: "नाईनंगला सकौती"
    },
    {
        district: "शामली",
        name: "ऊदपुर"
    },
    {
        district: "शामली",
        name: "म्यानकस्बा"
    },
    {
        district: "शामली",
        name: "बिडौली"
    },
    {
        district: "शामली",
        name: "फतेहपुर औदरी"
    },
    {
        district: "शामली",
        name: "युसुफपुर चैतरा"
    },
    {
        district: "शामली",
        name: "डोकपुरा"
    },
    {
        district: "शामली",
        name: "डून्डूखेडा बांगर"
    },
    {
        district: "शामली",
        name: "इस्सोपुरटील"
    },
    {
        district: "शामली",
        name: "सलफा"
    },
    {
        district: "शामली",
        name: "किवाना"
    },
    {
        district: "शामली",
        name: "लिसाढ"
    },
    {
        district: "शामली",
        name: "मतनावली"
    },
    {
        district: "शामली",
        name: "बलवा"
    },
    {
        district: "शामली",
        name: "जलालपुर"
    },
    {
        district: "शामली",
        name: "खानपुर तलवा माजरा"
    },
    {
        district: "शामली",
        name: "बरला जट"
    },
    {
        district: "शामली",
        name: "काबडौत"
    },
    {
        district: "शामली",
        name: "झाल"
    },
    {
        district: "शामली",
        name: "बुटराडी"
    },
    {
        district: "शामली",
        name: "लिलोन"
    },
    {
        district: "शामली",
        name: "खेडीकरमू"
    },
    {
        district: "शामली",
        name: "सिक्का"
    },
    {
        district: "शामली",
        name: "सिलावर"
    },
    {
        district: "शामली",
        name: "करोडी"
    },
    {
        district: "शामली",
        name: "फतेहपुर"
    },
    {
        district: "शामली",
        name: "गोहरपुर"
    },
    {
        district: "शामली",
        name: "कुडाना"
    },
    {
        district: "शामली",
        name: "ताजपुर सिंभालका"
    },
    {
        district: "शामली",
        name: "गोहरनी"
    },
    {
        district: "शामली",
        name: "जलालाबाद देहात"
    },
    {
        district: "शामली",
        name: "थानाभवन देहात"
    },
    {
        district: "शामली",
        name: "चन्देनामाल"
    },
    {
        district: "शामली",
        name: "गोगवान जलालपुर"
    },
    {
        district: "शामली",
        name: "दभेडी"
    },
    {
        district: "शामली",
        name: "हिरनवाडा"
    },
    {
        district: "शामली",
        name: "दखोडी जमालपुर"
    },
    {
        district: "शामली",
        name: "तितारसी"
    },
    {
        district: "श्रावस्ती",
        name: "खच"
    },
    {
        district: "श्रावस्ती",
        name: "गोठवा"
    },
    {
        district: "श्रावस्ती",
        name: "जौगढ"
    },
    {
        district: "श्रावस्ती",
        name: "नौबस्ता"
    },
    {
        district: "श्रावस्ती",
        name: "के ावापुर"
    },
    {
        district: "श्रावस्ती",
        name: "मोहम्मदपुर कला"
    },
    {
        district: "श्रावस्ती",
        name: "अमवा"
    },
    {
        district: "श्रावस्ती",
        name: "टिकुइया"
    },
    {
        district: "श्रावस्ती",
        name: "अशरफनगर"
    },
    {
        district: "श्रावस्ती",
        name: "राजापुर वीरपुर"
    },
    {
        district: "श्रावस्ती",
        name: "मटखनवा"
    },
    {
        district: "श्रावस्ती",
        name: "तिलकपुर"
    },
    {
        district: "श्रावस्ती",
        name: "अलीनगर धर्मनगर"
    },
    {
        district: "श्रावस्ती",
        name: "रमनगरा"
    },
    {
        district: "श्रावस्ती",
        name: "नरायनपुर"
    },
    {
        district: "श्रावस्ती",
        name: "कसियापुर"
    },
    {
        district: "श्रावस्ती",
        name: "सेहनिया"
    },
    {
        district: "श्रावस्ती",
        name: "गौहनिया"
    },
    {
        district: "श्रावस्ती",
        name: "मध्यनगर"
    },
    {
        district: "श्रावस्ती",
        name: "भरथापुर"
    },
    {
        district: "श्रावस्ती",
        name: "कोलाभार मजगंवा"
    },
    {
        district: "श्रावस्ती",
        name: "कोडरी दीगर"
    },
    {
        district: "श्रावस्ती",
        name: "जगरावलगढ़ी"
    },
    {
        district: "श्रावस्ती",
        name: "दहावरकला"
    },
    {
        district: "श्रावस्ती",
        name: "अंधरपुरवा"
    },
    {
        district: "श्रावस्ती",
        name: "भोजपुर"
    },
    {
        district: "श्रावस्ती",
        name: "मनकाकोट"
    },
    {
        district: "श्रावस्ती",
        name: "महरौली"
    },
    {
        district: "श्रावस्ती",
        name: "इटवरिया"
    },
    {
        district: "श्रावस्ती",
        name: "मलौना खसियारी"
    },
    {
        district: "श्रावस्ती",
        name: "मोहम्मदपुर राजा"
    },
    {
        district: "श्रावस्ती",
        name: "शिवाजोत"
    },
    {
        district: "श्रावस्ती",
        name: "सेमगढ़ा"
    },
    {
        district: "श्रावस्ती",
        name: "लालपुर खदरा"
    },
    {
        district: "श्रावस्ती",
        name: "राजगढ़ गुलरिहा"
    },
    {
        district: "श्रावस्ती",
        name: "खरगौरा गनेश"
    },
    {
        district: "श्रावस्ती",
        name: "भरथा"
    },
    {
        district: "श्रावस्ती",
        name: "गजोबरी"
    },
    {
        district: "श्रावस्ती",
        name: "कथरामाफी"
    },
    {
        district: "श्रावस्ती",
        name: "सर्रा"
    },
    {
        district: "श्रावस्ती",
        name: "धूमबोझी दुर्गा"
    },
    {
        district: "श्रावस्ती",
        name: "लक्ष्मनपुर सेमरहनिया"
    },
    {
        district: "श्रावस्ती",
        name: "जोगिया"
    },
    {
        district: "श्रावस्ती",
        name: "बरगदहा"
    },
    {
        district: "श्रावस्ती",
        name: "कलकलवा"
    },
    {
        district: "श्रावस्ती",
        name: "गंगाभागड़"
    },
    {
        district: "श्रावस्ती",
        name: "बेलरी"
    },
    {
        district: "श्रावस्ती",
        name: "बीरपुर लौकिहा"
    },
    {
        district: "श्रावस्ती",
        name: "संगमपुरवा"
    },
    {
        district: "श्रावस्ती",
        name: "शिकारी चैड़ा"
    },
    {
        district: "सिद्धार्थनगर",
        name: "अमरिया"
    },
    {
        district: "सिद्धार्थनगर",
        name: "संगल दीप"
    },
    {
        district: "सिद्धार्थनगर",
        name: "अजगरा"
    },
    {
        district: "सिद्धार्थनगर",
        name: "लाऊ खाई"
    },
    {
        district: "सिद्धार्थनगर",
        name: "गाय घाट"
    },
    {
        district: "सिद्धार्थनगर",
        name: "बंगरा"
    },
    {
        district: "सिद्धार्थनगर",
        name: "हथिवड ताल"
    },
    {
        district: "सिद्धार्थनगर",
        name: "इटौआ"
    },
    {
        district: "सिद्धार्थनगर",
        name: "फत्तेपुर"
    },
    {
        district: "सिद्धार्थनगर",
        name: "खजूर डाड"
    },
    {
        district: "सिद्धार्थनगर",
        name: "भगौतापुर"
    },
    {
        district: "सिद्धार्थनगर",
        name: "सतवाढी"
    },
    {
        district: "सिद्धार्थनगर",
        name: "सोनखर"
    },
    {
        district: "सिद्धार्थनगर",
        name: "बकुआव"
    },
    {
        district: "सिद्धार्थनगर",
        name: "भुजराई"
    },
    {
        district: "सिद्धार्थनगर",
        name: "टडवलघाट"
    },
    {
        district: "सिद्धार्थनगर",
        name: "सूपघाट"
    },
    {
        district: "सिद्धार्थनगर",
        name: "हाटाखास"
    },
    {
        district: "सिद्धार्थनगर",
        name: "मझवनखुर्द"
    },
    {
        district: "सिद्धार्थनगर",
        name: "मझवनकला"
    },
    {
        district: "सिद्धार्थनगर",
        name: "पेडारी मु०"
    },
    {
        district: "सिद्धार्थनगर",
        name: "कोहलगोद कोट"
    },
    {
        district: "सिद्धार्थनगर",
        name: "राउतडीला मु०"
    },
    {
        district: "सिद्धार्थनगर",
        name: "बेव मु०"
    },
    {
        district: "सिद्धार्थनगर",
        name: "असनहरा माफी"
    },
    {
        district: "सिद्धार्थनगर",
        name: "सीरमझारी"
    },
    {
        district: "सिद्धार्थनगर",
        name: "सुकरौली"
    },
    {
        district: "सिद्धार्थनगर",
        name: "बेतना मु०"
    },
    {
        district: "सिद्धार्थनगर",
        name: "राजपुर"
    },
    {
        district: "सिद्धार्थनगर",
        name: "खन्ता"
    },
    {
        district: "सिद्धार्थनगर",
        name: "सिगारजोत"
    },
    {
        district: "सिद्धार्थनगर",
        name: "बेलवा"
    },
    {
        district: "सिद्धार्थनगर",
        name: "छगडिहवा"
    },
    {
        district: "सिद्धार्थनगर",
        name: "मुडिलिया"
    },
    {
        district: "सिद्धार्थनगर",
        name: "सोनौली नानकार"
    },
    {
        district: "सिद्धार्थनगर",
        name: "कठेला शर्की"
    },
    {
        district: "सिद्धार्थनगर",
        name: "औरहवा"
    },
    {
        district: "सिद्धार्थनगर",
        name: "मदरहवा"
    },
    {
        district: "सिद्धार्थनगर",
        name: "बजराभारी"
    },
    {
        district: "सिद्धार्थनगर",
        name: "नबेल"
    },
    {
        district: "सिद्धार्थनगर",
        name: "भैसहवा"
    },
    {
        district: "सिद्धार्थनगर",
        name: "भपसी"
    },
    {
        district: "सिद्धार्थनगर",
        name: "मझवन"
    },
    {
        district: "सिद्धार्थनगर",
        name: "बैजनथा"
    },
    {
        district: "सिद्धार्थनगर",
        name: "पिपरहवा"
    },
    {
        district: "सिद्धार्थनगर",
        name: "तौलिहवा"
    },
    {
        district: "सिद्धार्थनगर",
        name: "खैरी उर्फ क्षुगहवा"
    },
    {
        district: "सिद्धार्थनगर",
        name: "मटियार उर्फ भुतहवा"
    },
    {
        district: "सिद्धार्थनगर",
        name: "खैरी शीतलप्रसाद"
    },
    {
        district: "सिद्धार्थनगर",
        name: "रेकहट"
    },
    {
        district: "सीतापुर",
        name: "नौरंगपुर"
    },
    {
        district: "सीतापुर",
        name: "अल्लीपुर अलीरजा"
    },
    {
        district: "सीतापुर",
        name: "मानपुर मल्लापुर"
    },
    {
        district: "सीतापुर",
        name: "औरी शाहपुर"
    },
    {
        district: "सीतापुर",
        name: "चंदवासोत"
    },
    {
        district: "सीतापुर",
        name: "गोधनी सरैया"
    },
    {
        district: "सीतापुर",
        name: "बसुदहा"
    },
    {
        district: "सीतापुर",
        name: "बजेहरा"
    },
    {
        district: "सीतापुर",
        name: "रामगढ़"
    },
    {
        district: "सीतापुर",
        name: "कुर्सी"
    },
    {
        district: "सीतापुर",
        name: "सेलूमऊ"
    },
    {
        district: "सीतापुर",
        name: "ककराही"
    },
    {
        district: "सीतापुर",
        name: "बड़ेलिया"
    },
    {
        district: "सीतापुर",
        name: "जयरामपुर"
    },
    {
        district: "सीतापुर",
        name: "हरिहरपुर"
    },
    {
        district: "सीतापुर",
        name: "चिलवारा"
    },
    {
        district: "सीतापुर",
        name: "शहजादपुर"
    },
    {
        district: "सीतापुर",
        name: "सुल्तानपुर शाहपुर"
    },
    {
        district: "सीतापुर",
        name: "लालपुर"
    },
    {
        district: "सीतापुर",
        name: "गौरिया"
    },
    {
        district: "सीतापुर",
        name: "मिरचैड़ी"
    },
    {
        district: "सीतापुर",
        name: "धरमपुर"
    },
    {
        district: "सीतापुर",
        name: "हेलेपारा"
    },
    {
        district: "सीतापुर",
        name: "गोडैचा"
    },
    {
        district: "सीतापुर",
        name: "महुआ कोला"
    },
    {
        district: "सीतापुर",
        name: "कुँवरपुर गद्दी"
    },
    {
        district: "सीतापुर",
        name: "पनाहनगर"
    },
    {
        district: "सीतापुर",
        name: "ठाकुरनगर"
    },
    {
        district: "सीतापुर",
        name: "गोहलारी"
    },
    {
        district: "सीतापुर",
        name: "सदरावॉ"
    },
    {
        district: "सीतापुर",
        name: "कण्डारी"
    },
    {
        district: "सीतापुर",
        name: "सिकन्दरबाद"
    },
    {
        district: "सीतापुर",
        name: "पेंदारी"
    },
    {
        district: "सीतापुर",
        name: "कुँइयाखेड़ा"
    },
    {
        district: "सीतापुर",
        name: "अकोहरा"
    },
    {
        district: "सीतापुर",
        name: "मिर्जापुर"
    },
    {
        district: "सीतापुर",
        name: "उमरिया खानपुर"
    },
    {
        district: "सीतापुर",
        name: "कन्धेलिया"
    },
    {
        district: "सीतापुर",
        name: "अखरी"
    },
    {
        district: "सीतापुर",
        name: "अंगरौरा"
    },
    {
        district: "सीतापुर",
        name: "अटौरा"
    },
    {
        district: "सीतापुर",
        name: "गौलोक कोडर"
    },
    {
        district: "सीतापुर",
        name: "मल्लापुर"
    },
    {
        district: "सीतापुर",
        name: "काशीपुर"
    },
    {
        district: "सीतापुर",
        name: "शाहपुर"
    },
    {
        district: "सीतापुर",
        name: "लखनियापुर"
    },
    {
        district: "सीतापुर",
        name: "बेलवा बसैहिया"
    },
    {
        district: "सीतापुर",
        name: "अकबरपुर रेवान"
    },
    {
        district: "सीतापुर",
        name: "विर सिंहपुर"
    },
    {
        district: "सीतापुर",
        name: "नयागाँव"
    },
    {
        district: "सुलतानपुर",
        name: "पहाडपुरवस्तीपुर"
    },
    {
        district: "सुलतानपुर",
        name: "भीरपुरप्रतापपुर"
    },
    {
        district: "सुलतानपुर",
        name: "बीही निदूरा"
    },
    {
        district: "सुलतानपुर",
        name: "हेगनापुर"
    },
    {
        district: "सुलतानपुर",
        name: "इसौली"
    },
    {
        district: "सुलतानपुर",
        name: "जरईकला"
    },
    {
        district: "सुलतानपुर",
        name: "डीह"
    },
    {
        district: "सुलतानपुर",
        name: "कांपा"
    },
    {
        district: "सुलतानपुर",
        name: "हलियापुर"
    },
    {
        district: "सुलतानपुर",
        name: "मेघगऊ"
    },
    {
        district: "सुलतानपुर",
        name: "गर्दैया"
    },
    {
        district: "सुलतानपुर",
        name: "पुरैना"
    },
    {
        district: "सुलतानपुर",
        name: "ऐंजर"
    },
    {
        district: "सुलतानपुर",
        name: "चन्दौर"
    },
    {
        district: "सुलतानपुर",
        name: "कोरो"
    },
    {
        district: "सुलतानपुर",
        name: "गोसैसिंहपुर"
    },
    {
        district: "सुलतानपुर",
        name: "कटघरापट्टी"
    },
    {
        district: "सुलतानपुर",
        name: "बन्धुआकला"
    },
    {
        district: "सुलतानपुर",
        name: "भाई"
    },
    {
        district: "सुलतानपुर",
        name: "गोरावारिक"
    },
    {
        district: "सुलतानपुर",
        name: "हसनपुर"
    },
    {
        district: "सुलतानपुर",
        name: "कटावां"
    },
    {
        district: "सुलतानपुर",
        name: "लौहरदक्षिण"
    },
    {
        district: "सुलतानपुर",
        name: "बनकपुर"
    },
    {
        district: "सुलतानपुर",
        name: "बलहरी"
    },
    {
        district: "सुलतानपुर",
        name: "चरासा"
    },
    {
        district: "सुलतानपुर",
        name: "मुंगेर "
    },
    {
        district: "सुलतानपुर",
        name: "फतहपरसगत"
    },
    {
        district: "सुलतानपुर",
        name: "सरौली "
    },
    {
        district: "सुलतानपुर",
        name: "बांकेगॉंव"
    },
    {
        district: "सुलतानपुर",
        name: "रैवीगो "
    },
    {
        district: "सुलतानपुर",
        name: "पाकड़पुर"
    },
    {
        district: "सुलतानपुर",
        name: "देह्धागुपुर "
    },
    {
        district: "सुलतानपुर",
        name: "सैफुल्लागंज "
    },
    {
        district: "सुलतानपुर",
        name: "सैदखानपुर"
    },
    {
        district: "सुलतानपुर",
        name: "भण्डार परसरामपुर"
    },
    {
        district: "सुलतानपुर",
        name: ""
    },
    {
        district: "सुलतानपुर",
        name: "कुड़वार"
    },
    {
        district: "सुलतानपुर",
        name: "शाहपुरसरकण्डेडीह"
    },
    {
        district: "सुलतानपुर",
        name: "सोहगौली"
    },
    {
        district: "सुलतानपुर",
        name: "हाजीपट्टी"
    },
    {
        district: "सुलतानपुर",
        name: "कोटिया"
    },
    {
        district: "सुलतानपुर",
        name: "तोतोमुरैनी"
    },
    {
        district: "सुलतानपुर",
        name: "खरगपुर"
    },
    {
        district: "सुलतानपुर",
        name: "नानेमऊ"
    },
    {
        district: "सुलतानपुर",
        name: "शाहपुरलपटा"
    },
    {
        district: "सुलतानपुर",
        name: "प्रतापपुरकमैचा"
    },
    {
        district: "सुलतानपुर",
        name: "सोनावां"
    },
    {
        district: "सुलतानपुर",
        name: "भिरौरा"
    },
    {
        district: "सुलतानपुर",
        name: "शुकुलउमरी"
    },
    {
        district: "उन्नाव",
        name: "लाल खेड़ा"
    },
    {
        district: "उन्नाव",
        name: "सारूखेडा"
    },
    {
        district: "उन्नाव",
        name: "कटरीगदनपुर आहार"
    },
    {
        district: "उन्नाव",
        name: "मेला आलपशाह"
    },
    {
        district: "उन्नाव",
        name: "फरीदपुर कट्टर"
    },
    {
        district: "उन्नाव",
        name: "जगतनगर"
    },
    {
        district: "उन्नाव",
        name: "सि०करन"
    },
    {
        district: "उन्नाव",
        name: "खुटहानौगवां"
    },
    {
        district: "उन्नाव",
        name: "बदियाखेड़ा"
    },
    {
        district: "उन्नाव",
        name: "बैदरा"
    },
    {
        district: "उन्नाव",
        name: "कटरीअलुहापुर सरेसा"
    },
    {
        district: "उन्नाव",
        name: "विभौराचन्दनपुर"
    },
    {
        district: "उन्नाव",
        name: "निबई गै०ए1"
    },
    {
        district: "उन्नाव",
        name: "रजवाखेड़ा"
    },
    {
        district: "उन्नाव",
        name: "दुधौड़ा"
    },
    {
        district: "उन्नाव",
        name: "खैरहा एहतमाली"
    },
    {
        district: "उन्नाव",
        name: "बसधना"
    },
    {
        district: "उन्नाव",
        name: "कनिकामऊ"
    },
    {
        district: "उन्नाव",
        name: "कटरी मरौंदा मझवारा"
    },
    {
        district: "उन्नाव",
        name: "मिर्जापुर"
    },
    {
        district: "उन्नाव",
        name: "रौतापुर"
    },
    {
        district: "उन्नाव",
        name: "शंकरपुर सराय"
    },
    {
        district: "उन्नाव",
        name: "सन्नी"
    },
    {
        district: "उन्नाव",
        name: "नेतुआ"
    },
    {
        district: "उन्नाव",
        name: "बरबट"
    },
    {
        district: "उन्नाव",
        name: "बक्सर"
    },
    {
        district: "उन्नाव",
        name: "सेंदूपुर"
    },
    {
        district: "उन्नाव",
        name: "संग्रामपुर"
    },
    {
        district: "उन्नाव",
        name: "दूलीखेडा"
    },
    {
        district: "उन्नाव",
        name: "छांछीराई खेडा"
    },
    {
        district: "उन्नाव",
        name: "रूपपुर चन्देला"
    },
    {
        district: "उन्नाव",
        name: "ककरौरा"
    },
    {
        district: "उन्नाव",
        name: "रुस्तमपुर"
    },
    {
        district: "उन्नाव",
        name: "जाजामऊ एहतमाली"
    },
    {
        district: "उन्नाव",
        name: "जैतपुर"
    },
    {
        district: "उन्नाव",
        name: "गडाई"
    },
    {
        district: "उन्नाव",
        name: "सैंता"
    },
    {
        district: "उन्नाव",
        name: "बेहटा कच्छ"
    },
    {
        district: "उन्नाव",
        name: "सिरधरपुर एह०"
    },
    {
        district: "उन्नाव",
        name: "भिखारीपुर पतसिया"
    },
    {
        district: "उन्नाव",
        name: "खैरहन"
    },
    {
        district: "उन्नाव",
        name: "सरांय कटियान"
    },
    {
        district: "उन्नाव",
        name: "असोहा"
    },
    {
        district: "उन्नाव",
        name: "सोहरामऊ"
    },
    {
        district: "उन्नाव",
        name: "बेहटा भवानी"
    },
    {
        district: "उन्नाव",
        name: "हिलौली"
    },
    {
        district: "उन्नाव",
        name: "खांनपुर"
    },
    {
        district: "उन्नाव",
        name: "मिर्जापुर अजिगांव"
    },
    {
        district: "उन्नाव",
        name: "हसनगंज"
    },
    {
        district: "उन्नाव",
        name: "पनापुर कला"
    },
{
        district: "आगरा",
        name: "अन्य"
    },
    {
        district: "अम्बेडकरनगर",
        name: "अन्य"
    },
    {
        district: "अमेठी",
        name: "अन्य"
    },
    {
        district: "अमरोहा",
        name: "अन्य"
    },
    {
        district: "औरैया",
        name: "अन्य"
    },
    {
        district: "बदायूँ",
        name: "अन्य"
    },
    {
        district: "बलरामपुर",
        name: "अन्य"
    },
    {
        district: "बांदा",
        name: "अन्य"
    },
    {
        district: "बरेली",
        name: "अन्य"
    },
    {
        district: "भदोही",
        name: "अन्य"
    },
    {
        district: "बागपत",
        name: "अन्य"
    },
    {
        district: "बिजनौर",
        name: "अन्य"
    },
    {
        district: "बुलन्दशहर",
        name: "अन्य"
    },
    {
        district: "चन्दौली",
        name: "अन्य"
    },
    {
        district: "देवरिया",
        name: "अन्य"
    },
    {
        district: "एटा",
        name: "अन्य"
    },
    {
        district: "इटावा",
        name: "अन्य"
    },
    {
        district: "फर्रुखाबाद",
        name: "अन्य"
    },
    {
        district: "फिरोजाबाद",
        name: "अन्य"
    },
    {
        district: "फतेहपुर",
        name: "अन्य"
    },
    {
        district: "गौतमबुद्धनगर",
        name: "अन्य"
    },
    {
        district: "गाजियाबाद",
        name: "अन्य"
    },
    {
        district: "हाथरस",
        name: "अन्य"
    },
    {
        district: "हमीरपुर",
        name: "अन्य"
    },
    {
        district: "हापुड़",
        name: "अन्य"
    },
    {
        district: "हरदोई",
        name: "अन्य"
    },
    {
        district: "जालौन",
        name: "अन्य"
    },
    {
        district: "जौनपुर",
        name: "अन्य"
    },
    {
        district: "कन्नौज",
        name: "अन्य"
    },
    {
        district: "कानपुर देहात",
        name: "अन्य"
    },
    {
        district: "कानपुर नगर",
        name: "अन्य"
    },
    {
        district: "कासगंज",
        name: "अन्य"
    },
    {
        district: "कौशाम्बी",
        name: "अन्य"
    },
    {
        district: "ललितपुर",
        name: "अन्य"
    },
    {
        district: "महोबा",
        name: "अन्य"
    },
    {
        district: "मैनपुरी ",
        name: "अन्य"
    },
    {
        district: "मथुरा",
        name: "अन्य"
    },
    {
        district: "मऊ",
        name: "अन्य"
    },
    {
        district: "मेरठ",
        name: "अन्य"
    },
    {
        district: "मीरजापुर",
        name: "अन्य"
    },
    {
        district: "मुजपफरनगर",
        name: "अन्य"
    },
    {
        district: "प्रयागराज ",
        name: "अन्य"
    },
    {
        district: "प्रयागराज",
        name: "अन्य"
    },
    {
        district: "पीलीभीत",
        name: "अन्य"
    },
    {
        district: "प्रतापगढ़",
        name: "अन्य"
    },
    {
        district: "रायबरेली जं.",
        name: "अन्य"
    },
    {
        district: "रामपुर",
        name: "अन्य"
    },
    {
        district: "शाहजहाँपुर",
        name: "अन्य"
    },
    {
        district: "सहारनपुर",
        name: "अन्य"
    },
    {
        district: "सम्भल",
        name: "अन्य"
    },
    {
        district: "संत कबीर नगर",
        name: "अन्य"
    },
    {
        district: "शामली",
        name: "अन्य"
    },
    {
        district: "श्रावस्ती",
        name: "अन्य"
    },
    {
        district: "सिद्धार्थनगर",
        name: "अन्य"
    },
    {
        district: "सीतापुर",
        name: "अन्य"
    },
    {
        district: "सुलतानपुर",
        name: "अन्य"
    },
    {
        district: "उन्नाव",
        name: "अन्य"
    }
]


// export const villages = [
//     {
//         district: "आगरा",
//         name: "उमरेठा"
//     },
//     {
//         district: "आगरा",
//         name: "क्यौरी"
//     },
//     {
//         district: "आगरा",
//         name: "जेवरा"
//     },
//     {
//         district: "आगरा",
//         name: "कुंवरखेड़ा"
//     },
//     {
//         district: "आगरा",
//         name: "गौसिंली"
//     },
//     {
//         district: "आगरा",
//         name: "बमरौली"
//     },
//     {
//         district: "आगरा",
//         name: "बासौनी"
//     },
//     {
//         district: "आगरा",
//         name: "खेडाराठौर"
//     },
//     {
//         district: "आगरा",
//         name: "रेहा"
//     },
//     {
//         district: "आगरा",
//         name: "बरेन्डा"
//     },
//     {
//         district: "आगरा",
//         name: "अई"
//     },
//     {
//         district: "आगरा",
//         name: "स्वारा"
//     },
//     {
//         district: "आगरा",
//         name: "भरापुर"
//     },
//     {
//         district: "आगरा",
//         name: "शाहिदपुर"
//     },
//     {
//         district: "आगरा",
//         name: "महरा चौधरी"
//     },
//     {
//         district: "आगरा",
//         name: "मडायना"
//     },
//     {
//         district: "आगरा",
//         name: "वाजीदपुर"
//     },
//     {
//         district: "आगरा",
//         name: "मेवलीखुर्द"
//     },
//     {
//         district: "आगरा",
//         name: "मेवलीकलां"
//     },
//     {
//         district: "आगरा",
//         name: "गुढा"
//     },
//     {
//         district: "आगरा",
//         name: "कुण्डौल"
//     },
//     {
//         district: "आगरा",
//         name: "वाईपुर"
//     },
//     {
//         district: "आगरा",
//         name: "मिढ़ाकुर"
//     },
//     {
//         district: "आगरा",
//         name: "मलपुरा"
//     },
//     {
//         district: "आगरा",
//         name: "वाद"
//     },
//     {
//         district: "आगरा",
//         name: "बरौली अहीर"
//     },
//     {
//         district: "आगरा",
//         name: "जारूआ कटरा"
//     },
//     {
//         district: "आगरा",
//         name: "अंगूठी"
//     },
//     {
//         district: "आगरा",
//         name: "विसारना"
//     },
//     {
//         district: "आगरा",
//         name: "अकोला"
//     },
//     {
//         district: "आगरा",
//         name: "बसई जगनेर"
//     },
//     {
//         district: "आगरा",
//         name: "सरैधी"
//     },
//     {
//         district: "आगरा",
//         name: "खेरागढ"
//     },
//     {
//         district: "आगरा",
//         name: "भिलावली"
//     },
//     {
//         district: "आगरा",
//         name: "बेरीचाहर"
//     },
//     {
//         district: "आगरा",
//         name: "सैंया"
//     },
//     {
//         district: "आगरा",
//         name: "तेहरा"
//     },
//     {
//         district: "आगरा",
//         name: "इरादतनगर"
//     },
//     {
//         district: "आगरा",
//         name: "कागारौल"
//     },
//     {
//         district: "आगरा",
//         name: "जगनेर"
//     },
//     {
//         district: "आगरा",
//         name: "सांधन"
//     },
//     {
//         district: "आगरा",
//         name: "कुकथला"
//     },
//     {
//         district: "आगरा",
//         name: "रूनकता"
//     },
//     {
//         district: "आगरा",
//         name: "ब्यारा"
//     },
//     {
//         district: "आगरा",
//         name: "जैगारा"
//     },
//     {
//         district: "आगरा",
//         name: "कौरई"
//     },
//     {
//         district: "आगरा",
//         name: "कराहरा"
//     },
//     {
//         district: "आगरा",
//         name: "रायभा"
//     },
//     {
//         district: "आगरा",
//         name: "जाजऊ"
//     },
//     {
//         district: "आगरा",
//         name: "दूरा"
//     },
//     {
//         district: "आगरा",
//         name: "सीकरी चार हिस्सा"
//     },
//     {
//         district: "आगरा",
//         name: "सामरा"
//     },
//     {
//         district: "आगरा",
//         name: "सुरहरा"
//     },
//     {
//         district: "आगरा",
//         name: "रायपुर"
//     },
//     {
//         district: "आगरा",
//         name: "गदपुरा"
//     },
//     {
//         district: "आगरा",
//         name: "रहनकंला"
//     },
//     {
//         district: "आगरा",
//         name: "पोइया"
//     },
//     {
//         district: "आगरा",
//         name: "हाजीपुर खेडा"
//     },
//     {
//         district: "आगरा",
//         name: "गिजौली"
//     },
//     {
//         district: "आगरा",
//         name: "पैतखेडा"
//     },
//     {
//         district: "आगरा",
//         name: "सौरई"
//     },
//     {
//         district: "आगरा",
//         name: "सराय दायरूपा"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "मकरही"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "धनुकारा"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "सरफुद्दीनपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "चहोड़ा शाहपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "नयागांव"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "सैदपुर लेडुवाडीह"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "गोवर्धनपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "जमलूपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "हकीमपुर खुर्द"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "मंसूरगंज"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "बिड़हरखास"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "तिलकारपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "तरौली मुबारकपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "फूलपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "पृथ्वीपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "केवटला"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "नरकटा बैरागीपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "अजमेरीबादशाहपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "नौरहनीरामपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "शंकरपुर विशुनपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "वर्गीनिजामपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "नसीराबाद"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "टण्डवा दरब"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "दुहिया"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "इस्माइलपुर बेल्दहा"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "अवसानपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "महरीपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "मांझा उल्टहवा"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "सलाहपुर रजौर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "ककराही"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "बड्डूपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "नैपुरा"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "खैरपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "रसूलपुर मुबारकपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "चिन्तौरा"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "कटरिया"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "आसोपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "सलारपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "करमपुर बरसावां"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "रायपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "मीरानपुर बभनपुरा"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "इन्दौरपुर उर्फ घिनहापुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "रायपुर वेलवाडाडी"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "माझा कम्हरिया"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "अराजी देवारा"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "किन्नूपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "मुबारकपुर पिकार"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "चाण्डीपुरकलाँ"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "फुलवरिया महारमपुर"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "केवटली"
//     },
//     {
//         district: "अमेठी",
//         name: "बघेल"
//     },
//     {
//         district: "अमेठी",
//         name: "बहादुरपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "ओदारी"
//     },
//     {
//         district: "अमेठी",
//         name: "मोहड्या केसरिया"
//     },
//     {
//         district: "अमेठी",
//         name: "चण्डेरिया"
//     },
//     {
//         district: "अमेठी",
//         name: "धौरहरा"
//     },
//     {
//         district: "अमेठी",
//         name: "भौसिंहपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "सोनारीकनू"
//     },
//     {
//         district: "अमेठी",
//         name: "पीपरपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "शिवगढ़ जलालपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "सुमेरपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "कडेरगांव"
//     },
//     {
//         district: "अमेठी",
//         name: "आलमपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "वासदेवपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "सोनारी"
//     },
//     {
//         district: "अमेठी",
//         name: "टीकरमाफी"
//     },
//     {
//         district: "अमेठी",
//         name: "पिछूती"
//     },
//     {
//         district: "अमेठी",
//         name: "मटियारी कला"
//     },
//     {
//         district: "अमेठी",
//         name: "कपूरीपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "जलालपुर तिवारी"
//     },
//     {
//         district: "अमेठी",
//         name: "गाजनपुर दुवरिया"
//     },
//     {
//         district: "अमेठी",
//         name: "कोछित"
//     },
//     {
//         district: "अमेठी",
//         name: "कोटवा"
//     },
//     {
//         district: "अमेठी",
//         name: "रसूलाबाद"
//     },
//     {
//         district: "अमेठी",
//         name: "बधौना"
//     },
//     {
//         district: "अमेठी",
//         name: "भागीरथपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "अकबरपुर फर्शी"
//     },
//     {
//         district: "अमेठी",
//         name: "रमई"
//     },
//     {
//         district: "अमेठी",
//         name: "रोहसी खुर्द"
//     },
//     {
//         district: "अमेठी",
//         name: "इटौजा पश्चिम"
//     },
//     {
//         district: "अमेठी",
//         name: "गौरीपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "बस्तीदेई"
//     },
//     {
//         district: "अमेठी",
//         name: "जुगराजपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "अहोरवा भवानी"
//     },
//     {
//         district: "अमेठी",
//         name: "जयनगरा"
//     },
//     {
//         district: "अमेठी",
//         name: "फूला"
//     },
//     {
//         district: "अमेठी",
//         name: "नीमपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "काजीपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "बूबूपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "हरखूगऊ"
//     },
//     {
//         district: "अमेठी",
//         name: "रामशाहपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "कसरावां"
//     },
//     {
//         district: "अमेठी",
//         name: "कौहार"
//     },
//     {
//         district: "अमेठी",
//         name: "सेंवई हेमगढ़"
//     },
//     {
//         district: "अमेठी",
//         name: "रामगढ़"
//     },
//     {
//         district: "अमेठी",
//         name: "सरवनपुर"
//     },
//     {
//         district: "अमेठी",
//         name: "लोहरता"
//     },
//     {
//         district: "अमेठी",
//         name: "कोरारी गिरधरशाह"
//     },
//     {
//         district: "अमेठी",
//         name: "भोएं"
//     },
//     {
//         district: "अमेठी",
//         name: "बलभद्रपुर"
//     },
//     {
//         district: "vejksgk ",
//         name: "iwjuiqj"
//     },
//     {
//         district: "vejksgk ",
//         name: "lgliqj vyhuxj"
//     },
//     {
//         district: "vejksgk ",
//         name: "pkanuxj"
//     },
//     {
//         district: "vejksgk ",
//         name: "dSylk"
//     },
//     {
//         district: "vejksgk ",
//         name: "fljlk [kqekj"
//     },
//     {
//         district: "vejksgk ",
//         name: "<fd;k peu"
//     },
//     {
//         district: "vejksgk ",
//         name: "vrjklh dyka"
//     },
//     {
//         district: "vejksgk ",
//         name: "fMMkSyh"
//     },
//     {
//         district: "vejksgk ",
//         name: "irsbZ [kkylk"
//     },
//     {
//         district: "vejksgk ",
//         name: "iiljk"
//     },
//     {
//         district: "vejksgk ",
//         name: "uUgsM+k vY;kjiqj"
//     },
//     {
//         district: "vejksgk ",
//         name: "cqMsjuk"
//     },
//     {
//         district: "vejksgk ",
//         name: "yrhQiqj"
//     },
//     {
//         district: "vejksgk ",
//         name: "ukSjkgu"
//     },
//     {
//         district: "vejksgk ",
//         name: "clsMk rxk"
//     },
//     {
//         district: "vejksgk ",
//         name: "ihykdq.M"
//     },
//     {
//         district: "vejksgk ",
//         name: "e[knweiqj"
//     },
//     {
//         district: "vejksgk ",
//         name: "gknhiqj dyk"
//     },
//     {
//         district: "vejksgk ",
//         name: "pduokyk eq0"
//     },
//     {
//         district: "vejksgk ",
//         name: "pduokyk ,s0"
//     },
//     {
//         district: "vejksgk ",
//         name: "okftniqj"
//     },
//     {
//         district: "vejksgk ",
//         name: "fo'kkoyh eq0"
//     },
//     {
//         district: "vejksgk ",
//         name: "jlwyiqj Hkaoj"
//     },
//     {
//         district: "vejksgk ",
//         name: "vkteiqj"
//     },
//     {
//         district: "vejksgk ",
//         name: "igkMiqj buk;r"
//     },
//     {
//         district: "vejksgk ",
//         name: "eqdkjeiqj"
//     },
//     {
//         district: "vejksgk ",
//         name: "yfEc;k"
//     },
//     {
//         district: "vejksgk ",
//         name: "iiljk [kknj"
//     },
//     {
//         district: "vejksgk ",
//         name: "flgkyh xkSlkbZ"
//     },
//     {
//         district: "vejksgk ",
//         name: "jgnjk"
//     },
//     {
//         district: "vejksgk ",
//         name: "ukSusj"
//     },
//     {
//         district: "vejksgk ",
//         name: "dkadkBsj"
//     },
//     {
//         district: "vejksgk ",
//         name: "ekSgEnkckn"
//     },
//     {
//         district: "vejksgk ",
//         name: "dqnSuk pd"
//     },
//     {
//         district: "vejksgk ",
//         name: "n;koyh [kkylk"
//     },
//     {
//         district: "vejksgk ",
//         name: "lrsM+k ,s0"
//     },
//     {
//         district: "vejksgk ",
//         name: "iwBh"
//     },
//     {
//         district: "vejksgk ",
//         name: "Vk.Mk"
//     },
//     {
//         district: "vejksgk ",
//         name: "ihiyh nkÅn"
//     },
//     {
//         district: "vejksgk ",
//         name: "clsM+k [kqnZ"
//     },
//     {
//         district: "vejksgk ",
//         name: "ihiyh esdpUn"
//     },
//     {
//         district: "vejksgk ",
//         name: "vkslkekQh"
//     },
//     {
//         district: "vejksgk ",
//         name: "jgjk"
//     },
//     {
//         district: "vejksgk ",
//         name: "nf<+;ky"
//     },
//     {
//         district: "vejksgk ",
//         name: "[kqfrZ;k"
//     },
//     {
//         district: "vejksgk ",
//         name: "eaxjkSyk "
//     },
//     {
//         district: "vejksgk ",
//         name: "nkSyriqj dyka"
//     },
//     {
//         district: "vejksgk ",
//         name: "vkneiqj"
//     },
//     {
//         district: "vejksgk ",
//         name: "<okjlh"
//     },
//     {
//         district: "vejksgk ",
//         name: "t;rkSyh"
//     },
//     {
//         district: "औरैया",
//         name: "गैली"
//     },
//     {
//         district: "औरैया",
//         name: "पाता"
//     },
//     {
//         district: "औरैया",
//         name: "मिरगांवा"
//     },
//     {
//         district: "औरैया",
//         name: "गुवारी"
//     },
//     {
//         district: "औरैया",
//         name: "पसुआ"
//     },
//     {
//         district: "औरैया",
//         name: "नौगवां"
//     },
//     {
//         district: "औरैया",
//         name: "नवीमोहन"
//     },
//     {
//         district: "औरैया",
//         name: "सवलपुर"
//     },
//     {
//         district: "औरैया",
//         name: "सिरयावा"
//     },
//     {
//         district: "औरैया",
//         name: "पिपरौली शिव"
//     },
//     {
//         district: "औरैया",
//         name: "फतेहपुर"
//     },
//     {
//         district: "औरैया",
//         name: "पूर्वा खुतेमदारी"
//     },
//     {
//         district: "औरैया",
//         name: "कुर्सी"
//     },
//     {
//         district: "औरैया",
//         name: "एरवा कुईली"
//     },
//     {
//         district: "औरैया",
//         name: "कुकरकाट"
//     },
//     {
//         district: "औरैया",
//         name: "हरचन्द्रपुर"
//     },
//     {
//         district: "औरैया",
//         name: "बांधमऊ"
//     },
//     {
//         district: "औरैया",
//         name: "बेला"
//     },
//     {
//         district: "औरैया",
//         name: "वंशी"
//     },
//     {
//         district: "औरैया",
//         name: "असजना"
//     },
//     {
//         district: "औरैया",
//         name: "गुहानी कलां"
//     },
//     {
//         district: "औरैया",
//         name: "जूहीखा"
//     },
//     {
//         district: "औरैया",
//         name: "भुरेपुर कलां"
//     },
//     {
//         district: "औरैया",
//         name: "जसवंतपुर"
//     },
//     {
//         district: "औरैया",
//         name: "गूँज"
//     },
//     {
//         district: "औरैया",
//         name: "कैथोंली"
//     },
//     {
//         district: "औरैया",
//         name: "अयाना"
//     },
//     {
//         district: "औरैया",
//         name: "अमावता"
//     },
//     {
//         district: "औरैया",
//         name: "बीधेपुर"
//     },
//     {
//         district: "औरैया",
//         name: "अनन्तराम"
//     },
//     {
//         district: "औरैया",
//         name: "कस्बा जाना"
//     },
//     {
//         district: "औरैया",
//         name: "चिटकापुर"
//     },
//     {
//         district: "औरैया",
//         name: "चकसत्तापुर"
//     },
//     {
//         district: "औरैया",
//         name: "हाफिजपुर"
//     },
//     {
//         district: "औरैया",
//         name: "सेऊपुर"
//     },
//     {
//         district: "औरैया",
//         name: "क्योंटरा"
//     },
//     {
//         district: "औरैया",
//         name: "मढ़ापुर"
//     },
//     {
//         district: "औरैया",
//         name: "जरूहौलीया"
//     },
//     {
//         district: "औरैया",
//         name: "मिहौली"
//     },
//     {
//         district: "औरैया",
//         name: "कस्बा खानपुर"
//     },
//     {
//         district: "औरैया",
//         name: "गोहना"
//     },
//     {
//         district: "औरैया",
//         name: "खरका"
//     },
//     {
//         district: "औरैया",
//         name: "मई"
//     },
//     {
//         district: "औरैया",
//         name: "करमपुर"
//     },
//     {
//         district: "औरैया",
//         name: "दासपुर"
//     },
//     {
//         district: "औरैया",
//         name: "भासौन"
//     },
//     {
//         district: "औरैया",
//         name: "पन्हर"
//     },
//     {
//         district: "औरैया",
//         name: "रसूलपुर हुलासराय"
//     },
//     {
//         district: "औरैया",
//         name: "चौकी"
//     },
//     {
//         district: "औरैया",
//         name: "फतेहपुर करम"
//     },
//     {
//         district: "बदायूँ",
//         name: "हुसैनपुर पुख्ता"
//     },
//     {
//         district: "बदायूँ",
//         name: "पिपरौल पुख्ता"
//     },
//     {
//         district: "बदायूँ",
//         name: "खजुरारा खाम"
//     },
//     {
//         district: "बदायूँ",
//         name: "अल्लीपुर मढैया"
//     },
//     {
//         district: "बदायूँ",
//         name: "पलिया पुख्ता"
//     },
//     {
//         district: "बदायूँ",
//         name: "ननाखेडा पुख्ता"
//     },
//     {
//         district: "बदायूँ",
//         name: "इन्द्राई"
//     },
//     {
//         district: "बदायूँ",
//         name: "जैतपुर खाम"
//     },
//     {
//         district: "बदायूँ",
//         name: "कुढा शाहपुर"
//     },
//     {
//         district: "बदायूँ",
//         name: "ककोडा"
//     },
//     {
//         district: "बदायूँ",
//         name: "सुन्द्रायन"
//     },
//     {
//         district: "बदायूँ",
//         name: "चन्दनपुर"
//     },
//     {
//         district: "बदायूँ",
//         name: "भैंसोरा"
//     },
//     {
//         district: "बदायूँ",
//         name: "भूडा भदरौल"
//     },
//     {
//         district: "बदायूँ",
//         name: "मजरा गंगबरार"
//     },
//     {
//         district: "बदायूँ",
//         name: "गढिया गंगबरार"
//     },
//     {
//         district: "बदायूँ",
//         name: "मुगर्रा टटेई"
//     },
//     {
//         district: "बदायूँ",
//         name: "बरौरा"
//     },
//     {
//         district: "बदायूँ",
//         name: "भकोड़ा"
//     },
//     {
//         district: "बदायूँ",
//         name: "कादरचौक"
//     },
//     {
//         district: "बदायूँ",
//         name: "खिरकवारी मानपुर पुख्ता"
//     },
//     {
//         district: "बदायूँ",
//         name: "औरंगाबाद ८० जामनी"
//     },
//     {
//         district: "बदायूँ",
//         name: "नगला वरन"
//     },
//     {
//         district: "बदायूँ",
//         name: "सिठौलिया खाम"
//     },
//     {
//         district: "बदायूँ",
//         name: "टोंटपुर करसरी"
//     },
//     {
//         district: "बदायूँ",
//         name: "मालपुर ततेरा मु०"
//     },
//     {
//         district: "बदायूँ",
//         name: "धापड़"
//     },
//     {
//         district: "बदायूँ",
//         name: "भीकमपुर ८० जामनी"
//     },
//     {
//         district: "बदायूँ",
//         name: "सेरई उर्फ विजयगढी"
//     },
//     {
//         district: "बदायूँ",
//         name: "केरई सेरई"
//     },
//     {
//         district: "बदायूँ",
//         name: "कुंवरपुर चांदन"
//     },
//     {
//         district: "बदायूँ",
//         name: "दियोरा शेखपुर"
//     },
//     {
//         district: "बदायूँ",
//         name: "पडरिया"
//     },
//     {
//         district: "बदायूँ",
//         name: "सिरसा खुर्द"
//     },
//     {
//         district: "बदायूँ",
//         name: "ढेल"
//     },
//     {
//         district: "बदायूँ",
//         name: "बैरपुर मानपुर"
//     },
//     {
//         district: "बदायूँ",
//         name: "हरदत्तपुर"
//     },
//     {
//         district: "बदायूँ",
//         name: "हमूपुर चमरपुरा"
//     },
//     {
//         district: "बदायूँ",
//         name: "कस्बा भगता नगला"
//     },
//     {
//         district: "बदायूँ",
//         name: "मुडारी सिधारपुर"
//     },
//     {
//         district: "बदायूँ",
//         name: "सरेली पुख्ता"
//     },
//     {
//         district: "बदायूँ",
//         name: "कटरा सहादतगंज"
//     },
//     {
//         district: "बदायूँ",
//         name: "अस्मयारफतपुर"
//     },
//     {
//         district: "बदायूँ",
//         name: "बघेली ताराचन्द्र"
//     },
//     {
//         district: "बदायूँ",
//         name: "टिकाई पुख्ता"
//     },
//     {
//         district: "बदायूँ",
//         name: "नगला शिम्भू पुख्ता"
//     },
//     {
//         district: "बदायूँ",
//         name: "भुण्डी"
//     },
//     {
//         district: "बदायूँ",
//         name: "ललोमई"
//     },
//     {
//         district: "बदायूँ",
//         name: "सिठौली खाम"
//     },
//     {
//         district: "बदायूँ",
//         name: "खेडा जलालपुर खाम"
//     },
//     {
//         district: "बलरामपुर",
//         name: "कोलवा"
//     },
//     {
//         district: "बलरामपुर",
//         name: "राजघाट"
//     },
//     {
//         district: "बलरामपुर",
//         name: "भगवतपुर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "परसियामाफी"
//     },
//     {
//         district: "बलरामपुर",
//         name: "सिरिया"
//     },
//     {
//         district: "बलरामपुर",
//         name: "मधवाजोत"
//     },
//     {
//         district: "बलरामपुर",
//         name: "भीखमपुर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "ढोढारी"
//     },
//     {
//         district: "बलरामपुर",
//         name: "टेंगनहियामानकोट"
//     },
//     {
//         district: "बलरामपुर",
//         name: "सेमरहना"
//     },
//     {
//         district: "बलरामपुर",
//         name: "जबदही"
//     },
//     {
//         district: "बलरामपुर",
//         name: "झौहना"
//     },
//     {
//         district: "बलरामपुर",
//         name: "कलन्दरपुर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "गौरात्रिकौलिया"
//     },
//     {
//         district: "बलरामपुर",
//         name: "नौबस्तामुड़िला"
//     },
//     {
//         district: "बलरामपुर",
//         name: "बेलवासुल्तानजोत"
//     },
//     {
//         district: "बलरामपुर",
//         name: "भगवानपुर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "मिर्जापुर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "लालाजोत"
//     },
//     {
//         district: "बलरामपुर",
//         name: "पैगापुर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "रोंवारी"
//     },
//     {
//         district: "बलरामपुर",
//         name: "कटराशंकरनगर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "सोनार"
//     },
//     {
//         district: "बलरामपुर",
//         name: "रामपुरबनघुसरा"
//     },
//     {
//         district: "बलरामपुर",
//         name: "सिसई"
//     },
//     {
//         district: "बलरामपुर",
//         name: "रछौडा"
//     },
//     {
//         district: "बलरामपुर",
//         name: "गंगापुरबांकी"
//     },
//     {
//         district: "बलरामपुर",
//         name: "अड़ारापाकड़"
//     },
//     {
//         district: "बलरामपुर",
//         name: "बहेरेकुईयां"
//     },
//     {
//         district: "बलरामपुर",
//         name: "गोदीपुर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "पूरेछीटन"
//     },
//     {
//         district: "बलरामपुर",
//         name: "बुड़न्तपुर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "साखीरेत"
//     },
//     {
//         district: "बलरामपुर",
//         name: "पटोहाकोट"
//     },
//     {
//         district: "बलरामपुर",
//         name: "लैवुड़वा"
//     },
//     {
//         district: "बलरामपुर",
//         name: "रूपनगर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "मिर्जापुर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "बेला"
//     },
//     {
//         district: "बलरामपुर",
//         name: "इटईमैदा"
//     },
//     {
//         district: "बलरामपुर",
//         name: "ढोवाडाबर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "मनियारिया"
//     },
//     {
//         district: "बलरामपुर",
//         name: "भिठौढ़ी"
//     },
//     {
//         district: "बलरामपुर",
//         name: "बिरदाबनियाभारी"
//     },
//     {
//         district: "बलरामपुर",
//         name: "रुस्तमनगर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "मोहनजोत"
//     },
//     {
//         district: "बलरामपुर",
//         name: "मिलौलीबाघाजोत"
//     },
//     {
//         district: "बलरामपुर",
//         name: "मटियरियाकर्मा"
//     },
//     {
//         district: "बलरामपुर",
//         name: "फत्तेपुर"
//     },
//     {
//         district: "बलरामपुर",
//         name: "नन्दौरी"
//     },
//     {
//         district: "बलरामपुर",
//         name: "बौड़िहार"
//     },
//     {
//         district: "बांदा",
//         name: "मऊ"
//     },
//     {
//         district: "बांदा",
//         name: "इंगुवा"
//     },
//     {
//         district: "बांदा",
//         name: "परसौली"
//     },
//     {
//         district: "बांदा",
//         name: "कमासिन"
//     },
//     {
//         district: "बांदा",
//         name: "बंथरी"
//     },
//     {
//         district: "बांदा",
//         name: "तिलौसा"
//     },
//     {
//         district: "बांदा",
//         name: "घूरी"
//     },
//     {
//         district: "बांदा",
//         name: "ओरन ग्रामीण"
//     },
//     {
//         district: "बांदा",
//         name: "बल्लान"
//     },
//     {
//         district: "बांदा",
//         name: "दफ्तरा"
//     },
//     {
//         district: "बांदा",
//         name: "कोर्रही"
//     },
//     {
//         district: "बांदा",
//         name: "उतरवॉ"
//     },
//     {
//         district: "बांदा",
//         name: "पिपरगवॉ"
//     },
//     {
//         district: "बांदा",
//         name: "सॉडी"
//     },
//     {
//         district: "बांदा",
//         name: "छिरहुँटा"
//     },
//     {
//         district: "बांदा",
//         name: "मुगूँस"
//     },
//     {
//         district: "बांदा",
//         name: "पपरेन्दा"
//     },
//     {
//         district: "बांदा",
//         name: "खप्टिहाकलॉ"
//     },
//     {
//         district: "बांदा",
//         name: "गोयरामुगली"
//     },
//     {
//         district: "बांदा",
//         name: "जारी"
//     },
//     {
//         district: "बांदा",
//         name: "मटौंध ग्रमीण"
//     },
//     {
//         district: "बांदा",
//         name: "मवईबुजुर्ग"
//     },
//     {
//         district: "बांदा",
//         name: "अछरौड़"
//     },
//     {
//         district: "बांदा",
//         name: "छनेहरालालपुर"
//     },
//     {
//         district: "बांदा",
//         name: "बहादुरपुर कालिंजर"
//     },
//     {
//         district: "बांदा",
//         name: "दुबरिया"
//     },
//     {
//         district: "बांदा",
//         name: "गुढ़ा कलॉ"
//     },
//     {
//         district: "बांदा",
//         name: "महोतरा"
//     },
//     {
//         district: "बांदा",
//         name: "बघेलावारी"
//     },
//     {
//         district: "बांदा",
//         name: "पल्हरी"
//     },
//     {
//         district: "बांदा",
//         name: "डढ़वामानपुर"
//     },
//     {
//         district: "बांदा",
//         name: "रामपुर"
//     },
//     {
//         district: "बांदा",
//         name: "चन्दवारा"
//     },
//     {
//         district: "बांदा",
//         name: "बडागांव"
//     },
//     {
//         district: "बांदा",
//         name: "जसपुरा"
//     },
//     {
//         district: "बांदा",
//         name: "सिन्धनकला"
//     },
//     {
//         district: "बांदा",
//         name: "अलिहा"
//     },
//     {
//         district: "बांदा",
//         name: "पंडरी"
//     },
//     {
//         district: "बांदा",
//         name: "पतवन"
//     },
//     {
//         district: "बांदा",
//         name: "पिण्डारन"
//     },
//     {
//         district: "बांदा",
//         name: "समगरा"
//     },
//     {
//         district: "बांदा",
//         name: "शिव"
//     },
//     {
//         district: "बांदा",
//         name: "सिमौनी"
//     },
//     {
//         district: "बांदा",
//         name: "बहेरी"
//     },
//     {
//         district: "बांदा",
//         name: "पचोखर"
//     },
//     {
//         district: "बांदा",
//         name: "गुमाई"
//     },
//     {
//         district: "बांदा",
//         name: "मसुरी"
//     },
//     {
//         district: "बांदा",
//         name: "अर्जुनाह"
//     },
//     {
//         district: "बांदा",
//         name: "गिरवां"
//     },
//     {
//         district: "बांदा",
//         name: "बिलगॉव"
//     },
//     {
//         district: "बरेली",
//         name: "jtiqjk"
//     },
//     {
//         district: "बरेली",
//         name: "ujk;u uxyk"
//     },
//     {
//         district: "बरेली",
//         name: "jktwuxyk"
//     },
//     {
//         district: "बरेली",
//         name: "iucfM+;k"
//     },
//     {
//         district: "बरेली",
//         name: "lwnuiqj"
//     },
//     {
//         district: "बरेली",
//         name: "[ktqgkbZ"
//     },
//     {
//         district: "बरेली",
//         name: "ftrkSj"
//     },
//     {
//         district: "बरेली",
//         name: "fofj;k ukjk;uiqj"
//     },
//     {
//         district: "बरेली",
//         name: "gkthiqj"
//     },
//     {
//         district: "बरेली",
//         name: "gkfQtxat"
//     },
//     {
//         district: "बरेली",
//         name: ">kÅuxyk"
//     },
//     {
//         district: "बरेली",
//         name: "clUriqj"
//     },
//     {
//         district: "बरेली",
//         name: "HkwM+k"
//     },
//     {
//         district: "बरेली",
//         name: "xksjkyksdukFkiqj"
//     },
//     {
//         district: "बरेली",
//         name: "xgcjk"
//     },
//     {
//         district: "बरेली",
//         name: "eksgEenxat"
//     },
//     {
//         district: "बरेली",
//         name: "leliqj"
//     },
//     {
//         district: "बरेली",
//         name: "cgj tkxhj"
//     },
//     {
//         district: "बरेली",
//         name: "vehj uxj"
//     },
//     {
//         district: "बरेली",
//         name: "tjik eksguiqj"
//     },
//     {
//         district: "बरेली",
//         name: "v/kdVk jCCkkuh csxe"
//     },
//     {
//         district: "बरेली",
//         name: "fogjhiqj vCnqy jgeku"
//     },
//     {
//         district: "बरेली",
//         name: "e/kquxyk"
//     },
//     {
//         district: "बरेली",
//         name: "uoknk bekekckn"
//     },
//     {
//         district: "बरेली",
//         name: "T;ksfr tkxhj"
//     },
//     {
//         district: "बरेली",
//         name: "ccwjh ccwjk"
//     },
//     {
//         district: "बरेली",
//         name: "dqojiqj rqylhiVVh"
//     },
//     {
//         district: "बरेली",
//         name: "ftxfu;k Hkxoariqj"
//     },
//     {
//         district: "बरेली",
//         name: "lqukSj ns'kuxj"
//     },
//     {
//         district: "बरेली",
//         name: "eukSuk"
//     },
//     {
//         district: "बरेली",
//         name: "txrhjk"
//     },
//     {
//         district: "बरेली",
//         name: "eÅpUniqj"
//     },
//     {
//         district: "बरेली",
//         name: "ckjh[ksM+k"
//     },
//     {
//         district: "बरेली",
//         name: "lqYrkuiqj"
//     },
//     {
//         district: "बरेली",
//         name: "enukiqj"
//     },
//     {
//         district: "बरेली",
//         name: "fcgkjhiqj"
//     },
//     {
//         district: "बरेली",
//         name: "cafM;k [kqnZ"
//     },
//     {
//         district: "बरेली",
//         name: "dknjxat"
//     },
//     {
//         district: "बरेली",
//         name: "csgjk"
//     },
//     {
//         district: "बरेली",
//         name: "uxfj;k dyka"
//     },
//     {
//         district: "बरेली",
//         name: "iucfM+;k"
//     },
//     {
//         district: "बरेली",
//         name: "eudjh"
//     },
//     {
//         district: "बरेली",
//         name: "fBfj;k [ksry"
//     },
//     {
//         district: "बरेली",
//         name: "rtiqjk uofn;k"
//     },
//     {
//         district: "बरेली",
//         name: "dksguh izrkiiqj"
//     },
//     {
//         district: "बरेली",
//         name: "ljnkjuxj"
//     },
//     {
//         district: "बरेली",
//         name: "c<+jbZ dqbZa;ka"
//     },
//     {
//         district: "बरेली",
//         name: "pUnkSvk"
//     },
//     {
//         district: "बरेली",
//         name: "ygj"
//     },
//     {
//         district: "बरेली",
//         name: "fQjkstiqj"
//     },
//     {
//         district: "भदोही",
//         name: "बनकट उपरवार"
//     },
//     {
//         district: "भदोही",
//         name: "अमिलौर"
//     },
//     {
//         district: "भदोही",
//         name: "बदरी"
//     },
//     {
//         district: "भदोही",
//         name: "बारीपुर"
//     },
//     {
//         district: "भदोही",
//         name: "बेरासपुर"
//     },
//     {
//         district: "भदोही",
//         name: "छेछुआ"
//     },
//     {
//         district: "भदोही",
//         name: "दानीपट्टी"
//     },
//     {
//         district: "भदोही",
//         name: "दुगुना"
//     },
//     {
//         district: "भदोही",
//         name: "गोपालपुर"
//     },
//     {
//         district: "भदोही",
//         name: "हरिरामपुर"
//     },
//     {
//         district: "भदोही",
//         name: "इटहरा"
//     },
//     {
//         district: "भदोही",
//         name: "कलिकमवैया"
//     },
//     {
//         district: "भदोही",
//         name: "खेमापुर"
//     },
//     {
//         district: "भदोही",
//         name: "कुड़ीकला"
//     },
//     {
//         district: "भदोही",
//         name: "कुडीखुर्द"
//     },
//     {
//         district: "भदोही",
//         name: "मवैयाथानसिंह"
//     },
//     {
//         district: "भदोही",
//         name: "नगरदह"
//     },
//     {
//         district: "भदोही",
//         name: "पुरवॉ"
//     },
//     {
//         district: "भदोही",
//         name: "सेमराध"
//     },
//     {
//         district: "भदोही",
//         name: "तुलसीकला"
//     },
//     {
//         district: "भदोही",
//         name: "बहपुरा"
//     },
//     {
//         district: "भदोही",
//         name: "फुलवरियों"
//     },
//     {
//         district: "भदोही",
//         name: "बेरवॉपहाड़पुर"
//     },
//     {
//         district: "भदोही",
//         name: "ओझापुर"
//     },
//     {
//         district: "भदोही",
//         name: "गोपालापुर कलिंजरा"
//     },
//     {
//         district: "भदोही",
//         name: "इब्राहिमपुर"
//     },
//     {
//         district: "भदोही",
//         name: "गुलौरी"
//     },
//     {
//         district: "भदोही",
//         name: "बिहरोजपुर"
//     },
//     {
//         district: "भदोही",
//         name: "केदारपुर"
//     },
//     {
//         district: "भदोही",
//         name: "डीघ"
//     },
//     {
//         district: "भदोही",
//         name: "अरई"
//     },
//     {
//         district: "भदोही",
//         name: "भभौरी"
//     },
//     {
//         district: "भदोही",
//         name: "धनतुलसी"
//     },
//     {
//         district: "भदोही",
//         name: "सागररायपुर"
//     },
//     {
//         district: "भदोही",
//         name: "नारेपार"
//     },
//     {
//         district: "भदोही",
//         name: "मूलापुर"
//     },
//     {
//         district: "भदोही",
//         name: "रामपुर"
//     },
//     {
//         district: "भदोही",
//         name: "चकनिरंजन"
//     },
//     {
//         district: "भदोही",
//         name: "भवानीपुर"
//     },
//     {
//         district: "भदोही",
//         name: "जहाँगीराबाद"
//     },
//     {
//         district: "भदोही",
//         name: "डेरवॉ"
//     },
//     {
//         district: "भदोही",
//         name: "बर्जीकला"
//     },
//     {
//         district: "भदोही",
//         name: "सहसेपुर"
//     },
//     {
//         district: "भदोही",
//         name: "उमापुर"
//     },
//     {
//         district: "भदोही",
//         name: "कठारी"
//     },
//     {
//         district: "भदोही",
//         name: "विठ्ठलपुर"
//     },
//     {
//         district: "भदोही",
//         name: "द्वारिकापुर"
//     },
//     {
//         district: "भदोही",
//         name: "ईटवॉ"
//     },
//     {
//         district: "भदोही",
//         name: "कसिदहाँ"
//     },
//     {
//         district: "भदोही",
//         name: "मुशीलाटपुर"
//     },
//     {
//         district: "बागपत",
//         name: "पाली"
//     },
//     {
//         district: "बागपत",
//         name: "सिसाना"
//     },
//     {
//         district: "बागपत",
//         name: "निवाडा"
//     },
//     {
//         district: "बागपत",
//         name: "गौरीपुर जवाहरनगर"
//     },
//     {
//         district: "बागपत",
//         name: "नैथला"
//     },
//     {
//         district: "बागपत",
//         name: "फैजुल्लापुर"
//     },
//     {
//         district: "बागपत",
//         name: "खेडा इस्लामपुर"
//     },
//     {
//         district: "बागपत",
//         name: "सुल्तानपुर हटाना"
//     },
//     {
//         district: "बागपत",
//         name: "पुरा"
//     },
//     {
//         district: "बागपत",
//         name: "मवीखुर्द"
//     },
//     {
//         district: "बागपत",
//         name: "अमीपुर बालैनी"
//     },
//     {
//         district: "बागपत",
//         name: "कहरका"
//     },
//     {
//         district: "बागपत",
//         name: "मुकारी"
//     },
//     {
//         district: "बागपत",
//         name: "घाटौली"
//     },
//     {
//         district: "बागपत",
//         name: "डौलचा"
//     },
//     {
//         district: "बागपत",
//         name: "शबगा"
//     },
//     {
//         district: "बागपत",
//         name: "जागोस"
//     },
//     {
//         district: "बागपत",
//         name: "टाण्डा"
//     },
//     {
//         district: "बागपत",
//         name: "बदरखा"
//     },
//     {
//         district: "बागपत",
//         name: "ककौर"
//     },
//     {
//         district: "बागपत",
//         name: "छपरौली"
//     },
//     {
//         district: "बागपत",
//         name: "नांगल"
//     },
//     {
//         district: "बागपत",
//         name: "कुर्डी"
//     },
//     {
//         district: "बागपत",
//         name: "गांगनौली"
//     },
//     {
//         district: "बागपत",
//         name: "मौजिजाबाद नांगल"
//     },
//     {
//         district: "बागपत",
//         name: "रंछाड"
//     },
//     {
//         district: "बागपत",
//         name: "सूजती"
//     },
//     {
//         district: "बागपत",
//         name: "हिम्मतपुर"
//     },
//     {
//         district: "बागपत",
//         name: "बरनावा"
//     },
//     {
//         district: "बागपत",
//         name: "खपराना"
//     },
//     {
//         district: "बागपत",
//         name: "मिलाना"
//     },
//     {
//         district: "बागपत",
//         name: "फखरपुर शेखपुरा"
//     },
//     {
//         district: "बागपत",
//         name: "गल्हैता"
//     },
//     {
//         district: "बागपत",
//         name: "शाहपुर बाण गंगा"
//     },
//     {
//         district: "बागपत",
//         name: "तवेलागढी"
//     },
//     {
//         district: "बागपत",
//         name: "काठा"
//     },
//     {
//         district: "बागपत",
//         name: "फुलैरा"
//     },
//     {
//         district: "बागपत",
//         name: "पूरनपुर नवादा"
//     },
//     {
//         district: "बागपत",
//         name: "सिंगोलीतगा"
//     },
//     {
//         district: "बागपत",
//         name: "शरफाबाद"
//     },
//     {
//         district: "बागपत",
//         name: "गढीकलंजरी"
//     },
//     {
//         district: "बागपत",
//         name: "ललियाना"
//     },
//     {
//         district: "बागपत",
//         name: "गौना शहवानपुर"
//     },
//     {
//         district: "बागपत",
//         name: "सांकरौद"
//     },
//     {
//         district: "बागपत",
//         name: "सुभानपुर"
//     },
//     {
//         district: "बागपत",
//         name: "हसनपुर मसूरी"
//     },
//     {
//         district: "बागपत",
//         name: "डुन्डाहैडा"
//     },
//     {
//         district: "बागपत",
//         name: "मवीकलां"
//     },
//     {
//         district: "बागपत",
//         name: "बसी"
//     },
//     {
//         district: "बागपत",
//         name: "खेकडा"
//     },
//     {
//         district: "fctukSj",
//         name: "jokuk f'kdkjiqj"
//     },
//     {
//         district: "fctukSj",
//         name: "fiRFkkiqj"
//     },
//     {
//         district: "fctukSj",
//         name: "ckthiqj tkxhj"
//     },
//     {
//         district: "fctukSj",
//         name: "ykEck [ksM+k"
//     },
//     {
//         district: "fctukSj",
//         name: "vehukckn"
//     },
//     {
//         district: "fctukSj",
//         name: "vtheqYykuxj"
//     },
//     {
//         district: "fctukSj",
//         name: "gjsoyh"
//     },
//     {
//         district: "fctukSj",
//         name: "jkeuxj"
//     },
//     {
//         district: "fctukSj",
//         name: "HkViqjk"
//     },
//     {
//         district: "fctukSj",
//         name: "[kkjh"
//     },
//     {
//         district: "fctukSj",
//         name: "nkjkuxj"
//     },
//     {
//         district: "fctukSj",
//         name: "i`Fohiqj"
//     },
//     {
//         district: "fctukSj",
//         name: "gjnkliqj x<h"
//     },
//     {
//         district: "fctukSj",
//         name: "vxjh"
//     },
//     {
//         district: "fctukSj",
//         name: "frcMh"
//     },
//     {
//         district: "fctukSj",
//         name: "gkfQtkckn 'kdhZ"
//     },
//     {
//         district: "fctukSj",
//         name: "ihiylkuk"
//     },
//     {
//         district: "fctukSj",
//         name: "yrhQiqj"
//     },
//     {
//         district: "fctukSj",
//         name: "ljdFky ek/kks"
//     },
//     {
//         district: "fctukSj",
//         name: "jlwyiqj uaxyk"
//     },
//     {
//         district: "fctukSj",
//         name: "jsgjk"
//     },
//     {
//         district: "fctukSj",
//         name: "dekyiqj"
//     },
//     {
//         district: "fctukSj",
//         name: "ihiyhtV"
//     },
//     {
//         district: "fctukSj",
//         name: "ljdFky"
//     },
//     {
//         district: "fctukSj",
//         name: "vdcjkckn"
//     },
//     {
//         district: "fctukSj",
//         name: "eseulknkr"
//     },
//     {
//         district: "fctukSj",
//         name: "cqMxjk"
//     },
//     {
//         district: "fctukSj",
//         name: "xukSjk"
//     },
//     {
//         district: "fctukSj",
//         name: "'kgyhiqj dksVjk"
//     },
//     {
//         district: "fctukSj",
//         name: "iwjSuh"
//     },
//     {
//         district: "fctukSj",
//         name: "'kknhiqj"
//     },
//     {
//         district: "fctukSj",
//         name: "dLck dksVjk"
//     },
//     {
//         district: "fctukSj",
//         name: "ekSTteiqj gjoa'k"
//     },
//     {
//         district: "fctukSj",
//         name: "rSewjiqj nhik"
//     },
//     {
//         district: "fctukSj",
//         name: "dkthokyk"
//     },
//     {
//         district: "fctukSj",
//         name: "vehjiqjnkl mQZ /keZuxjh"
//     },
//     {
//         district: "fctukSj",
//         name: "eqdheiqj /kk:"
//     },
//     {
//         district: "fctukSj",
//         name: "clsM+k [kqnZ"
//     },
//     {
//         district: "fctukSj",
//         name: "csxjktiqj"
//     },
//     {
//         district: "fctukSj",
//         name: "ik.Myh ek.Mw"
//     },
//     {
//         district: "fctukSj",
//         name: "iiljk"
//     },
//     {
//         district: "fctukSj",
//         name: "djheiqj eqckjd"
//     },
//     {
//         district: "fctukSj",
//         name: "'ks[kiqj x<w"
//     },
//     {
//         district: "fctukSj",
//         name: "ljk; vkye"
//     },
//     {
//         district: "fctukSj",
//         name: "jkgriqj [kqnZ"
//     },
//     {
//         district: "fctukSj",
//         name: "'kkgtgkWiqj tljFk"
//     },
//     {
//         district: "fctukSj",
//         name: "iStfu;kW"
//     },
//     {
//         district: "fctukSj",
//         name: "f'kokyk dyk"
//     },
//     {
//         district: "fctukSj",
//         name: "e>kSyk fcYykSp"
//     },
//     {
//         district: "fctukSj",
//         name: "lsyk"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "मुवारिकपुर बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "अहार बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "हसनपुर बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "सिरौरा बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "अकबरपुर उर्फ बच्चीखेडा बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "अनूपशहर बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "रूठ बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "भैरियाहरिद्वारपुर बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "उदयपुर खुर्द बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "कर्णवास बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "नयावास बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "बिलौनारूप बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "राजघाट बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "उदयगढी बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "नरौरा बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "नौदेई बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "रामघाट बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "कुराला"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "काहिरा"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "कोटा"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "लखावठी"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "लौहगरा"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "परताबपुर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "चिंगराबठी"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "वैरा फिरोजपुर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "देहरा"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "रवानी कटीरी बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "औरंगाबाद ताहरपुर बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "मांडू हसनगढी बांगर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "फतहपुर गुर्जर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "जावल"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "मुनी"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "करौरा"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "वाहनपुर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "हसनपुर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "धरपा चूहरपुर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "अरनिया मौजपुर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "आंचरू खुर्द"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "पूठरी खुर्द .."
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "खैरपुर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "रिवाडा"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "करीरां"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "हुर्थला"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "बरासऊ"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "इस्माईलपुर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "चन्देरू"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "आशादेई उर्फ प्रानगढ़"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "भौखेडा"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "हसनपुर जागीर"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "सुनपेड़ा"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "निठारी"
//     },
//     {
//         district: "चन्दौली",
//         name: "भतीजा"
//     },
//     {
//         district: "चन्दौली",
//         name: "देवकली"
//     },
//     {
//         district: "चन्दौली",
//         name: "सोगाई"
//     },
//     {
//         district: "चन्दौली",
//         name: "ओयरचक"
//     },
//     {
//         district: "चन्दौली",
//         name: "जमुडा"
//     },
//     {
//         district: "चन्दौली",
//         name: "पानी"
//     },
//     {
//         district: "चन्दौली",
//         name: "धपरी"
//     },
//     {
//         district: "चन्दौली",
//         name: "भोजपुर"
//     },
//     {
//         district: "चन्दौली",
//         name: "गांधना"
//     },
//     {
//         district: "चन्दौली",
//         name: "जफरपुर"
//     },
//     {
//         district: "चन्दौली",
//         name: "गरछा"
//     },
//     {
//         district: "चन्दौली",
//         name: ""
//     },
//     {
//         district: "चन्दौली",
//         name: ""
//     },
//     {
//         district: "चन्दौली",
//         name: ""
//     },
//     {
//         district: "चन्दौली",
//         name: "Ramgarh"
//     },
//     {
//         district: "चन्दौली",
//         name: "Sevari"
//     },
//     {
//         district: "चन्दौली",
//         name: ""
//     },
//     {
//         district: "चन्दौली",
//         name: "Tandakala"
//     },
//     {
//         district: "चन्दौली",
//         name: ""
//     },
//     {
//         district: "चन्दौली",
//         name: "Baghi"
//     },
//     {
//         district: "चन्दौली",
//         name: "जगमोहनी"
//     },
//     {
//         district: "चन्दौली",
//         name: "सोनवर"
//     },
//     {
//         district: "चन्दौली",
//         name: "पीतपुर"
//     },
//     {
//         district: "चन्दौली",
//         name: "गनेशपुर"
//     },
//     {
//         district: "चन्दौली",
//         name: "पुरानाडीह"
//     },
//     {
//         district: "चन्दौली",
//         name: "हिनौता द०"
//     },
//     {
//         district: "चन्दौली",
//         name: "मुजपफरपुर"
//     },
//     {
//         district: "चन्दौली",
//         name: "हंतिमपुर"
//     },
//     {
//         district: "चन्दौली",
//         name: "परासीकला"
//     },
//     {
//         district: "चन्दौली",
//         name: "मद्भूपुर"
//     },
//     {
//         district: "चन्दौली",
//         name: "पड्या"
//     },
//     {
//         district: "चन्दौली",
//         name: "पैतुआ:"
//     },
//     {
//         district: "चन्दौली",
//         name: "चनहटा"
//     },
//     {
//         district: "चन्दौली",
//         name: "भिखारीपुर"
//     },
//     {
//         district: "चन्दौली",
//         name: "खास"
//     },
//     {
//         district: "चन्दौली",
//         name: "सरिंगपुर"
//     },
//     {
//         district: "चन्दौली",
//         name: "करनील"
//     },
//     {
//         district: "चन्दौली",
//         name: "जैगुरी"
//     },
//     {
//         district: "चन्दौली",
//         name: "सेमरा"
//     },
//     {
//         district: "चन्दौली",
//         name: "नौडिहा"
//     },
//     {
//         district: "चन्दौली",
//         name: "पसहटा"
//     },
//     {
//         district: "चन्दौली",
//         name: "सेनहुली:"
//     },
//     {
//         district: "चन्दौली",
//         name: "बुद्धपुर"
//     },
//     {
//         district: "चन्दौली",
//         name: "प्रहलादपुर"
//     },
//     {
//         district: "चन्दौली",
//         name: "पंचगंगापुर"
//     },
//     {
//         district: "चन्दौली",
//         name: "कवलपुरा"
//     },
//     {
//         district: "चन्दौली",
//         name: "धरहरा"
//     },
//     {
//         district: "चन्दौली",
//         name: "कम्हारी"
//     },
//     {
//         district: "चन्दौली",
//         name: "फुल्ली"
//     },
//     {
//         district: "चन्दौली",
//         name: "डेढगावां"
//     },
//     {
//         district: "देवरिया",
//         name: "नगवा खास"
//     },
//     {
//         district: "देवरिया",
//         name: "गायधार"
//     },
//     {
//         district: "देवरिया",
//         name: "कृतपुरा"
//     },
//     {
//         district: "देवरिया",
//         name: "धरमपुर"
//     },
//     {
//         district: "देवरिया",
//         name: "पचरुखा"
//     },
//     {
//         district: "देवरिया",
//         name: "एकौना"
//     },
//     {
//         district: "देवरिया",
//         name: "इस्लामाबाद"
//     },
//     {
//         district: "देवरिया",
//         name: "टिघरा खैरवा"
//     },
//     {
//         district: "देवरिया",
//         name: "रतनपुर"
//     },
//     {
//         district: "देवरिया",
//         name: "होली बलिया"
//     },
//     {
//         district: "देवरिया",
//         name: "हड़हा"
//     },
//     {
//         district: "देवरिया",
//         name: "माझा नारायण"
//     },
//     {
//         district: "देवरिया",
//         name: "कोडर"
//     },
//     {
//         district: "देवरिया",
//         name: "परसिया देवार"
//     },
//     {
//         district: "देवरिया",
//         name: "विशुनपुर देवार"
//     },
//     {
//         district: "देवरिया",
//         name: "भदिला प्रथम"
//     },
//     {
//         district: "देवरिया",
//         name: "गौरा कटइलवा"
//     },
//     {
//         district: "देवरिया",
//         name: "कपरवार"
//     },
//     {
//         district: "देवरिया",
//         name: "बेलडाड"
//     },
//     {
//         district: "देवरिया",
//         name: "परसिया तिवारी"
//     },
//     {
//         district: "देवरिया",
//         name: "माहेन"
//     },
//     {
//         district: "देवरिया",
//         name: "मोहरा"
//     },
//     {
//         district: "देवरिया",
//         name: "समोगर"
//     },
//     {
//         district: "देवरिया",
//         name: "भदिला दोयम"
//     },
//     {
//         district: "देवरिया",
//         name: "कोटवा"
//     },
//     {
//         district: "देवरिया",
//         name: "पैना"
//     },
//     {
//         district: "देवरिया",
//         name: "नवापार"
//     },
//     {
//         district: "देवरिया",
//         name: "लवकनी गंगा"
//     },
//     {
//         district: "देवरिया",
//         name: "देईडिंहा"
//     },
//     {
//         district: "देवरिया",
//         name: "मईल"
//     },
//     {
//         district: "देवरिया",
//         name: "मौना गढ़वा"
//     },
//     {
//         district: "देवरिया",
//         name: "तेलिया अफगान"
//     },
//     {
//         district: "देवरिया",
//         name: "बकुची 2"
//     },
//     {
//         district: "देवरिया",
//         name: "नरियावं"
//     },
//     {
//         district: "देवरिया",
//         name: "देवसिया"
//     },
//     {
//         district: "देवरिया",
//         name: "छित्तूपुर"
//     },
//     {
//         district: "देवरिया",
//         name: "नरसिंहडाड़"
//     },
//     {
//         district: "देवरिया",
//         name: "भागलपुर"
//     },
//     {
//         district: "देवरिया",
//         name: "पिपराबांध"
//     },
//     {
//         district: "देवरिया",
//         name: "तेलिया कलां"
//     },
//     {
//         district: "देवरिया",
//         name: "बढौना हरदो"
//     },
//     {
//         district: "देवरिया",
//         name: "देउबारी"
//     },
//     {
//         district: "देवरिया",
//         name: "डुमरी"
//     },
//     {
//         district: "देवरिया",
//         name: "खरवनिया"
//     },
//     {
//         district: "देवरिया",
//         name: "पिण्डी"
//     },
//     {
//         district: "देवरिया",
//         name: "महलमंझरिया"
//     },
//     {
//         district: "देवरिया",
//         name: "तकिया धरहरा"
//     },
//     {
//         district: "देवरिया",
//         name: "सहियागढ़"
//     },
//     {
//         district: "देवरिया",
//         name: "चुरिया"
//     },
//     {
//         district: "देवरिया",
//         name: "नेमा"
//     },
//     {
//         district: "एटा",
//         name: "Sarai Aghat"
//     },
//     {
//         district: "एटा",
//         name: "Alipur"
//     },
//     {
//         district: "एटा",
//         name: "Angraya Jamunai"
//     },
//     {
//         district: "एटा",
//         name: "Kharsuliya"
//     },
//     {
//         district: "एटा",
//         name: "Ubhai Asadnagar"
//     },
//     {
//         district: "एटा",
//         name: "Aliganj Dehat"
//     },
//     {
//         district: "एटा",
//         name: "Sikandar Salwar"
//     },
//     {
//         district: "एटा",
//         name: "Nadrala"
//     },
//     {
//         district: "एटा",
//         name: "Raya"
//     },
//     {
//         district: "एटा",
//         name: "Rohina Mirjapur"
//     },
//     {
//         district: "एटा",
//         name: "Majrajat Awagarh"
//     },
//     {
//         district: "एटा",
//         name: "Tikathar"
//     },
//     {
//         district: "एटा",
//         name: "Jarani Khurd Kalan"
//     },
//     {
//         district: "एटा",
//         name: "Borra Kalan"
//     },
//     {
//         district: "एटा",
//         name: "Nuh Khash"
//     },
//     {
//         district: "एटा",
//         name: "Barai Kalyanpur"
//     },
//     {
//         district: "एटा",
//         name: "Majra Jatt Jaithra"
//     },
//     {
//         district: "एटा",
//         name: "Dhumri"
//     },
//     {
//         district: "एटा",
//         name: "Tarigwan"
//     },
//     {
//         district: "एटा",
//         name: "Paroli Suhagpur"
//     },
//     {
//         district: "एटा",
//         name: "Sahori"
//     },
//     {
//         district: "एटा",
//         name: "Kiriya Nagar Sahh"
//     },
//     {
//         district: "एटा",
//         name: "Phagnol"
//     },
//     {
//         district: "एटा",
//         name: "Bahgo"
//     },
//     {
//         district: "एटा",
//         name: "Bhaupura"
//     },
//     {
//         district: "एटा",
//         name: "Shakroli"
//     },
//     {
//         district: "एटा",
//         name: "Sah Nagar Timarua"
//     },
//     {
//         district: "एटा",
//         name: "Vichpuri"
//     },
//     {
//         district: "एटा",
//         name: "Ucha Gaun"
//     },
//     {
//         district: "एटा",
//         name: "Chirgwan"
//     },
//     {
//         district: "एटा",
//         name: "Mahan Mai"
//     },
//     {
//         district: "एटा",
//         name: "Nagal Mitan"
//     },
//     {
//         district: "एटा",
//         name: "Ahmad Nagar Bamnoi"
//     },
//     {
//         district: "एटा",
//         name: "Barai"
//     },
//     {
//         district: "एटा",
//         name: "Pilua"
//     },
//     {
//         district: "एटा",
//         name: "Dholeshwar"
//     },
//     {
//         district: "एटा",
//         name: "Babsa"
//     },
//     {
//         district: "एटा",
//         name: "Gahetu"
//     },
//     {
//         district: "एटा",
//         name: "Sogra"
//     },
//     {
//         district: "एटा",
//         name: "Basudhra"
//     },
//     {
//         district: "एटा",
//         name: "Raphat Nagar Saithra"
//     },
//     {
//         district: "एटा",
//         name: "Kheda Nijam Pur"
//     },
//     {
//         district: "एटा",
//         name: "Manora"
//     },
//     {
//         district: "एटा",
//         name: "Malawan"
//     },
//     {
//         district: "एटा",
//         name: "Chilasani"
//     },
//     {
//         district: "एटा",
//         name: "Jirsami"
//     },
//     {
//         district: "एटा",
//         name: "Bhagipur"
//     },
//     {
//         district: "एटा",
//         name: "Barthar"
//     },
//     {
//         district: "एटा",
//         name: "Rarpatti"
//     },
//     {
//         district: "एटा",
//         name: "Shitalpur"
//     },
//     {
//         district: "इटावा",
//         name: "टकरूपुरा"
//     },
//     {
//         district: "इटावा",
//         name: "पुरावली"
//     },
//     {
//         district: "इटावा",
//         name: "नन्दगवां"
//     },
//     {
//         district: "इटावा",
//         name: "नंगला बिलहटी"
//     },
//     {
//         district: "इटावा",
//         name: "इकनौर"
//     },
//     {
//         district: "इटावा",
//         name: "दिलीप नगर"
//     },
//     {
//         district: "इटावा",
//         name: "अन्दावा"
//     },
//     {
//         district: "इटावा",
//         name: "चिण्डौली"
//     },
//     {
//         district: "इटावा",
//         name: "बहादुरपुर घार"
//     },
//     {
//         district: "इटावा",
//         name: "ईश्वरीपुरा"
//     },
//     {
//         district: "इटावा",
//         name: "हरौली बहादुरपुर"
//     },
//     {
//         district: "इटावा",
//         name: "गढ़ा कास्दा"
//     },
//     {
//         district: "इटावा",
//         name: "भरेह"
//     },
//     {
//         district: "इटावा",
//         name: "अनेठा"
//     },
//     {
//         district: "इटावा",
//         name: "छिबरौली"
//     },
//     {
//         district: "इटावा",
//         name: "पथर्रा"
//     },
//     {
//         district: "इटावा",
//         name: "कंधेसीघार"
//     },
//     {
//         district: "इटावा",
//         name: "चॉदई"
//     },
//     {
//         district: "इटावा",
//         name: "कुन्दौल"
//     },
//     {
//         district: "इटावा",
//         name: "तेजपुरा"
//     },
//     {
//         district: "इटावा",
//         name: "ददरा"
//     },
//     {
//         district: "इटावा",
//         name: "कचहरी"
//     },
//     {
//         district: "इटावा",
//         name: "नीमरी"
//     },
//     {
//         district: "इटावा",
//         name: "बिडौरी"
//     },
//     {
//         district: "इटावा",
//         name: "अनेठा"
//     },
//     {
//         district: "इटावा",
//         name: "विठौली"
//     },
//     {
//         district: "इटावा",
//         name: "बंसरी"
//     },
//     {
//         district: "इटावा",
//         name: "चौरेला"
//     },
//     {
//         district: "इटावा",
//         name: "सहसों"
//     },
//     {
//         district: "इटावा",
//         name: "विहार"
//     },
//     {
//         district: "इटावा",
//         name: "सिण्डौस"
//     },
//     {
//         district: "इटावा",
//         name: "गोहानी"
//     },
//     {
//         district: "इटावा",
//         name: "नौगवां"
//     },
//     {
//         district: "इटावा",
//         name: "खिरीटी"
//     },
//     {
//         district: "इटावा",
//         name: "जगतौली"
//     },
//     {
//         district: "इटावा",
//         name: "हनुमन्तपुरा"
//     },
//     {
//         district: "इटावा",
//         name: "कुन्दौल"
//     },
//     {
//         district: "इटावा",
//         name: "बरेछा"
//     },
//     {
//         district: "इटावा",
//         name: "महुआ सूँडा"
//     },
//     {
//         district: "इटावा",
//         name: "पछांयगांव"
//     },
//     {
//         district: "इटावा",
//         name: "बसबारा"
//     },
//     {
//         district: "इटावा",
//         name: "धमना"
//     },
//     {
//         district: "इटावा",
//         name: "बढ़पुरा"
//     },
//     {
//         district: "इटावा",
//         name: "जरहौली"
//     },
//     {
//         district: "इटावा",
//         name: "मुसावली"
//     },
//     {
//         district: "इटावा",
//         name: "मिहौली"
//     },
//     {
//         district: "इटावा",
//         name: "गाती"
//     },
//     {
//         district: "इटावा",
//         name: "प्रतापनेर"
//     },
//     {
//         district: "इटावा",
//         name: "सितौरा"
//     },
//     {
//         district: "इटावा",
//         name: "बराखेडा"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "रजलामई"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "शरीफपुर छिछनी"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "रमापुरजसू"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "मंझना"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "नटवारा"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "हुसेनपुर तराई"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "उखरा"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "सिरौली"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "पखना"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "सितवनपुर पिथू"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "बिहार"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "बनकटी"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "जाजपुर बंजारा"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "रूखौया खालिकदादपुर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "कोकापुर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "रसीदपुर मई"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "भटासा"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "रमापुर दवीर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "पहाडपुर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "जनैया सितैया"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "बसेली"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "रम्पुरा"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "भाऊपुर खुर्द"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "हैवतपुर गढिया"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "कुबेरपुर घाट"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "घारमपुर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "अजमतपुर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "पिथूपुर मेहदिया"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "अताईपुर कोहना"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "अल्हापुर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "रूटौल"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "जिजपुरा"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "चिलौली"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "त्योरखास"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "बिल्हा"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "शाहीपुर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "सवासी"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "भरखा"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "बदनपुर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "कमालुददीनपुर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "सथरा"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "राई"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "कडक्का"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "जैनापुर महेशपुर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "भुवनपुर ताजपुर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "हिसामपुर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "चौकी महमदपुर"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "बन्दरखेडा"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "अहमदपुर देवरिया"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "नसरतपुर नौंगवा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "बामई"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "करहरा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "पीथेपुर"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "विनेय कुमार"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "कारीखेडा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "पतारा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "कादीपुर"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "भदाना"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "भगनेर"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "रामपुर"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "बिलहना"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "इटौरा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "सेंगई"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "विजयपुर न० भावसिंह"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "मोहम्मदपुर बिहारीपुर"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "आलमपुर जारखी"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "इटाहरी"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "कनवारा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "कटैना हर्षा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "शेखनपुर"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "रीवा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "रानीपुर"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "उपेन्द्र कुमार"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "बिठवारा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "जाजुमई"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "खेरीया पटिकरा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "सलेमपुर कर्खा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "पुनच्छा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "केसरी"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "कुतुकपुर शेरपुर"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "आंख खुर्द"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "कठफोरी"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "बछगांव"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "दौलतपुर"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "गांगनी"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "गोंछ"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "जौधरी"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "कार्तिकी"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "असुवा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "वासुदेवमई"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "हरिया"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "साडुपुर"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "रुपसपुर"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "जाफराबाद"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "हरगनपुर"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "देवखेडा"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "गढ़ी निर्भय"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "कोटकी"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "रसुलाबाद"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "शेरपुर मण्डनपुर"
//     },
//     {
//         district: "फतेहपुर",
//         name: "अल्लीपुर बहेरा"
//     },
//     {
//         district: "फतेहपुर",
//         name: "अल्लीपुर "
//     },
//     {
//         district: "फतेहपुर",
//         name: "बुदवन"
//     },
//     {
//         district: "फतेहपुर",
//         name: "विक्रमपुर"
//     },
//     {
//         district: "फतेहपुर",
//         name: "बेहटा"
//     },
//     {
//         district: "फतेहपुर",
//         name: "अमौली"
//     },
//     {
//         district: "फतेहपुर",
//         name: "सराय धर्मपुर"
//     },
//     {
//         district: "फतेहपुर",
//         name: "कोठा बखरिया"
//     },
//     {
//         district: "फतेहपुर",
//         name: "ललौली"
//     },
//     {
//         district: "फतेहपुर",
//         name: "कौण्डर"
//     },
//     {
//         district: "फतेहपुर",
//         name: "देवलान"
//     },
//     {
//         district: "फतेहपुर",
//         name: "धरमपुर सुसवन खुर्द"
//     },
//     {
//         district: "फतेहपुर",
//         name: "खटौली"
//     },
//     {
//         district: "फतेहपुर",
//         name: "शाह"
//     },
//     {
//         district: "फतेहपुर",
//         name: "लदिगवा"
//     },
//     {
//         district: "फतेहपुर",
//         name: "समियाना"
//     },
//     {
//         district: "फतेहपुर",
//         name: "देवरानार"
//     },
//     {
//         district: "फतेहपुर",
//         name: "लखपुरा"
//     },
//     {
//         district: "फतेहपुर",
//         name: "सेनपुर"
//     },
//     {
//         district: "फतेहपुर",
//         name: "जमरावा"
//     },
//     {
//         district: "फतेहपुर",
//         name: "देवमई"
//     },
//     {
//         district: "फतेहपुर",
//         name: "गलाथा"
//     },
//     {
//         district: "फतेहपुर",
//         name: "करचलपुर"
//     },
//     {
//         district: "फतेहपुर",
//         name: "टिकरा"
//     },
//     {
//         district: "फतेहपुर",
//         name: "डेंडासाही"
//     },
//     {
//         district: "फतेहपुर",
//         name: "हाकिमपुर खन्तवा"
//     },
//     {
//         district: "फतेहपुर",
//         name: "कुसुम्भा"
//     },
//     {
//         district: "फतेहपुर",
//         name: "जाम"
//     },
//     {
//         district: "फतेहपुर",
//         name: "हसवा"
//     },
//     {
//         district: "फतेहपुर",
//         name: "टेक्सारी बुजुर्ग "
//     },
//     {
//         district: "फतेहपुर",
//         name: "कुसुम्भी"
//     },
//     {
//         district: "फतेहपुर",
//         name: "सातोपीत"
//     },
//     {
//         district: "फतेहपुर",
//         name: "गोपालपुर तेल्हा"
//     },
//     {
//         district: "फतेहपुर",
//         name: "छिवलहा"
//     },
//     {
//         district: "फतेहपुर",
//         name: "बैगांव"
//     },
//     {
//         district: "फतेहपुर",
//         name: "सुरजीपुर"
//     },
//     {
//         district: "फतेहपुर",
//         name: "सिजौली"
//     },
//     {
//         district: "फतेहपुर",
//         name: "बागबादशाही"
//     },
//     {
//         district: "फतेहपुर",
//         name: "टिकरी मनौटी"
//     },
//     {
//         district: "फतेहपुर",
//         name: "शहबाजपुर"
//     },
//     {
//         district: "फतेहपुर",
//         name: "कंसपुर गुगौली"
//     },
//     {
//         district: "फतेहपुर",
//         name: "मलवां"
//     },
//     {
//         district: "फतेहपुर",
//         name: "मौहार"
//     },
//     {
//         district: "फतेहपुर",
//         name: " जाफराबाद"
//     },
//     {
//         district: "फतेहपुर",
//         name: "सरायं सहिजादा"
//     },
//     {
//         district: "फतेहपुर",
//         name: "हसनापुर सानी"
//     },
//     {
//         district: "फतेहपुर",
//         name: "मन्झूपुर"
//     },
//     {
//         district: "फतेहपुर",
//         name: "त्रिलोचनपुर"
//     },
//     {
//         district: "फतेहपुर",
//         name: "खासमऊ"
//     },
//     {
//         district: "फतेहपुर",
//         name: "विजयीपुर"
//     },
//     {
//         district: "फतेहपुर",
//         name: "गोदौरा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "आकलपुर जागीर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "बिश्नोली"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "दुरियाई"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "गिरधरपुर सुनारसी"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "बादलपुर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "कचेंड़ा वारसाबाद"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "खंगोड़ा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "सादीपुर छीड़ोली"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "ऊँचामीरपुर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "बिसहड़ा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "रनोली लतीफपुर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "सलारपुर कलॉ"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "इस्लामाबाद कल्दा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "आनन्दपुर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "|छौलस"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "दतावली"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "कैमराला चकसेनपुर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "कलोंदा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "कटहैरा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "खटाना धीरखेड़ा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "नंगला नेनसुख"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "नूरपुर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "फूलपुर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "शहापुर खुर्द"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "रामगढ़ चमरावली"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "समाऊद्दीनपुर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "सैंथली"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "मुठियानी"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "जॉरचा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "कोट"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "लुहारली"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "चिटेहरा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "चीती"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "मण्डपा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "राजपुर कलॉ"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "शहपुर खुर्द"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "लोदाना"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "माहरा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "भवोकरा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "भगवन्तपुर छातंगा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "जेवर खादर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "झुप्पा"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "मेवला गोपालगढ़"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "कानीगढ़ी"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "अलियाबाद उर्फ महन्दीपुर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "जहांगीरपुर देहात"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "चुहणपुर बांगर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "सालेपुर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "जमालपुर"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "बांजरपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "मण्डौला"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "नानू"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "बदरपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "मीरपुर हिन्दू"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "पचायरा"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "अल्लीपुर नोरसपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "सिरौरा सलेमपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "मुर्तजाबाद भूपखेडी"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "महमूदपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "भनैडा खुर्द"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "अफजलपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "शरीफाबाद राजपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "असालतपुर फर्रुखनगर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "चिरौडी"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "शकलपुरा"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "जाफराबाद गनौली"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "बादशाहपुर सिरौली"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "अटोर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "बहादुरपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "भीकनपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "भोवापुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "डासना देहात"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "जलालाबाद"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "मकरेडा"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "मसूरी"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "मथुरापुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "मोरटी"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "मोहिउददीन ढबारसी"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "नंगला फिरोज मोहनपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "शाहपुरनिज मोरटा"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "शमशेर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "नूरपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "कनौजा"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "मतौर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "सुराना"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "नेकपुर साबितनगर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "खौराजपुर विहंग"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "भदौली"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "मिलक चाकरपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "बडका आरिफपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "रेवडी रेवडा"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "युसुफपुर ईशापुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "लतीफपुर तिवडा"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "औरंगाबाद गदाना"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "नंगोला अमीरपुर"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "नाहली"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "अमराला"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "मकरम्मतपुर सिखैडा"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "चुडियाला"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "सारा"
//     },
//     {
//         district: "हाथरस",
//         name: "नगरिया पटटी देवरी"
//     },
//     {
//         district: "हाथरस",
//         name: "दौकेली"
//     },
//     {
//         district: "हाथरस",
//         name: "नगला बरी पट्टी देवरी"
//     },
//     {
//         district: "हाथरस",
//         name: "बरसामई"
//     },
//     {
//         district: "हाथरस",
//         name: "सिचावली कदीम"
//     },
//     {
//         district: "हाथरस",
//         name: "सिहोरी."
//     },
//     {
//         district: "हाथरस",
//         name: "फतेहपुरबछेड़ा"
//     },
//     {
//         district: "हाथरस",
//         name: "बघना"
//     },
//     {
//         district: "हाथरस",
//         name: "कटैलिया"
//     },
//     {
//         district: "हाथरस",
//         name: "अल्हैपुर चुर्सेन"
//     },
//     {
//         district: "हाथरस",
//         name: "महौं"
//     },
//     {
//         district: "हाथरस",
//         name: "वीरनगर"
//     },
//     {
//         district: "हाथरस",
//         name: "लाडपुर"
//     },
//     {
//         district: "हाथरस",
//         name: "परसारा"
//     },
//     {
//         district: "हाथरस",
//         name: "तामसी"
//     },
//     {
//         district: "हाथरस",
//         name: "करकौली"
//     },
//     {
//         district: "हाथरस",
//         name: "नसीरपुर"
//     },
//     {
//         district: "हाथरस",
//         name: "कजरोठी"
//     },
//     {
//         district: "हाथरस",
//         name: "मई"
//     },
//     {
//         district: "हाथरस",
//         name: "अभयपुरा"
//     },
//     {
//         district: "हाथरस",
//         name: "बरोस"
//     },
//     {
//         district: "हाथरस",
//         name: "रामपुर"
//     },
//     {
//         district: "हाथरस",
//         name: "सलेमपुर"
//     },
//     {
//         district: "हाथरस",
//         name: "विर्स"
//     },
//     {
//         district: "हाथरस",
//         name: "बाधनु"
//     },
//     {
//         district: "हाथरस",
//         name: "रूहेरी"
//     },
//     {
//         district: "हाथरस",
//         name: "महमूदपुर बरसे"
//     },
//     {
//         district: "हाथरस",
//         name: "समामई"
//     },
//     {
//         district: "हाथरस",
//         name: "नगला गोपी"
//     },
//     {
//         district: "हाथरस",
//         name: "खेडा बरामई"
//     },
//     {
//         district: "हाथरस",
//         name: "रामगढ़"
//     },
//     {
//         district: "हाथरस",
//         name: "जटोई."
//     },
//     {
//         district: "हाथरस",
//         name: "टुकसान"
//     },
//     {
//         district: "हाथरस",
//         name: "नगलादया"
//     },
//     {
//         district: "हाथरस",
//         name: "रायक"
//     },
//     {
//         district: "हाथरस",
//         name: "खेड़ापरसोली"
//     },
//     {
//         district: "हाथरस",
//         name: "समदपुद"
//     },
//     {
//         district: "हाथरस",
//         name: "सलेमपुर"
//     },
//     {
//         district: "हाथरस",
//         name: "हसनपुर बारू"
//     },
//     {
//         district: "हाथरस",
//         name: "गुतहरा"
//     },
//     {
//         district: "हाथरस",
//         name: "धाधऊ"
//     },
//     {
//         district: "हाथरस",
//         name: "उधैना"
//     },
//     {
//         district: "हाथरस",
//         name: "महरारा"
//     },
//     {
//         district: "हाथरस",
//         name: "अगराना जरारा"
//     },
//     {
//         district: "हाथरस",
//         name: "मीरपुर"
//     },
//     {
//         district: "हाथरस",
//         name: "महमूदपुर"
//     },
//     {
//         district: "हाथरस",
//         name: "सुजावलपुर"
//     },
//     {
//         district: "हाथरस",
//         name: "मऊचिरायल"
//     },
//     {
//         district: "हाथरस",
//         name: "खेडिया खुर्द"
//     },
//     {
//         district: "हाथरस",
//         name: "पंचों"
//     },
//     {
//         district: "हमीरपुर",
//         name: "बरूआ"
//     },
//     {
//         district: "हमीरपुर",
//         name: "बेरी"
//     },
//     {
//         district: "हमीरपुर",
//         name: "देवीगंज"
//     },
//     {
//         district: "हमीरपुर",
//         name: "कण्डौर"
//     },
//     {
//         district: "हमीरपुर",
//         name: "कनौटा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "मनकीखुर्द"
//     },
//     {
//         district: "हमीरपुर",
//         name: "मनकीकला"
//     },
//     {
//         district: "हमीरपुर",
//         name: "बिलौटा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "सिकरोढी"
//     },
//     {
//         district: "हमीरपुर",
//         name: "गिमुहाडांडा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "भौलीडांडा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "भटपुरा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "पारा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "पतारा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "उमराहट"
//     },
//     {
//         district: "हमीरपुर",
//         name: "चन्दूपुर"
//     },
//     {
//         district: "हमीरपुर",
//         name: "जमरेहीऊपर"
//     },
//     {
//         district: "हमीरपुर",
//         name: "जल्ला"
//     },
//     {
//         district: "हमीरपुर",
//         name: "बरूआ"
//     },
//     {
//         district: "हमीरपुर",
//         name: "अमिरता"
//     },
//     {
//         district: "हमीरपुर",
//         name: "बरदहा सहजना"
//     },
//     {
//         district: "हमीरपुर",
//         name: "बडागांव"
//     },
//     {
//         district: "हमीरपुर",
//         name: "कुम्हऊपुर"
//     },
//     {
//         district: "हमीरपुर",
//         name: "मोराकांदर"
//     },
//     {
//         district: "हमीरपुर",
//         name: "टिकरौली"
//     },
//     {
//         district: "हमीरपुर",
//         name: "भौरा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "पाराओझी"
//     },
//     {
//         district: "हमीरपुर",
//         name: "पौथिया"
//     },
//     {
//         district: "हमीरपुर",
//         name: "पत्योरा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "सहुरापुर"
//     },
//     {
//         district: "हमीरपुर",
//         name: "सुरौली बुजुर्ग"
//     },
//     {
//         district: "हमीरपुर",
//         name: "देवगांव"
//     },
//     {
//         district: "हमीरपुर",
//         name: "बहदीना अछपुरा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "भुजपुर"
//     },
//     {
//         district: "हमीरपुर",
//         name: "बजेहटा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "चन्दवारीडांडा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "मगरौठ"
//     },
//     {
//         district: "हमीरपुर",
//         name: "चिकासी"
//     },
//     {
//         district: "हमीरपुर",
//         name: "इस्लामपुर"
//     },
//     {
//         district: "हमीरपुर",
//         name: "इछौरा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "बसरिया"
//     },
//     {
//         district: "हमीरपुर",
//         name: "बन्धौली"
//     },
//     {
//         district: "हमीरपुर",
//         name: "बिरहट"
//     },
//     {
//         district: "हमीरपुर",
//         name: "रिरूआ बुजर्ग"
//     },
//     {
//         district: "हमीरपुर",
//         name: "जिटकरी"
//     },
//     {
//         district: "हमीरपुर",
//         name: "हरदुआ"
//     },
//     {
//         district: "हमीरपुर",
//         name: "भेडीडांडा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "चण्डौत"
//     },
//     {
//         district: "हमीरपुर",
//         name: "जमौडीडांडा"
//     },
//     {
//         district: "हमीरपुर",
//         name: "अतरौली"
//     },
//     {
//         district: "हापुड़",
//         name: "DEHRA"
//     },
//     {
//         district: "हापुड़",
//         name: "HASANPUR LODHA"
//     },
//     {
//         district: "हापुड़",
//         name: "NAGLAUDAIRAMPUR"
//     },
//     {
//         district: "हापुड़",
//         name: "BHAJEDA KALAN"
//     },
//     {
//         district: "हापुड़",
//         name: "KAMARUDDIN NAGAR"
//     },
//     {
//         district: "हापुड़",
//         name: "NAGLA GAJJU"
//     },
//     {
//         district: "हापुड़",
//         name: "NAGLA KASHI"
//     },
//     {
//         district: "हापुड़",
//         name: "PARPA"
//     },
//     {
//         district: "हापुड़",
//         name: "PIPLADA"
//     },
//     {
//         district: "हापुड़",
//         name: "SHEKHPUR KHICHARA"
//     },
//     {
//         district: "हापुड़",
//         name: "KATAURA ZAFARABAD"
//     },
//     {
//         district: "हापुड़",
//         name: "NEKNAMPUR NANAI"
//     },
//     {
//         district: "हापुड़",
//         name: "ALLABUXPUR"
//     },
//     {
//         district: "हापुड़",
//         name: "KATI URF SHANKARA TEELA"
//     },
//     {
//         district: "हापुड़",
//         name: "LATHIRA"
//     },
//     {
//         district: "हापुड़",
//         name: "RAHARWA KIRAWALI"
//     },
//     {
//         district: "हापुड़",
//         name: "INAYATPUR"
//     },
//     {
//         district: "हापुड़",
//         name: "BAHADUR GARH"
//     },
//     {
//         district: "हापुड़",
//         name: "BHADSYANA"
//     },
//     {
//         district: "हापुड़",
//         name: "MOHAMMADPUR RUSTAMPUR"
//     },
//     {
//         district: "हापुड़",
//         name: "POOTH SHANKARATEELA"
//     },
//     {
//         district: "हापुड़",
//         name: "ALAMGIRPUR"
//     },
//     {
//         district: "हापुड़",
//         name: "BABUGARH GRAM"
//     },
//     {
//         district: "हापुड़",
//         name: "BACHHLAUTA"
//     },
//     {
//         district: "हापुड़",
//         name: "BHAMEDA"
//     },
//     {
//         district: "हापुड़",
//         name: "LAKHRADA"
//     },
//     {
//         district: "हापुड़",
//         name: "NOORPUR"
//     },
//     {
//         district: "हापुड़",
//         name: "SHEKHPUR"
//     },
//     {
//         district: "हापुड़",
//         name: "AYADNAGAR (NORTH)"
//     },
//     {
//         district: "हापुड़",
//         name: "AYADNAGAR(SOUTH)"
//     },
//     {
//         district: "हापुड़",
//         name: "BHADANGPUR"
//     },
//     {
//         district: "हापुड़",
//         name: "BHATAIL"
//     },
//     {
//         district: "हापुड़",
//         name: "BHIKANPUR"
//     },
//     {
//         district: "हापुड़",
//         name: "CHHAPKAULI"
//     },
//     {
//         district: "हापुड़",
//         name: "SIKANDARPUR KAKAUDI"
//     },
//     {
//         district: "हापुड़",
//         name: "BANKHANDA"
//     },
//     {
//         district: "हापुड़",
//         name: "FATEHPUR"
//     },
//     {
//         district: "हापुड़",
//         name: "ATUTA"
//     },
//     {
//         district: "हापुड़",
//         name: "HAVASPUR BIGAS"
//     },
//     {
//         district: "हापुड़",
//         name: "HIMMATPUR"
//     },
//     {
//         district: "हापुड़",
//         name: "KANIYA KALYANPUR"
//     },
//     {
//         district: "हापुड़",
//         name: "PEER NAGAR"
//     },
//     {
//         district: "हापुड़",
//         name: "RAJPUR, MAZRA RAJPUR KI MA"
//     },
//     {
//         district: "हापुड़",
//         name: "BUXER"
//     },
//     {
//         district: "हापुड़",
//         name: "GOSHMAHIUDDINPUR CHHAT"
//     },
//     {
//         district: "हापुड़",
//         name: "MUBARAK PUR"
//     },
//     {
//         district: "हापुड़",
//         name: "MUHAMMADPUR AZAMPUR"
//     },
//     {
//         district: "हापुड़",
//         name: "TIGRI"
//     },
//     {
//         district: "हापुड़",
//         name: "AKBARPUR BUKLANA"
//     },
//     {
//         district: "हापुड़",
//         name: "BHAGWANPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "AHIRI"
//     },
//     {
//         district: "हरदोई",
//         name: "AHIRORI"
//     },
//     {
//         district: "हरदोई",
//         name: "AIKGHARA MAGHOLA"
//     },
//     {
//         district: "हरदोई",
//         name: "AEJA"
//     },
//     {
//         district: "हरदोई",
//         name: "ALLIPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "ANANG BEНТА"
//     },
//     {
//         district: "हरदोई",
//         name: "AKABARPUR TALHU"
//     },
//     {
//         district: "हरदोई",
//         name: "AKOHARA NEWADA"
//     },
//     {
//         district: "हरदोई",
//         name: "ALADADPUR NEWADA"
//     },
//     {
//         district: "हरदोई",
//         name: "JAJUPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "KAUDIYA"
//     },
//     {
//         district: "हरदोई",
//         name: "MANJHGAON"
//     },
//     {
//         district: "हरदोई",
//         name: "AMIRTA MARKERA"
//     },
//     {
//         district: "हरदोई",
//         name: "AMTARA"
//     },
//     {
//         district: "हरदोई",
//         name: "ANANGPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "AKBARPUR PASNAMAU"
//     },
//     {
//         district: "हरदोई",
//         name: "ALIGARH"
//     },
//     {
//         district: "हरदोई",
//         name: "ATARCHHA BUZURG"
//     },
//     {
//         district: "हरदोई",
//         name: "ACHUAPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "AGAULAPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "AHEMDI"
//     },
//     {
//         district: "हरदोई",
//         name: "ALIGANJ NAN KHERIYA"
//     },
//     {
//         district: "हरदोई",
//         name: "ARJUNPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "ARWAL"
//     },
//     {
//         district: "हरदोई",
//         name: "BAAN"
//     },
//     {
//         district: "हरदोई",
//         name: "BAGHORA"
//     },
//     {
//         district: "हरदोई",
//         name: "BALAMU"
//     },
//     {
//         district: "हरदोई",
//         name: "ADAMPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "AEMA"
//     },
//     {
//         district: "हरदोई",
//         name: "ANT SANT"
//     },
//     {
//         district: "हरदोई",
//         name: "ATWA ALI MARDANPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "BABATMAU"
//     },
//     {
//         district: "हरदोई",
//         name: "BAGHAURHA"
//     },
//     {
//         district: "हरदोई",
//         name: "AKBARPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "ALAAPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "ABDULLA NAGAR"
//     },
//     {
//         district: "हरदोई",
//         name: "AHEMI"
//     },
//     {
//         district: "हरदोई",
//         name: "ADAMPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "AERA BHADAR"
//     },
//     {
//         district: "हरदोई",
//         name: "AIRKA"
//     },
//     {
//         district: "हरदोई",
//         name: "ALAMPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "ATAMAU"
//     },
//     {
//         district: "हरदोई",
//         name: "ABDULLAPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "AGAMPUR DHURHAI"
//     },
//     {
//         district: "हरदोई",
//         name: "ACHRA MAU"
//     },
//     {
//         district: "हरदोई",
//         name: "AINCHA MAU"
//     },
//     {
//         district: "हरदोई",
//         name: "ADAMPUR"
//     },
//     {
//         district: "हरदोई",
//         name: "ALI NAGER"
//     },
//     {
//         district: "हरदोई",
//         name: "ALAM NAGAR"
//     },
//     {
//         district: "हरदोई",
//         name: "ANDAUA"
//     },
//     {
//         district: "जालौन",
//         name: "AIR"
//     },
//     {
//         district: "जालौन",
//         name: "ATARIYA"
//     },
//     {
//         district: "जालौन",
//         name: "BOHADPURA"
//     },
//     {
//         district: "जालौन",
//         name: "DHAMNI BUZURG"
//     },
//     {
//         district: "जालौन",
//         name: "HARDOI GUJAR"
//     },
//     {
//         district: "जालौन",
//         name: "JAIPURA"
//     },
//     {
//         district: "जालौन",
//         name: "KAPASI"
//     },
//     {
//         district: "जालौन",
//         name: "KARWI BUZURG"
//     },
//     {
//         district: "जालौन",
//         name: "MOHAMMDABAD"
//     },
//     {
//         district: "जालौन",
//         name: "RAGOLI"
//     },
//     {
//         district: "जालौन",
//         name: "AKORHI"
//     },
//     {
//         district: "जालौन",
//         name: "BHADVAN"
//     },
//     {
//         district: "जालौन",
//         name: "RURA MALLOO"
//     },
//     {
//         district: "जालौन",
//         name: "SAHAO"
//     },
//     {
//         district: "जालौन",
//         name: "SIKRA RAJA"
//     },
//     {
//         district: "जालौन",
//         name: "AATA"
//     },
//     {
//         district: "जालौन",
//         name: "AKBARPUR"
//     },
//     {
//         district: "जालौन",
//         name: "CHAMARI"
//     },
//     {
//         district: "जालौन",
//         name: "MASGAV"
//     },
//     {
//         district: "जालौन",
//         name: "PIPRAYA"
//     },
//     {
//         district: "जालौन",
//         name: "AMITA"
//     },
//     {
//         district: "जालौन",
//         name: "CHAMARI"
//     },
//     {
//         district: "जालौन",
//         name: "JAKHAULI"
//     },
//     {
//         district: "जालौन",
//         name: "LONA"
//     },
//     {
//         district: "जालौन",
//         name: "PAHARGAON"
//     },
//     {
//         district: "जालौन",
//         name: "AJITAPUR"
//     },
//     {
//         district: "जालौन",
//         name: "HARSINGPUR"
//     },
//     {
//         district: "जालौन",
//         name: "ITON"
//     },
//     {
//         district: "जालौन",
//         name: "MADARIPUR"
//     },
//     {
//         district: "जालौन",
//         name: "SALEMPUR KALPI"
//     },
//     {
//         district: "जालौन",
//         name: "AHETA"
//     },
//     {
//         district: "जालौन",
//         name: "GOHANI"
//     },
//     {
//         district: "जालौन",
//         name: "MAHARAJPURA"
//     },
//     {
//         district: "जालौन",
//         name: "RUPAPUR"
//     },
//     {
//         district: "जालौन",
//         name: "SIRSADOGARHI"
//     },
//     {
//         district: "जालौन",
//         name: "DEVKALI MUSTAKIL"
//     },
//     {
//         district: "जालौन",
//         name: "HIRAPUR MUSTAKIL"
//     },
//     {
//         district: "जालौन",
//         name: "MAHEWA"
//     },
//     {
//         district: "जालौन",
//         name: "NIAMATPUR"
//     },
//     {
//         district: "जालौन",
//         name: "SARSELA"
//     },
//     {
//         district: "जालौन",
//         name: "BHEND"
//     },
//     {
//         district: "जालौन",
//         name: "GIDHAWASA"
//     },
//     {
//         district: "जालौन",
//         name: "KAMSERA"
//     },
//     {
//         district: "जालौन",
//         name: "KUDARI MADHOGARH"
//     },
//     {
//         district: "जालौन",
//         name: "PARAWAR"
//     },
//     {
//         district: "जालौन",
//         name: "BILAUHA"
//     },
//     {
//         district: "जालौन",
//         name: "JAIGHA"
//     },
//     {
//         district: "जालौन",
//         name: "MAJITH"
//     },
//     {
//         district: "जालौन",
//         name: "PRITHVIPURA"
//     },
//     {
//         district: "जालौन",
//         name: "TIHAR"
//     },
//     {
//         district: "जौनपुर",
//         name: "नेहरूनगर"
//     },
//     {
//         district: "जौनपुर",
//         name: "गयासपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "जलालुद्दीनपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "कबूलपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "हुँसेपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "शिवपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "केशवपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "बीबीपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "सुंगुलपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "सलखापुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "परियावा"
//     },
//     {
//         district: "जौनपुर",
//         name: "गोपीपुर."
//     },
//     {
//         district: "जौनपुर",
//         name: "बेलवा रामसागर"
//     },
//     {
//         district: "जौनपुर",
//         name: "अमदहां:"
//     },
//     {
//         district: "जौनपुर",
//         name: "धनेजा।"
//     },
//     {
//         district: "जौनपुर",
//         name: "बिशुनपुर मझवार"
//     },
//     {
//         district: "जौनपुर",
//         name: "विशुनपुर पुलगुजर"
//     },
//     {
//         district: "जौनपुर",
//         name: "बढौली अहिरान"
//     },
//     {
//         district: "जौनपुर",
//         name: "सब्बेपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "चौरासन्तदास"
//     },
//     {
//         district: "जौनपुर",
//         name: "देऊपर"
//     },
//     {
//         district: "जौनपुर",
//         name: "बेलसड़ी"
//     },
//     {
//         district: "जौनपुर",
//         name: "भरसवां"
//     },
//     {
//         district: "जौनपुर",
//         name: "हरीगांव"
//     },
//     {
//         district: "जौनपुर",
//         name: "भुवाखुर्द"
//     },
//     {
//         district: "जौनपुर",
//         name: "लखेसर"
//     },
//     {
//         district: "जौनपुर",
//         name: "उचौरा"
//     },
//     {
//         district: "जौनपुर",
//         name: "वीरपालपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "कन्धरपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "इन्दरिया"
//     },
//     {
//         district: "जौनपुर",
//         name: "फूलपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "कुवरदा।"
//     },
//     {
//         district: "जौनपुर",
//         name: "रतनूपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "उमरवार"
//     },
//     {
//         district: "जौनपुर",
//         name: "गोबरा"
//     },
//     {
//         district: "जौनपुर",
//         name: "बरैछावीर"
//     },
//     {
//         district: "जौनपुर",
//         name: "बरामनपुर"
//     },
//     {
//         district: "जौनपुर",
//         name: "गुलरा"
//     },
//     {
//         district: "जौनपुर",
//         name: "जगुआ"
//     },
//     {
//         district: "जौनपुर",
//         name: "पारापाटी"
//     },
//     {
//         district: "जौनपुर",
//         name: "देवराई"
//     },
//     {
//         district: "जौनपुर",
//         name: "बलतरी"
//     },
//     {
//         district: "जौनपुर",
//         name: "बरइछ"
//     },
//     {
//         district: "जौनपुर",
//         name: "Ghustaund"
//     },
//     {
//         district: "जौनपुर",
//         name: "Bhaisa"
//     },
//     {
//         district: "जौनपुर",
//         name: "Unhani Kalan"
//     },
//     {
//         district: "जौनपुर",
//         name: "Marthani"
//     },
//     {
//         district: "जौनपुर",
//         name: "Rampurnaddi"
//     },
//     {
//         district: "जौनपुर",
//         name: "Sikandarpur"
//     },
//     {
//         district: "कन्नौज",
//         name: "रोहली"
//     },
//     {
//         district: "कन्नौज",
//         name: "इन्दुइयागंज"
//     },
//     {
//         district: "कन्नौज",
//         name: "गंगागंज गुरौली"
//     },
//     {
//         district: "कन्नौज",
//         name: "तेरारब्बू"
//     },
//     {
//         district: "कन्नौज",
//         name: "सिघनापुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "मौरा"
//     },
//     {
//         district: "कन्नौज",
//         name: "तमियामऊ"
//     },
//     {
//         district: "कन्नौज",
//         name: "अतरौली"
//     },
//     {
//         district: "कन्नौज",
//         name: "बिबियाजलालपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "बिर्रा"
//     },
//     {
//         district: "कन्नौज",
//         name: "मेरापुर गढ़िया"
//     },
//     {
//         district: "कन्नौज",
//         name: "पंथरा"
//     },
//     {
//         district: "कन्नौज",
//         name: "बहादुरपुर मझिगवां"
//     },
//     {
//         district: "कन्नौज",
//         name: "शंकरपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "देवीपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "आरिफपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "खानपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "बझेड़ी"
//     },
//     {
//         district: "कन्नौज",
//         name: "रसूलपुर विकूपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "सिकन्दरपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "वीलमपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "नुनारी"
//     },
//     {
//         district: "कन्नौज",
//         name: "नौरंगपुर नगरिया"
//     },
//     {
//         district: "कन्नौज",
//         name: "पाला"
//     },
//     {
//         district: "कन्नौज",
//         name: "सखौली"
//     },
//     {
//         district: "कन्नौज",
//         name: "सिकरोरी"
//     },
//     {
//         district: "कन्नौज",
//         name: "कनौली"
//     },
//     {
//         district: "कन्नौज",
//         name: "भखरा"
//     },
//     {
//         district: "कन्नौज",
//         name: "सरसई"
//     },
//     {
//         district: "कन्नौज",
//         name: "खैरनगर"
//     },
//     {
//         district: "कन्नौज",
//         name: "राजपुरकरना"
//     },
//     {
//         district: "कन्नौज",
//         name: "चपुन्ना"
//     },
//     {
//         district: "कन्नौज",
//         name: "जरिहापुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "कुवरपुरकाशीदीन"
//     },
//     {
//         district: "कन्नौज",
//         name: "रेरीरामपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "दाईपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "गदनपुर नराहा"
//     },
//     {
//         district: "कन्नौज",
//         name: "चौराचॉदपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "सैयदपुर सकरी"
//     },
//     {
//         district: "कन्नौज",
//         name: "महमूदपुर बीजा"
//     },
//     {
//         district: "कन्नौज",
//         name: "कटरी गंगपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "अकबरपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "मनोरथपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "चियासर"
//     },
//     {
//         district: "कन्नौज",
//         name: "अलीपुर जलेसर"
//     },
//     {
//         district: "कन्नौज",
//         name: "गढ़िया बलीदादपुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "गुगरापुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "जसौरा"
//     },
//     {
//         district: "कन्नौज",
//         name: "कूलापुर"
//     },
//     {
//         district: "कन्नौज",
//         name: "बनपुरा नरायनपुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "अहरौली घाट बांगर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "ट्योगा"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "नगीना बांगर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "नगसिया"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "चपरेहटा"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "चपरघटा"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "देवराहट"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "दौलतपुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "उदईपुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "सलारपुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "हलिया"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "दिबैर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "डिलौलिया बांगर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "किशुनपुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "कथरी"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "करियापुर भोगनीपुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "कृपालपुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "बम्हरौली घाट बांगर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "बरौला"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "मुक्तापुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "गहोलिया"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "कम्भी"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "किशवाखेडा"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "खजुरी"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "खल्ला"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "परौख"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "इन्द्ररूख"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "सिहुठा"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "साहनीपुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "जमौरा"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "पिण्डाथू"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "रायपुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "हवासपुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "गढिया सिकन्दरा"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "पल्हनापुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "नैपलापुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "खोजारामपुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "बैजामऊ बांगर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "सिहुरा"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "जैसलपुर महदेवा"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "गौहानी बांगर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "गौरीरतन बांगर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "जटियापुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "महमूदपुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "भरतौली"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "सरौटा"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "जगदीशपुर"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "मलासा"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "निगोही"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "मावर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "अकबरपुर सेंग"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "आंकिन"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "ददिखा"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "गदनपुर आहार"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "गिलवट अमीनाबाद"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "हसौली काजीगंज"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "महिगवां"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "मोहउद्दीनपुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "नानामऊ"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "राढा"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "राढन"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "संजती बादशाहपुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "बाजिदपुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "बाल्हीपुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "बेहटा"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "दुर्गापुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "क्योना"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "राजारामपुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "सुनौढा"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "तरी पाठकपुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "विल्हन"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "काकूपुर सीताराम"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "मुहपोछा"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "निवादा दरिया"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "हिन्दुपुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "हिंगुपुर बांगर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "हृदयपुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "ईश्वरीगंज"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "कटरी लोधवाखेडा"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "कटरी शंकरपुर सराय"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "ख्योरा कटरी"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "मोहम्मदपुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "प्रतापपुर हरी"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "रमेलनगर बांगर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "ऐमा"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "विपौसी"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "मथुराखेड़ा"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "नागापुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "नारायनपुर सलेमपुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "डोमनपुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "समूही"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "रामपुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "मऊनखत"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "कटरी"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "कोटरा"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "पनका बहादुरनगर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "भौती प्रतापपुर"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "भौती खेड़ा"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "टिकरा"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "मर्दनपुर"
//     },
//     {
//         district: "कासगंज",
//         name: "दतलाना पुख्ता"
//     },
//     {
//         district: "कासगंज",
//         name: "मानपुर नगरिया"
//     },
//     {
//         district: "कासगंज",
//         name: "उढैरपुख्ता"
//     },
//     {
//         district: "कासगंज",
//         name: "मौजमपुर हुसैनपुर"
//     },
//     {
//         district: "कासगंज",
//         name: "बघेला पुख्ता"
//     },
//     {
//         district: "कासगंज",
//         name: "कादरवाडी"
//     },
//     {
//         district: "कासगंज",
//         name: "तारापुर नसीर"
//     },
//     {
//         district: "कासगंज",
//         name: "बनूपुर पुख्ता"
//     },
//     {
//         district: "कासगंज",
//         name: "उकुरी"
//     },
//     {
//         district: "कासगंज",
//         name: "अल्लीपुर बरवारा"
//     },
//     {
//         district: "कासगंज",
//         name: "महमूदपुर पुख्ता"
//     },
//     {
//         district: "कासगंज",
//         name: "अभयपुर रामपुर पुख्ता"
//     },
//     {
//         district: "कासगंज",
//         name: "बरौना"
//     },
//     {
//         district: "कासगंज",
//         name: "गठोरा"
//     },
//     {
//         district: "कासगंज",
//         name: "कादरगंज खाम"
//     },
//     {
//         district: "कासगंज",
//         name: "न्यौली फतुआबाद"
//     },
//     {
//         district: "कासगंज",
//         name: "हिम्मतनगर बझेरा"
//     },
//     {
//         district: "कासगंज",
//         name: "कादरगंज पुख्ता"
//     },
//     {
//         district: "कासगंज",
//         name: "बगवास"
//     },
//     {
//         district: "कासगंज",
//         name: "इन्द्राजशनपुर"
//     },
//     {
//         district: "कासगंज",
//         name: "तरसी"
//     },
//     {
//         district: "कासगंज",
//         name: "उलाईखेडा"
//     },
//     {
//         district: "कासगंज",
//         name: "शहवाजपुर पुख्ता"
//     },
//     {
//         district: "कासगंज",
//         name: "राजपुर कुर्रा"
//     },
//     {
//         district: "कासगंज",
//         name: "सुन्नगढी"
//     },
//     {
//         district: "कासगंज",
//         name: "मोहनीमामूरगंज"
//     },
//     {
//         district: "कासगंज",
//         name: "अहरौली"
//     },
//     {
//         district: "कासगंज",
//         name: "ब्राहीमपुर"
//     },
//     {
//         district: "कासगंज",
//         name: "नरौली"
//     },
//     {
//         district: "कासगंज",
//         name: "मोहम्मदपुर"
//     },
//     {
//         district: "कासगंज",
//         name: "ढोलना"
//     },
//     {
//         district: "कासगंज",
//         name: "ततारपुर माफी"
//     },
//     {
//         district: "कासगंज",
//         name: "मनिकापुर पुख्ता"
//     },
//     {
//         district: "कासगंज",
//         name: "चंदवापुख्ता"
//     },
//     {
//         district: "कासगंज",
//         name: "नगला बदन"
//     },
//     {
//         district: "कासगंज",
//         name: "बस्तरमऊ"
//     },
//     {
//         district: "कासगंज",
//         name: "बोंदर"
//     },
//     {
//         district: "कासगंज",
//         name: "जासमई"
//     },
//     {
//         district: "कासगंज",
//         name: "पावलढेरा"
//     },
//     {
//         district: "कासगंज",
//         name: "ढूंढरा"
//     },
//     {
//         district: "कासगंज",
//         name: "बसई"
//     },
//     {
//         district: "कासगंज",
//         name: "फकौता"
//     },
//     {
//         district: "कासगंज",
//         name: "रसूलवा सुलहपुर"
//     },
//     {
//         district: "कासगंज",
//         name: "भ्रगवासिनी"
//     },
//     {
//         district: "कासगंज",
//         name: "नरई"
//     },
//     {
//         district: "कासगंज",
//         name: "बढौला"
//     },
//     {
//         district: "कासगंज",
//         name: "हथौडावन"
//     },
//     {
//         district: "कासगंज",
//         name: "थाना दरियावगंज"
//     },
//     {
//         district: "कासगंज",
//         name: "नरथर"
//     },
//     {
//         district: "कासगंज",
//         name: "सिकंदरपुर मनौना"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "रैयादेह माफी"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "हरदुआ"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "शेखपुर रसूलपुर"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "पन्सौर"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "गौसपुर"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "सिकन्दरपुर बजहा"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "बरई सलेम"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "सिरियायां कला"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "महगांव"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "श्यामपुर उर्फ मल्हीपुर"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "चित्तापुर"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "पुरखास"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "बसुहार"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "बरियावां"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "शेरगढ़"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "भोपतपुर"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "दुर्गापुर"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "अम्बावां पूरव"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "गुवारा तैयबपुर"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "इब्राहिमपुर"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "तैयबपुर मंगौरा"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "टेनशाहआलमाबाद"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "मवई"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "मुबारकपुर"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "अढ़ौली"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "अलवारा"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "बौली"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "डकशरीरा"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "बारा"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "भकन्दा"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "बेरौचा"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "अर्का फतेहपुर"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "जुगराजपुर"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "रसूलपुर सोनी"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "लौगावां"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "कसिया"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "मोंगरी कड़ा"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "राला"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "कोखराज"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "कुण्ड्रावी"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "गोविन्दपुर गोरियो"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "बिछौरा"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "बरी पुर"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "कैमा"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "गनपा"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "तरसौरा"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "अम्बाई बुजुर्ग"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "अलीपुर जीता"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "अलावलपुर टिकरी"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "कनवार"
//     },
//     {
//         district: "ललितपुर",
//         name: "गैलवाराखुर्द"
//     },
//     {
//         district: "ललितपुर",
//         name: "ऐरा"
//     },
//     {
//         district: "ललितपुर",
//         name: "बछलापुर"
//     },
//     {
//         district: "ललितपुर",
//         name: "डुगरिया"
//     },
//     {
//         district: "ललितपुर",
//         name: "कचनौदाकलां"
//     },
//     {
//         district: "ललितपुर",
//         name: "बेटना"
//     },
//     {
//         district: "ललितपुर",
//         name: "बंगरिया"
//     },
//     {
//         district: "ललितपुर",
//         name: "सलैया"
//     },
//     {
//         district: "ललितपुर",
//         name: "बरौदियाराईन"
//     },
//     {
//         district: "ललितपुर",
//         name: "धुरवारा"
//     },
//     {
//         district: "ललितपुर",
//         name: "अडवाहा"
//     },
//     {
//         district: "ललितपुर",
//         name: "सौरई"
//     },
//     {
//         district: "ललितपुर",
//         name: "करगन"
//     },
//     {
//         district: "ललितपुर",
//         name: "खडोवरा"
//     },
//     {
//         district: "ललितपुर",
//         name: "सतगता"
//     },
//     {
//         district: "ललितपुर",
//         name: "मडवारी"
//     },
//     {
//         district: "ललितपुर",
//         name: "निवाई"
//     },
//     {
//         district: "ललितपुर",
//         name: "महरौनीखुर्द"
//     },
//     {
//         district: "ललितपुर",
//         name: "गुलेन्दा"
//     },
//     {
//         district: "ललितपुर",
//         name: "कन्धारीकलां"
//     },
//     {
//         district: "ललितपुर",
//         name: "कन्धारीखुर्द"
//     },
//     {
//         district: "ललितपुर",
//         name: "उदगुवां"
//     },
//     {
//         district: "ललितपुर",
//         name: "बम्होरीसर"
//     },
//     {
//         district: "ललितपुर",
//         name: "रारा"
//     },
//     {
//         district: "ललितपुर",
//         name: "टेटा"
//     },
//     {
//         district: "ललितपुर",
//         name: "बिगारी"
//     },
//     {
//         district: "ललितपुर",
//         name: "किसरदा"
//     },
//     {
//         district: "ललितपुर",
//         name: "खटोरा"
//     },
//     {
//         district: "ललितपुर",
//         name: "सतवांसा"
//     },
//     {
//         district: "ललितपुर",
//         name: "सिलावन"
//     },
//     {
//         district: "ललितपुर",
//         name: "निवारी"
//     },
//     {
//         district: "ललितपुर",
//         name: "नैनवारा"
//     },
//     {
//         district: "ललितपुर",
//         name: "सैदपुर"
//     },
//     {
//         district: "ललितपुर",
//         name: "कुम्हेंडी"
//     },
//     {
//         district: "ललितपुर",
//         name: "गौना"
//     },
//     {
//         district: "ललितपुर",
//         name: "धवा"
//     },
//     {
//         district: "ललितपुर",
//         name: "मडावरा"
//     },
//     {
//         district: "ललितपुर",
//         name: "मदनपुर"
//     },
//     {
//         district: "ललितपुर",
//         name: "नीमखेरा"
//     },
//     {
//         district: "ललितपुर",
//         name: "पटनामड़ावरा"
//     },
//     {
//         district: "ललितपुर",
//         name: "बमराना"
//     },
//     {
//         district: "ललितपुर",
//         name: "बहादुरपुर"
//     },
//     {
//         district: "ललितपुर",
//         name: "बानौनी"
//     },
//     {
//         district: "ललितपुर",
//         name: "सुनवाहा"
//     },
//     {
//         district: "ललितपुर",
//         name: "चंदावली"
//     },
//     {
//         district: "ललितपुर",
//         name: "ऊमरी"
//     },
//     {
//         district: "ललितपुर",
//         name: "गैदौरा"
//     },
//     {
//         district: "ललितपुर",
//         name: "मरौली"
//     },
//     {
//         district: "ललितपुर",
//         name: "भैलोनीलोध"
//     },
//     {
//         district: "ललितपुर",
//         name: "टीला"
//     },
//     {
//         district: "महोबा",
//         name: "सिजरिया"
//     },
//     {
//         district: "महोबा",
//         name: "बन्नी"
//     },
//     {
//         district: "महोबा",
//         name: "पुरा"
//     },
//     {
//         district: "महोबा",
//         name: "Mavai Khurd"
//     },
//     {
//         district: "महोबा",
//         name: "सिरसीकलां"
//     },
//     {
//         district: "महोबा",
//         name: "चिचारा"
//     },
//     {
//         district: "महोबा",
//         name: "खम्हरिया"
//     },
//     {
//         district: "महोबा",
//         name: "लिलवाही"
//     },
//     {
//         district: "महोबा",
//         name: "खना"
//     },
//     {
//         district: "महोबा",
//         name: "धरान"
//     },
//     {
//         district: "महोबा",
//         name: "परराहा"
//     },
//     {
//         district: "महोबा",
//         name: "गहरा"
//     },
//     {
//         district: "महोबा",
//         name: "सुरहा"
//     },
//     {
//         district: "महोबा",
//         name: "सिचारा"
//     },
//     {
//         district: "महोबा",
//         name: "छानीकला"
//     },
//     {
//         district: "महोबा",
//         name: "जैतपुर"
//     },
//     {
//         district: "महोबा",
//         name: "जैलवारा"
//     },
//     {
//         district: "महोबा",
//         name: "बछेछर खुर्द"
//     },
//     {
//         district: "महोबा",
//         name: "बडखेरा"
//     },
//     {
//         district: "महोबा",
//         name: "पुरवा जैतपुर"
//     },
//     {
//         district: "महोबा",
//         name: "स्यावन"
//     },
//     {
//         district: "महोबा",
//         name: "रजपुरा"
//     },
//     {
//         district: "महोबा",
//         name: "मगरिया"
//     },
//     {
//         district: "महोबा",
//         name: "रजौनी"
//     },
//     {
//         district: "महोबा",
//         name: "विहार"
//     },
//     {
//         district: "महोबा",
//         name: "नकरा"
//     },
//     {
//         district: "महोबा",
//         name: "दादरी"
//     },
//     {
//         district: "महोबा",
//         name: "महुआइटौरा"
//     },
//     {
//         district: "महोबा",
//         name: "तैझ्या"
//     },
//     {
//         district: "महोबा",
//         name: "खैरोकला"
//     },
//     {
//         district: "महोबा",
//         name: "उमरई"
//     },
//     {
//         district: "महोबा",
//         name: "मडवारी"
//     },
//     {
//         district: "महोबा",
//         name: "लौलारा"
//     },
//     {
//         district: "महोबा",
//         name: "नैपुरा"
//     },
//     {
//         district: "महोबा",
//         name: "भुजपुरा"
//     },
//     {
//         district: "महोबा",
//         name: "बल्लॉय"
//     },
//     {
//         district: "महोबा",
//         name: "कुड़ार"
//     },
//     {
//         district: "महोबा",
//         name: "चन्दौली"
//     },
//     {
//         district: "महोबा",
//         name: "अनघौरा"
//     },
//     {
//         district: "महोबा",
//         name: "कमलखेड़ा"
//     },
//     {
//         district: "महोबा",
//         name: "बम्हौरीकलॉ"
//     },
//     {
//         district: "महोबा",
//         name: "बरदा"
//     },
//     {
//         district: "महोबा",
//         name: "लुहारी"
//     },
//     {
//         district: "महोबा",
//         name: "अकठौहा"
//     },
//     {
//         district: "महोबा",
//         name: "गोरखा"
//     },
//     {
//         district: "महोबा",
//         name: "बम्हौरीबेलदारन"
//     },
//     {
//         district: "महोबा",
//         name: "सूपा"
//     },
//     {
//         district: "महोबा",
//         name: "गुढ़ा"
//     },
//     {
//         district: "महोबा",
//         name: "गौरहरी"
//     },
//     {
//         district: "महोबा",
//         name: "करहराखुर्द"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "ekaljiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "uokVsM+k"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "lquwiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "Hkwjwiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "<diqjk"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "HkSaljksyh"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "tljktiqj"
//     },
//     {
//         district: "eSuiqjh",
//         name: "ftygh"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "pUnuiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "ulhjiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "en~nkiqj[kkl"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "jkeuxfj;k"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "uxyk Ålj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "iuokg"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "cj[ksM+k"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "gkfQtiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "xaxkiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "ygjk"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "yywiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "iqlSuk"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "bVkSjk"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "cykjiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "fnouiqjpkS/kjh"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "gUuw[ksM+k"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "HkkWaor"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "v?kkj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "C;kSarhdykW"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "}kfjdkiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "ohjiqjdykWa"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "iMqjk"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "r[kjÅ"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "j:vk"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "unjSyk"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "vYyhiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "vyhiqjds'kksaiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "xaxnkliqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "igkM+iqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "jaxiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "fgjkSyh"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "cq<jkZ"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "xf<;kdqjkoyh"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "ipkoj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "fodzeiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "uxyk lysgh"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "njokg"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "bZdjh"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "cMs+jh"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "f'koflagiqj uoknk"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "yks/khiqjcgknqjiqj"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "cqjkZ"
//     },
//     {
//         district: "मथुरा",
//         name: "सकराया"
//     },
//     {
//         district: "मथुरा",
//         name: "आटस"
//     },
//     {
//         district: "मथुरा",
//         name: "सुनरख"
//     },
//     {
//         district: "मथुरा",
//         name: "घौरेरा"
//     },
//     {
//         district: "मथुरा",
//         name: "वृन्दावन"
//     },
//     {
//         district: "मथुरा",
//         name: "जयसिंहपुरा"
//     },
//     {
//         district: "मथुरा",
//         name: "मथुरा"
//     },
//     {
//         district: "मथुरा",
//         name: "औरंगाबाद"
//     },
//     {
//         district: "मथुरा",
//         name: "राँची"
//     },
//     {
//         district: "मथुरा",
//         name: "गढाया लतीफपुर"
//     },
//     {
//         district: "मथुरा",
//         name: "छिनपारई"
//     },
//     {
//         district: "मथुरा",
//         name: "देदना बांगर"
//     },
//     {
//         district: "मथुरा",
//         name: "बरौठ"
//     },
//     {
//         district: "मथुरा",
//         name: "बाघर्रा"
//     },
//     {
//         district: "मथुरा",
//         name: "ओहावा"
//     },
//     {
//         district: "मथुरा",
//         name: "डांगौली"
//     },
//     {
//         district: "मथुरा",
//         name: "बेगमपुर"
//     },
//     {
//         district: "मथुरा",
//         name: "जहांगीरपुर"
//     },
//     {
//         district: "मथुरा",
//         name: "पानीगांव"
//     },
//     {
//         district: "मथुरा",
//         name: "मांट राजा"
//     },
//     {
//         district: "मथुरा",
//         name: "धानौता"
//     },
//     {
//         district: "मथुरा",
//         name: "शाहपुर"
//     },
//     {
//         district: "मथुरा",
//         name: "शेरनगर"
//     },
//     {
//         district: "मथुरा",
//         name: "मझोई"
//     },
//     {
//         district: "मथुरा",
//         name: "शेरगढ"
//     },
//     {
//         district: "मथुरा",
//         name: "धीमरी"
//     },
//     {
//         district: "मथुरा",
//         name: "रामपुर"
//     },
//     {
//         district: "मथुरा",
//         name: "भौगांव"
//     },
//     {
//         district: "मथुरा",
//         name: "दलौता"
//     },
//     {
//         district: "मथुरा",
//         name: "पीरपुर"
//     },
//     {
//         district: "मथुरा",
//         name: "महमदपुर"
//     },
//     {
//         district: "मथुरा",
//         name: "मगोर्रा"
//     },
//     {
//         district: "मथुरा",
//         name: "फोंडर"
//     },
//     {
//         district: "मथुरा",
//         name: "रसूलपुर"
//     },
//     {
//         district: "मथुरा",
//         name: "मल्हू"
//     },
//     {
//         district: "मथुरा",
//         name: "पाडल"
//     },
//     {
//         district: "मथुरा",
//         name: "हाथिया"
//     },
//     {
//         district: "मथुरा",
//         name: "पलसों"
//     },
//     {
//         district: "मथुरा",
//         name: "मुड़सेरस"
//     },
//     {
//         district: "मथुरा",
//         name: "नैनूपट्टी"
//     },
//     {
//         district: "मथुरा",
//         name: "हंशगज (नगर - निगम)"
//     },
//     {
//         district: "मथुरा",
//         name: "विशनगंज (नगर-निगम)"
//     },
//     {
//         district: "मथुरा",
//         name: "ईशापुर (नगर-निगम)"
//     },
//     {
//         district: "मथुरा",
//         name: "न०कोल्हू (नगर-निगम)"
//     },
//     {
//         district: "मथुरा",
//         name: "रमण रेती आश्रम-महावन"
//     },
//     {
//         district: "मथुरा",
//         name: "लहरौली घाट"
//     },
//     {
//         district: "मथुरा",
//         name: "न० अकोस"
//     },
//     {
//         district: "मथुरा",
//         name: "नेरा"
//     },
//     {
//         district: "मथुरा",
//         name: "मौहपई- नेरा"
//     },
//     {
//         district: "मथुरा",
//         name: "सेहत"
//     },
//     {
//         district: "मऊ",
//         name: "चक्की मूसाडोहि"
//     },
//     {
//         district: "मऊ",
//         name: "गजियापुर"
//     },
//     {
//         district: "मऊ",
//         name: "परसिया जयरामगिरि"
//     },
//     {
//         district: "मऊ",
//         name: "तिघरा"
//     },
//     {
//         district: "मऊ",
//         name: "अहिरूपुर"
//     },
//     {
//         district: "मऊ",
//         name: "भेड़कुल सुल्तानपुर"
//     },
//     {
//         district: "मऊ",
//         name: "कुवरपरुवाँ"
//     },
//     {
//         district: "मऊ",
//         name: "मोलनापुर"
//     },
//     {
//         district: "मऊ",
//         name: "दुबारी"
//     },
//     {
//         district: "मऊ",
//         name: "धर्मपुर विशुनपुर"
//     },
//     {
//         district: "मऊ",
//         name: "लोकया"
//     },
//     {
//         district: "मऊ",
//         name: "मठिया"
//     },
//     {
//         district: "मऊ",
//         name: "सुवाह"
//     },
//     {
//         district: "मऊ",
//         name: "हरधौली"
//     },
//     {
//         district: "मऊ",
//         name: "चक भगवानदास"
//     },
//     {
//         district: "मऊ",
//         name: "नवली"
//     },
//     {
//         district: "मऊ",
//         name: "चिऊटीडाड"
//     },
//     {
//         district: "मऊ",
//         name: "लामी"
//     },
//     {
//         district: "मऊ",
//         name: "तारनपुर"
//     },
//     {
//         district: "मऊ",
//         name: "भैसा खरग (नई बजार)"
//     },
//     {
//         district: "मऊ",
//         name: "बीबीपुर"
//     },
//     {
//         district: "मऊ",
//         name: "गौरीडीह"
//     },
//     {
//         district: "मऊ",
//         name: "नुरूल्लाहपुर"
//     },
//     {
//         district: "मऊ",
//         name: "सुरजपुर"
//     },
//     {
//         district: "मऊ",
//         name: "रसूलपुर इनामुद्दीनपुर"
//     },
//     {
//         district: "मऊ",
//         name: "बहरामपुर"
//     },
//     {
//         district: "मऊ",
//         name: "सिसवा"
//     },
//     {
//         district: "मऊ",
//         name: "भैरोपुर"
//     },
//     {
//         district: "मऊ",
//         name: "पतनई बुजुर्ग"
//     },
//     {
//         district: "मऊ",
//         name: "बखरिया"
//     },
//     {
//         district: "मऊ",
//         name: "बहादुरपुर"
//     },
//     {
//         district: "मऊ",
//         name: "धनौली रामपुर"
//     },
//     {
//         district: "मऊ",
//         name: "कटघरा महलू"
//     },
//     {
//         district: "मऊ",
//         name: "भैसा खरग (जमीन सरहरा)"
//     },
//     {
//         district: "मऊ",
//         name: "किशुनपुर उर्फ सुग्गीचौरी"
//     },
//     {
//         district: "मऊ",
//         name: "टौस नदी"
//     },
//     {
//         district: "मऊ",
//         name: "टौंस नदी"
//     },
//     {
//         district: "मऊ",
//         name: "टौंस नदी"
//     },
//     {
//         district: "मऊ",
//         name: "टौंस नदी"
//     },
//     {
//         district: "मऊ",
//         name: "गाढ़ा ताल"
//     },
//     {
//         district: "मऊ",
//         name: "गाढ़ा ताल"
//     },
//     {
//         district: "मऊ",
//         name: "गाढ़ा ताल"
//     },
//     {
//         district: "मऊ",
//         name: "गालीबपुर"
//     },
//     {
//         district: "मऊ",
//         name: "नरौनी"
//     },
//     {
//         district: "मऊ",
//         name: "अतरारी"
//     },
//     {
//         district: "मऊ",
//         name: "मोहिउद्दीनपुर"
//     },
//     {
//         district: "मऊ",
//         name: "भातकोल"
//     },
//     {
//         district: "मऊ",
//         name: "अमरहट"
//     },
//     {
//         district: "मऊ",
//         name: "जमालपुर"
//     },
//     {
//         district: "मेरठ",
//         name: "मानपुर"
//     },
//     {
//         district: "मेरठ",
//         name: "रठौरा कलां"
//     },
//     {
//         district: "मेरठ",
//         name: "सिरजेपुर"
//     },
//     {
//         district: "मेरठ",
//         name: "दूधली खादर"
//     },
//     {
//         district: "मेरठ",
//         name: "बस्तौरा नारंग"
//     },
//     {
//         district: "मेरठ",
//         name: "मखदूमपुर"
//     },
//     {
//         district: "मेरठ",
//         name: "किशोरपुर"
//     },
//     {
//         district: "मेरठ",
//         name: "जलालपुर जोहरा"
//     },
//     {
//         district: "मेरठ",
//         name: "कुन्हैडा"
//     },
//     {
//         district: "मेरठ",
//         name: "तारापुर"
//     },
//     {
//         district: "मेरठ",
//         name: "रठौरा खुर्द"
//     },
//     {
//         district: "मेरठ",
//         name: "डाडाका"
//     },
//     {
//         district: "मेरठ",
//         name: "लतीफपुर"
//     },
//     {
//         district: "मेरठ",
//         name: "सैफपुर फिरोजपुर"
//     },
//     {
//         district: "मेरठ",
//         name: "महमूदपुर सिखैडा"
//     },
//     {
//         district: "मेरठ",
//         name: "खोडराय"
//     },
//     {
//         district: "मेरठ",
//         name: "अलीपुर मोरना"
//     },
//     {
//         district: "मेरठ",
//         name: "सैफपुर कर्मचन्दपुर"
//     },
//     {
//         district: "मेरठ",
//         name: "निडावली"
//     },
//     {
//         district: "मेरठ",
//         name: "दरियापुर"
//     },
//     {
//         district: "मेरठ",
//         name: "नंगला गौरााई"
//     },
//     {
//         district: "मेरठ",
//         name: "आसिफाबाद"
//     },
//     {
//         district: "मेरठ",
//         name: "समरापुर"
//     },
//     {
//         district: "मेरठ",
//         name: "नीमका"
//     },
//     {
//         district: "मेरठ",
//         name: "सिकन्दरपुर"
//     },
//     {
//         district: "मेरठ",
//         name: "तरबियतपुर जनूवी"
//     },
//     {
//         district: "मेरठ",
//         name: "अगवानपुर"
//     },
//     {
//         district: "मेरठ",
//         name: "सालौर रसूलपुर पनाह"
//     },
//     {
//         district: "मेरठ",
//         name: "असीतपुर"
//     },
//     {
//         district: "मेरठ",
//         name: "गेसूपुर उत्त्तरी"
//     },
//     {
//         district: "मेरठ",
//         name: "तिगरी"
//     },
//     {
//         district: "मेरठ",
//         name: "गुबारिकपुर"
//     },
//     {
//         district: "मेरठ",
//         name: "खेडी मनिहार"
//     },
//     {
//         district: "मेरठ",
//         name: ""
//     },
//     {
//         district: "मेरठ",
//         name: "सांधन"
//     },
//     {
//         district: "मेरठ",
//         name: "अटौरा"
//     },
//     {
//         district: "मेरठ",
//         name: "खेडकी जदीद"
//     },
//     {
//         district: "मेरठ",
//         name: "मवानाखुर्द"
//     },
//     {
//         district: "मेरठ",
//         name: "राफन"
//     },
//     {
//         district: "मेरठ",
//         name: "फिटकरी"
//     },
//     {
//         district: "मेरठ",
//         name: "शोल्दा"
//     },
//     {
//         district: "मेरठ",
//         name: "भडौली"
//     },
//     {
//         district: "मेरठ",
//         name: "महलवाला"
//     },
//     {
//         district: "मेरठ",
//         name: "कैलीरामपुर"
//     },
//     {
//         district: "मेरठ",
//         name: "रार्धना"
//     },
//     {
//         district: "मेरठ",
//         name: "किठौर देहात"
//     },
//     {
//         district: "मेरठ",
//         name: "नंगली किठौर"
//     },
//     {
//         district: "मेरठ",
//         name: "बहरोडा"
//     },
//     {
//         district: "मेरठ",
//         name: "कायस्थ बडढा"
//     },
//     {
//         district: "मेरठ",
//         name: "पसवाडा"
//     },
//     {
//         district: "मीरजापुर",
//         name: "RAIPUR"
//     },
//     {
//         district: "मीरजापुर",
//         name: "KALNA GAHARWAR"
//     },
//     {
//         district: "मीरजापुर",
//         name: "SUMATIA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "VIJAYPUR"
//     },
//     {
//         district: "मीरजापुर",
//         name: "BASUHARA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "BHATWARI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "GALARA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "MAHUGADHI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "HARDI SAHIJANI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "MIRZAPUR KHURD"
//     },
//     {
//         district: "मीरजापुर",
//         name: "JAYPATTI KALA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "SURAHA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "JAGAPATTI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "MAHANGIPUR"
//     },
//     {
//         district: "मीरजापुर",
//         name: "MANAJA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "TILTHI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "ATRAILA RAJA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "BAMI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "DUBAR KALA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "MAHADEV"
//     },
//     {
//         district: "मीरजापुर",
//         name: "GADAULI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "KANAK SARAI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "MATIYARI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "RAMAPUR"
//     },
//     {
//         district: "मीरजापुर",
//         name: "BERJI MUKUNDPUR"
//     },
//     {
//         district: "मीरजापुर",
//         name: "BHEWAR KARMANPUR"
//     },
//     {
//         district: "मीरजापुर",
//         name: "GURUSANDI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "MAHEWA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "PADRA HANUMAN"
//     },
//     {
//         district: "मीरजापुर",
//         name: "SHAHPUR CHAUSA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "BAGHI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "JALALPUR MAFI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "SONAI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "TEDHUWA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "AKSAULI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "BHARPURA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "CHENDULI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "SINDHORA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "AMOI PURWA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "BASAHI"
//     },
//     {
//         district: "मीरजापुर",
//         name: "KAKRAD"
//     },
//     {
//         district: "मीरजापुर",
//         name: "MALUA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "DHANSIRIYA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "GOLHANPUR"
//     },
//     {
//         district: "मीरजापुर",
//         name: "KHORADIH"
//     },
//     {
//         district: "मीरजापुर",
//         name: "SIRIYA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "AA. LA. SULTANPUR"
//     },
//     {
//         district: "मीरजापुर",
//         name: "BAGHA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "KHAIRA"
//     },
//     {
//         district: "मीरजापुर",
//         name: "RUDAULI"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "धौलरी"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "बुडीना कलां"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "तितावी"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "लखान"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "कुटबा"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "कुटबी"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "शिकारपुर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "बहरामगढ"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "खानपुर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "बवाना"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "गढी नौआबाद"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "बाननगर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "दीदाहेडी"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "रूकनपुर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "नंगला राई"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "रोहाना खुर्द"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "स्याली"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "रहडया"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "जलालपुर नीला"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "हुसैनपुर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "रामराज"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "जीवनपुरी"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "हंसावाला"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "अम्बरपुर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "चन्दसीना"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "समौली"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "रतनपुरी"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "शुकताल"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "शुकतारी"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "फिरोजपुर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "भुवापुर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "इलाहाबास"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "रामपुर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "बढींवाला"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "बामनहेडी"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "मिमलाना"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "शेरपुर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "रजकल्लापुर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "भदौला मारकपुर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "बढीवाला"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "चमरावाला."
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "शेरपुर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "मोरकुक्का"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "आदमपुर"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "सावटू"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "दुल्हेरा"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "पलडी"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "ढिढांवली"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "vljkos [kqnZ"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "\"tykyiqj ?kks\"\"kh\""
//     },
//     {
//         district: "प्रयागराज ",
//         name: "vljkos dyk"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "eukSjh"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "djsagnk"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "vdcjiqj fetkZiqj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "eUnjnsg ekQh"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "vgeniqj ikou"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "pQjh mijgkj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "eqckjdiqj mijgkj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "vdcjiqj xaxkxat"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "eqckjdiqj iwju dNkj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "nknuiwj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "lqYrkuiqj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "jSiqjk"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "vjbZ"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "c?ksM+k"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "eNgj mQzZ iqjok"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "lqyebZ"
//     },
//     {
//         district: "प्रयागराज ",
//         name: ">hjh yPNhiqj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "cM+ksa[kjk "
//     },
//     {
//         district: "प्रयागराज ",
//         name: "lasejh ck?kjk;"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "dkaslQjkdyk"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "cSnokjdyk"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "egqyh"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "jR;ksjk djfi;k"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "nqenqek "
//     },
//     {
//         district: "प्रयागराज ",
//         name: "gfjgjiqj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "mlekiqj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "c<kSyh"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "dlkS/ku mQ`~ y{kkx`g"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "dUnyk eoS;k"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "gjHkkuiqj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "pdvijk/k"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "eSygu"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "chjHkkuiqj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "ljk; 'ks[kiqj mQZ lyekiqj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "dqrqcn~nhuiqj mQZ vfg;kiqj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "idjh lsokj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "ijuhiqj "
//     },
//     {
//         district: "प्रयागराज ",
//         name: "tsofu;k "
//     },
//     {
//         district: "प्रयागराज ",
//         name: "vksukSj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "legu"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "ca/kok"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "fpYyk xkSgkuh"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "lqtkSuk"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "datklk mijgkj"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "fVdjh dyk "
//     },
//     {
//         district: "प्रयागराज ",
//         name: "lsagqM+k"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "dkaVh"
//     },
//     {
//         district: "पीलीभीत",
//         name: "मगरासा"
//     },
//     {
//         district: "पीलीभीत",
//         name: "कुकरीखेडा"
//     },
//     {
//         district: "पीलीभीत",
//         name: "संडिया मुस्तकिल"
//     },
//     {
//         district: "पीलीभीत",
//         name: "रायपुर मुस्तकिल"
//     },
//     {
//         district: "पीलीभीत",
//         name: "बेहरी"
//     },
//     {
//         district: "पीलीभीत",
//         name: "नांवकुंड"
//     },
//     {
//         district: "पीलीभीत",
//         name: "चन्दोई"
//     },
//     {
//         district: "पीलीभीत",
//         name: "सिमरिया ताराचन्द"
//     },
//     {
//         district: "पीलीभीत",
//         name: "प्यास"
//     },
//     {
//         district: "पीलीभीत",
//         name: "चिनौरा मुस्तकिल"
//     },
//     {
//         district: "पीलीभीत",
//         name: "मुडिया कुण्डरी"
//     },
//     {
//         district: "पीलीभीत",
//         name: "शेखापुर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "खानपुर. उर्फ. वीरमपुर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "भैसटा जलालपुर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "कबूलपुर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "अर्जुनपुर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "किशनी"
//     },
//     {
//         district: "पीलीभीत",
//         name: "चौसर हरदौपट्टी"
//     },
//     {
//         district: "पीलीभीत",
//         name: "टिहुलिया"
//     },
//     {
//         district: "पीलीभीत",
//         name: "गजीपुर कुण्डा"
//     },
//     {
//         district: "पीलीभीत",
//         name: "गभिया सहराई"
//     },
//     {
//         district: "पीलीभीत",
//         name: "नौजलहा नकटहा"
//     },
//     {
//         district: "पीलीभीत",
//         name: "महाराजपुर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "नगरिया खुर्द कलां"
//     },
//     {
//         district: "पीलीभीत",
//         name: "रमनगरा"
//     },
//     {
//         district: "पीलीभीत",
//         name: "पुरैना ता० महाराजपुर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "सेल्हा"
//     },
//     {
//         district: "पीलीभीत",
//         name: "मटैया लालपुर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "मझारा. ता० महाराजपुर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "धुरिया पलिया"
//     },
//     {
//         district: "पीलीभीत",
//         name: "उण्डरा"
//     },
//     {
//         district: "पीलीभीत",
//         name: "भूडा"
//     },
//     {
//         district: "पीलीभीत",
//         name: "नगरिया मुस्तकिल"
//     },
//     {
//         district: "पीलीभीत",
//         name: "सुस्वार"
//     },
//     {
//         district: "पीलीभीत",
//         name: "अंडरायन मुस्तकिल"
//     },
//     {
//         district: "पीलीभीत",
//         name: "निवाढ ऐठपुर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "भगा मोहम्मद गंज"
//     },
//     {
//         district: "पीलीभीत",
//         name: "कुरी"
//     },
//     {
//         district: "पीलीभीत",
//         name: "टोडरपुर सहराई"
//     },
//     {
//         district: "पीलीभीत",
//         name: "तुर्कपुरा उर्फ बढवार"
//     },
//     {
//         district: "पीलीभीत",
//         name: "चंदिया हजारा"
//     },
//     {
//         district: "पीलीभीत",
//         name: "नहरोसा"
//     },
//     {
//         district: "पीलीभीत",
//         name: "राणा प्रताप नगर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "अशोक नगर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "कबीरगंज"
//     },
//     {
//         district: "पीलीभीत",
//         name: "मौरनियां गांधीनगर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "शास्त्रीनगर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "सिद्धनगर"
//     },
//     {
//         district: "पीलीभीत",
//         name: "बैल्हा"
//     },
//     {
//         district: "पीलीभीत",
//         name: "श्रीनगर"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "ATRAURA MEERPUR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "MAHDAURA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "RATTIPUR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "CHAUKHARA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "GOI"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "KHABHAUR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "KHUGHI KALA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "YAHIYAPUR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "BAKOL"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "GOGAHAR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "KHANWARI"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "AIMA JATUPUR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "AUTARPUR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "KODRAJEET"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "ATAULIYA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "RAMPUR GARAULI"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "JAHANABAD"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "MAUDARA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "DEOLI"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "KATEIYA NEWADA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "TILAURI"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "PAHARPUR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "SAMAPUR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "CHANDOKA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "PARASRAMPUR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "SARAULEE"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "BAJHA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "NOORPUR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "PURELA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "GADAI CHAKDEYA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "KATAKA BALI"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "MAJHILAHA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "PURE RAIJOO"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "ASOG"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "PURE DHANA TIKARIYA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "SANGRAMGARH"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "JALALPUR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "KODRA MADUPUR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "KOL BAZARDEEHA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "KOTHANWARIYA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "MAVAIYA KALA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "BADSHAHPUR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "BHAWANIGARH"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "CHAHIN"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "DARRA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "MUSTAFABAD"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "PINJARI"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "RAHATIKAR"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "SARUA"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "SILAUDHI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "ROOPA MAU"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "ICHAULI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "RAMPUR SUDAULI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "NEEMTEEKAR"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "THULENDI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "RAJA MAU"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "KUNWAR MAU"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "BARARA BUJURG"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "TERUKHA"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "AIHAR"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "GAURAHARDO"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "JALALPUR DHAI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "BIRNAWAN"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "MAU"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "DEEH"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "POTHAI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "KATHWARA"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "JOHWA SHARKI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "JAGATPUR"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "KHIRON"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "SAMRI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "PAHO"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "BHITAR GAON"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "AMBARA PASHCHIM"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "SEMER PAHA"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "BEHTA KALAN"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "ALAMPUR"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "MAU"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "ATREHTA"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "RAHI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "BELA GUSISI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "BELA BHELA"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "LODHWARI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "ITAURA BUZURG"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "RASOOLPUR"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "DHARAI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "BEWALI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "KITHANWA"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "SUCHI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "MANEHRO"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "ATAURA BUZURG"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "HAJIPUR"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "GOJHARI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "KORIHAR"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "ONAI PAHARPUR"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "KAILAULI"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "BARDAR"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "GURHA"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "PATTI RAHAS KAITHWALMUSTAKIL"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "KANDRAWAN"
//     },
//     {
//         district: "रामपुर",
//         name: "CHANDAYAN"
//     },
//     {
//         district: "रामपुर",
//         name: "CHOUKONI"
//     },
//     {
//         district: "रामपुर",
//         name: "SANKARA"
//     },
//     {
//         district: "रामपुर",
//         name: "KACHNAL"
//     },
//     {
//         district: "रामपुर",
//         name: "BAL KHERA"
//     },
//     {
//         district: "रामपुर",
//         name: "JORASI"
//     },
//     {
//         district: "रामपुर",
//         name: "CHANDELA"
//     },
//     {
//         district: "रामपुर",
//         name: "MUNDIA KALAN"
//     },
//     {
//         district: "रामपुर",
//         name: "TALABPUR"
//     },
//     {
//         district: "रामपुर",
//         name: "CHAMARPURA"
//     },
//     {
//         district: "रामपुर",
//         name: "CHIKNA"
//     },
//     {
//         district: "रामपुर",
//         name: "AFZALPUR PATTI"
//     },
//     {
//         district: "रामपुर",
//         name: "INDRI"
//     },
//     {
//         district: "रामपुर",
//         name: "MANDAIYAN JOLPUR"
//     },
//     {
//         district: "रामपुर",
//         name: "FATTEHPUR"
//     },
//     {
//         district: "रामपुर",
//         name: "AAGAPUR"
//     },
//     {
//         district: "रामपुर",
//         name: "GANGAPUR KADIM"
//     },
//     {
//         district: "रामपुर",
//         name: "RAJPURA"
//     },
//     {
//         district: "रामपुर",
//         name: "KARIMGANJ"
//     },
//     {
//         district: "रामपुर",
//         name: "RAS DANDIA"
//     },
//     {
//         district: "रामपुर",
//         name: "PASHU PURA"
//     },
//     {
//         district: "रामपुर",
//         name: "PATIA"
//     },
//     {
//         district: "रामपुर",
//         name: "KHANPUR JADID"
//     },
//     {
//         district: "रामपुर",
//         name: "KRIPYA HAPPU"
//     },
//     {
//         district: "रामपुर",
//         name: "KUMHARIYA"
//     },
//     {
//         district: "रामपुर",
//         name: "PARCHAI"
//     },
//     {
//         district: "रामपुर",
//         name: "BENAZEER URF GHATAMPUR"
//     },
//     {
//         district: "रामपुर",
//         name: "MEHNDI NAGAR"
//     },
//     {
//         district: "रामपुर",
//         name: "SHUMALI"
//     },
//     {
//         district: "रामपुर",
//         name: "BAGARKHA"
//     },
//     {
//         district: "रामपुर",
//         name: "KARANPUR"
//     },
//     {
//         district: "रामपुर",
//         name: "BHATPURA"
//     },
//     {
//         district: "रामपुर",
//         name: "RAWANNA"
//     },
//     {
//         district: "रामपुर",
//         name: "SAHVIA KHURD"
//     },
//     {
//         district: "रामपुर",
//         name: "GADMARPATTI MOTI SINGH"
//     },
//     {
//         district: "रामपुर",
//         name: "THIRIYA ZADID"
//     },
//     {
//         district: "रामपुर",
//         name: "MITTARPUR AHRAULA BHURASI"
//     },
//     {
//         district: "रामपुर",
//         name: "DANIYAPUR"
//     },
//     {
//         district: "रामपुर",
//         name: "HUSAINGANJ"
//     },
//     {
//         district: "रामपुर",
//         name: "RAIPUR"
//     },
//     {
//         district: "रामपुर",
//         name: "SHIV NAGAR LOHARI"
//     },
//     {
//         district: "रामपुर",
//         name: "DHIRAJ NAGAR"
//     },
//     {
//         district: "रामपुर",
//         name: "MILAK TAJ KHAN"
//     },
//     {
//         district: "रामपुर",
//         name: "PARSHUPURA"
//     },
//     {
//         district: "रामपुर",
//         name: "SHAHZAD NAGAR"
//     },
//     {
//         district: "रामपुर",
//         name: "BHOT BAQQAL"
//     },
//     {
//         district: "रामपुर",
//         name: "CHANDPURA SEEKAMPUR"
//     },
//     {
//         district: "रामपुर",
//         name: "LALPUR"
//     },
//     {
//         district: "रामपुर",
//         name: "LAMBA KHERA"
//     },
//     {
//         district: "रामपुर",
//         name: "SARKARI"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "कोला"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "अल्हदादपुर बैहारी"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "गुनारा"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "निजामपुर नगरिया"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "रघुनाथपुर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "जेरारहीमपुर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "भरथौली"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "चौरासी"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "फत्तेपुर बिरिया"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "रामपुर ढकाडांडी"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "बिहारीपुर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "रटा"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "सरसवां"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "विकमपुर चकौरा"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "कूकहा महमूदपुर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "बमिहाना"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "कुरगांव"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "धुवला करीमनगर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "मरेना"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "नगला देहातमाली"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "पैलानी उत्तर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "चौराबगरखेत"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "जरीनपुर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "वीघापुर सिठौली"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "परौर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "दहिलिया"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "कुनियाशाह नजीरपुर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "पृथ्वीपुर ढाई"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "दोषपुर थोक"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "मझारा"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "इन्दलपुर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "इनायतपुर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "बिलन्दापुर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "सिंधौली"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "गोलारायपुर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "कोरोकुइंया"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "ररूआ"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "महासिर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "नवावपुर गंगा"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "सुनासर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "चौडेरा"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "कारीमुकआपुर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "नागरपाल"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "गुवारी"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "सरौरा"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "चांदा बरकतपुर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "धन्यौरा"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "रहमाननगर उर्फ राईखेड़ा"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "चौहनापुर"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "भरगवां"
//     },
//     {
//         district: "सहारनपुर",
//         name: "असलपुर बस्था"
//     },
//     {
//         district: "सहारनपुर",
//         name: "नुनियारी"
//     },
//     {
//         district: "सहारनपुर",
//         name: "घौलरा"
//     },
//     {
//         district: "सहारनपुर",
//         name: "कस्वागढ"
//     },
//     {
//         district: "सहारनपुर",
//         name: "हबीबपुर उर्फ तपोवन"
//     },
//     {
//         district: "सहारनपुर",
//         name: "मढ़ती"
//     },
//     {
//         district: "सहारनपुर",
//         name: "बडकला"
//     },
//     {
//         district: "सहारनपुर",
//         name: "जसमौर"
//     },
//     {
//         district: "सहारनपुर",
//         name: "पाजराना"
//     },
//     {
//         district: "सहारनपुर",
//         name: "मिर्जापुर पोल"
//     },
//     {
//         district: "सहारनपुर",
//         name: "दबकोरा"
//     },
//     {
//         district: "सहारनपुर",
//         name: "जंघेडी"
//     },
//     {
//         district: "सहारनपुर",
//         name: "हसनपुर नौगावा"
//     },
//     {
//         district: "सहारनपुर",
//         name: "शाहबुद्दीनपुर"
//     },
//     {
//         district: "सहारनपुर",
//         name: "शाहपुर"
//     },
//     {
//         district: "सहारनपुर",
//         name: "नानोली"
//     },
//     {
//         district: "सहारनपुर",
//         name: "कासमपुर मजरा पाडली"
//     },
//     {
//         district: "सहारनपुर",
//         name: "मिरगपुर पाजूवाला"
//     },
//     {
//         district: "सहारनपुर",
//         name: "जंतपुर खुर्द"
//     },
//     {
//         district: "सहारनपुर",
//         name: "भाण्डूवाला"
//     },
//     {
//         district: "सहारनपुर",
//         name: "खुशहालीपुर"
//     },
//     {
//         district: "सहारनपुर",
//         name: "थापुल ईसमाईलपुर"
//     },
//     {
//         district: "सहारनपुर",
//         name: "टाबर अहतभाल"
//     },
//     {
//         district: "सहारनपुर",
//         name: "रानीपुर बरसी"
//     },
//     {
//         district: "सहारनपुर",
//         name: "सहसपुर जट्ट (डाल्लेवाला)"
//     },
//     {
//         district: "सहारनपुर",
//         name: "मन्धोर"
//     },
//     {
//         district: "सहारनपुर",
//         name: "नागल राजपूत"
//     },
//     {
//         district: "सहारनपुर",
//         name: "कुण्डा कलों"
//     },
//     {
//         district: "सहारनपुर",
//         name: "सनौली"
//     },
//     {
//         district: "सहारनपुर",
//         name: "कुण्डा खुर्द"
//     },
//     {
//         district: "सहारनपुर",
//         name: "रसूलपुर बल्लामाजरा"
//     },
//     {
//         district: "सहारनपुर",
//         name: "लखनौती अहतमाल जदीद"
//     },
//     {
//         district: "सहारनपुर",
//         name: "कल्लरहेडी"
//     },
//     {
//         district: "सहारनपुर",
//         name: "सौधेबास"
//     },
//     {
//         district: "सहारनपुर",
//         name: "ग्याजुद्दीनपुर"
//     },
//     {
//         district: "सहारनपुर",
//         name: "टोडरपुर"
//     },
//     {
//         district: "सहारनपुर",
//         name: "मझार"
//     },
//     {
//         district: "सहारनपुर",
//         name: "आल्हणपुर अहतमाल"
//     },
//     {
//         district: "सहारनपुर",
//         name: "दुमझडा अहतमाल"
//     },
//     {
//         district: "सहारनपुर",
//         name: "धाघाण्ड अहतमाल"
//     },
//     {
//         district: "सहारनपुर",
//         name: "नारगपुर"
//     },
//     {
//         district: "सहारनपुर",
//         name: "दुसनपुर अहतमाल"
//     },
//     {
//         district: "सहारनपुर",
//         name: "हसनपुर भलस्वा अहतमाल"
//     },
//     {
//         district: "सहारनपुर",
//         name: "दुधली दुखारा अहत्तमाल"
//     },
//     {
//         district: "सहारनपुर",
//         name: "गांगलहंडी अहतगाल"
//     },
//     {
//         district: "सहारनपुर",
//         name: "तिवाया अहतमाल"
//     },
//     {
//         district: "सहारनपुर",
//         name: "बडगाव"
//     },
//     {
//         district: "सहारनपुर",
//         name: "चकवाली"
//     },
//     {
//         district: "सहारनपुर",
//         name: "महतोली"
//     },
//     {
//         district: "सहारनपुर",
//         name: "सापली बत्री"
//     },
//     {
//         district: "सम्भल",
//         name: "बिलालपत"
//     },
//     {
//         district: "सम्भल",
//         name: "दुगावर"
//     },
//     {
//         district: "सम्भल",
//         name: "ओवरी"
//     },
//     {
//         district: "सम्भल",
//         name: "खासपुर"
//     },
//     {
//         district: "सम्भल",
//         name: "मनौटा"
//     },
//     {
//         district: "सम्भल",
//         name: "अकबरपुर गहरा"
//     },
//     {
//         district: "सम्भल",
//         name: "केशोपुर रसैटा"
//     },
//     {
//         district: "सम्भल",
//         name: "बहादुरनगर"
//     },
//     {
//         district: "सम्भल",
//         name: "पाठकपुर"
//     },
//     {
//         district: "सम्भल",
//         name: "धामपुर कान्हू"
//     },
//     {
//         district: "सम्भल",
//         name: "आनन्दपुर"
//     },
//     {
//         district: "सम्भल",
//         name: "चाटन"
//     },
//     {
//         district: "सम्भल",
//         name: "मझावली"
//     },
//     {
//         district: "सम्भल",
//         name: "नेहटा"
//     },
//     {
//         district: "सम्भल",
//         name: "पुरा"
//     },
//     {
//         district: "सम्भल",
//         name: "बेहटा साहू"
//     },
//     {
//         district: "सम्भल",
//         name: "भुलावई"
//     },
//     {
//         district: "सम्भल",
//         name: "अकरौली"
//     },
//     {
//         district: "सम्भल",
//         name: "कुहेरा"
//     },
//     {
//         district: "सम्भल",
//         name: "बहलोलपुर"
//     },
//     {
//         district: "सम्भल",
//         name: "सुनवर सराय"
//     },
//     {
//         district: "सम्भल",
//         name: "जयरामनगर"
//     },
//     {
//         district: "सम्भल",
//         name: "मानकपुर"
//     },
//     {
//         district: "सम्भल",
//         name: "उधरनपुर अजमतनगर"
//     },
//     {
//         district: "सम्भल",
//         name: "झुकैरा"
//     },
//     {
//         district: "सम्भल",
//         name: "दुवारी कला"
//     },
//     {
//         district: "सम्भल",
//         name: "चबूतरा"
//     },
//     {
//         district: "सम्भल",
//         name: "चिरवारी"
//     },
//     {
//         district: "सम्भल",
//         name: "सुल्तानगढ़"
//     },
//     {
//         district: "सम्भल",
//         name: "गढ़ी विचौला"
//     },
//     {
//         district: "सम्भल",
//         name: "कुदारसी"
//     },
//     {
//         district: "सम्भल",
//         name: "खगूपुरा"
//     },
//     {
//         district: "सम्भल",
//         name: "रामपुर मोहकम"
//     },
//     {
//         district: "सम्भल",
//         name: "डुप्टा खुर्द"
//     },
//     {
//         district: "सम्भल",
//         name: "पवसरा"
//     },
//     {
//         district: "सम्भल",
//         name: "मलिकपुर"
//     },
//     {
//         district: "सम्भल",
//         name: "सैफखां सराय"
//     },
//     {
//         district: "सम्भल",
//         name: "शेरखां सराय"
//     },
//     {
//         district: "सम्भल",
//         name: "गोविन्दपुर"
//     },
//     {
//         district: "सम्भल",
//         name: "जोगीपुर"
//     },
//     {
//         district: "सम्भल",
//         name: "बिछौली"
//     },
//     {
//         district: "सम्भल",
//         name: "अफजलपुर डरौली"
//     },
//     {
//         district: "सम्भल",
//         name: "कासमपुर जगरूप"
//     },
//     {
//         district: "सम्भल",
//         name: "शाहपुर चामरान"
//     },
//     {
//         district: "सम्भल",
//         name: "रहटौल"
//     },
//     {
//         district: "सम्भल",
//         name: "अतौरा"
//     },
//     {
//         district: "सम्भल",
//         name: "बटौआ"
//     },
//     {
//         district: "सम्भल",
//         name: "सदीरनपुर"
//     },
//     {
//         district: "सम्भल",
//         name: "मवई ठाकुरान"
//     },
//     {
//         district: "सम्भल",
//         name: "भारतल सिरसी"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "भक्टी"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "सांगठ"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "भगवानपुर"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "भैसठ"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "बिहारे"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "फेउसी"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "बकहा"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "बालूशासन"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "चन्दनी"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "उतरावल"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "कोल्हुआ लकडा"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "चौरी"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "कुस्फर उर्फ उर्दहवा"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "जंगलऊन"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "मोहम्मदपुर कठार"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "मोहद्दीपुर"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "रसूलाबाद"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "साड़ेकला"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "साड़ेखुर्द"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "जोरवा"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "बेलौहा"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "भरथुआ"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "नौगो"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "बढ़या"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "कुसौना कला"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "जमोहरा"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "बरईपुर"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "हरपुर"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "सरफरा"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "पिडारीकला"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "बेलौली"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "डुमरियाबाबू"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "ढोढ"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "ओझापट्टी"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "सिसवनिया"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "शनिचरा चौबे"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "गोरयाघाट"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "बेलहरा"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "सांखी"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "तेजपुर"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "भोतहा खास"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "पड़रिया"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "लौकिया"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "नकहा"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "चकिया"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "नरायनपुर"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "चपरा पूर्वी"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "रामपुर दक्षिणी"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "गायघाट दक्षिणि"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "बहराडाडी"
//     },
//     {
//         district: "शामली",
//         name: "नंगलाराई"
//     },
//     {
//         district: "शामली",
//         name: "मामौर"
//     },
//     {
//         district: "शामली",
//         name: "सहपत"
//     },
//     {
//         district: "शामली",
//         name: "मवी हैदरपुर"
//     },
//     {
//         district: "शामली",
//         name: "मवी अह०तिमाली"
//     },
//     {
//         district: "शामली",
//         name: "रामडा"
//     },
//     {
//         district: "शामली",
//         name: "इस्सोपुर खुरगान"
//     },
//     {
//         district: "शामली",
//         name: "बसेडा"
//     },
//     {
//         district: "शामली",
//         name: "मण्डावर"
//     },
//     {
//         district: "शामली",
//         name: "मौहम्मदपुर राई"
//     },
//     {
//         district: "शामली",
//         name: "चौसाना जदीद"
//     },
//     {
//         district: "शामली",
//         name: "भडी मुस्तफाबाद"
//     },
//     {
//         district: "शामली",
//         name: "नाईनंगला सकौती"
//     },
//     {
//         district: "शामली",
//         name: "ऊदपुर"
//     },
//     {
//         district: "शामली",
//         name: "म्यानकस्बा"
//     },
//     {
//         district: "शामली",
//         name: "बिडौली"
//     },
//     {
//         district: "शामली",
//         name: "फतेहपुर औदरी"
//     },
//     {
//         district: "शामली",
//         name: "युसुफपुर चौतरा"
//     },
//     {
//         district: "शामली",
//         name: "डोकपुरा"
//     },
//     {
//         district: "शामली",
//         name: "डून्डूखेडा बांगर"
//     },
//     {
//         district: "शामली",
//         name: "इस्सोपुरटील"
//     },
//     {
//         district: "शामली",
//         name: "सलफा"
//     },
//     {
//         district: "शामली",
//         name: "किवाना"
//     },
//     {
//         district: "शामली",
//         name: "लिसाढ"
//     },
//     {
//         district: "शामली",
//         name: "मतनावली"
//     },
//     {
//         district: "शामली",
//         name: "बलवा"
//     },
//     {
//         district: "शामली",
//         name: "जलालपुर"
//     },
//     {
//         district: "शामली",
//         name: "खानपुर तलवा माजरा"
//     },
//     {
//         district: "शामली",
//         name: "बरला जट"
//     },
//     {
//         district: "शामली",
//         name: "काबडौत"
//     },
//     {
//         district: "शामली",
//         name: "झाल"
//     },
//     {
//         district: "शामली",
//         name: "बुटराडी"
//     },
//     {
//         district: "शामली",
//         name: "लिलोन"
//     },
//     {
//         district: "शामली",
//         name: "खेडीकरमू"
//     },
//     {
//         district: "शामली",
//         name: "सिक्का"
//     },
//     {
//         district: "शामली",
//         name: "सिलावर"
//     },
//     {
//         district: "शामली",
//         name: "करोडी"
//     },
//     {
//         district: "शामली",
//         name: "फतेहपुर"
//     },
//     {
//         district: "शामली",
//         name: "गोहरपुर"
//     },
//     {
//         district: "शामली",
//         name: "कुडाना"
//     },
//     {
//         district: "शामली",
//         name: "ताजपुर सिंभालका"
//     },
//     {
//         district: "शामली",
//         name: "गोहरनी"
//     },
//     {
//         district: "शामली",
//         name: "जलालाबाद देहात"
//     },
//     {
//         district: "शामली",
//         name: "थानाभवन देहात"
//     },
//     {
//         district: "शामली",
//         name: "चन्देनामाल"
//     },
//     {
//         district: "शामली",
//         name: "गोगवान जलालपुर"
//     },
//     {
//         district: "शामली",
//         name: "दभेडी"
//     },
//     {
//         district: "शामली",
//         name: "हिरनवाडा"
//     },
//     {
//         district: "शामली",
//         name: "दखोडी जमालपुर"
//     },
//     {
//         district: "शामली",
//         name: "तितारसी"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "खच"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "गोठवा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "जौगढ"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "नौबस्ता"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "के ावापुर"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "मोहम्मदपुर कला"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "अमवा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "टिकुइया"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "अशरफनगर"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "राजापुर वीरपुर"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "मटखनवा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "तिलकपुर"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "अलीनगर धर्मनगर"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "रमनगरा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "नरायनपुर"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "कसियापुर"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "सेहनिया"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "गौहनिया"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "मध्यनगर"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "भरथापुर"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "कोलाभार मजगंवा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "कोडरी दीगर"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "जगरावलगढ़ी"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "दहावरकला"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "अंधरपुरवा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "भोजपुर"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "मनकाकोट"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "महरौली"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "इटवरिया"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "मलौना खसियारी"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "मोहम्मदपुर राजा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "शिवाजोत"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "सेमगढ़ा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "लालपुर खदरा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "राजगढ़ गुलरिहा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "खरगौरा गनेश"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "भरथा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "गजोबरी"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "कथरामाफी"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "सर्रा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "धूमबोझी दुर्गा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "लक्ष्मनपुर सेमरहनिया"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "जोगिया"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "बरगदहा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "कलकलवा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "गंगाभागड़"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "बेलरी"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "बीरपुर लौकिहा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "संगमपुरवा"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "शिकारी चौड़ा"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "अमरिया"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "संगल दीप"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "अजगरा"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "लाऊ खाई"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "गाय घाट"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "बंगरा"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "हथिवड ताल"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "इटौआ"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "फत्तेपुर"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "खजूर डाड"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "भगौतापुर"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "सतवाढी"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "सोनखर"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "बकुआव"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "भुजराई"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "टडवलघाट"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "सूपघाट"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "हाटाखास"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "मझवनखुर्द"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "मझवनकला"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "पेडारी मु०"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "कोहलगोद कोट"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "राउतडीला मु०"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "बेव मु०"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "असनहरा माफी"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "सीरमझारी"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "सुकरौली"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "बेतना मु०"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "राजपुर"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "खन्ता"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "सिगारजोत"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "बेलवा"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "छगडिहवा"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "मुडिलिया"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "सोनौली नानकार"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "कठेला शर्की"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "औरहवा"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "मदरहवा"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "बजराभारी"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "नबेल"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "भैसहवा"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "भपसी"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "मझवन"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "बैजनथा"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "पिपरहवा"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "तौलिहवा"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "खैरी उर्फ क्षुगहवा"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "मटियार उर्फ भुतहवा"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "खैरी शीतलप्रसाद"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "रेकहट"
//     },
//     {
//         district: "सीतापुर",
//         name: "नौरंगपुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "अल्लीपुर अलीरजा"
//     },
//     {
//         district: "सीतापुर",
//         name: "मानपुर मल्लापुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "औरी शाहपुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "चंदवासोत"
//     },
//     {
//         district: "सीतापुर",
//         name: "गोधनी सरैया"
//     },
//     {
//         district: "सीतापुर",
//         name: "बसुदहा"
//     },
//     {
//         district: "सीतापुर",
//         name: "बजेहरा"
//     },
//     {
//         district: "सीतापुर",
//         name: "रामगढ़"
//     },
//     {
//         district: "सीतापुर",
//         name: "कुर्सी"
//     },
//     {
//         district: "सीतापुर",
//         name: "सेलूमऊ"
//     },
//     {
//         district: "सीतापुर",
//         name: "ककराही"
//     },
//     {
//         district: "सीतापुर",
//         name: "बड़ेलिया"
//     },
//     {
//         district: "सीतापुर",
//         name: "जयरामपुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "हरिहरपुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "चिलवारा"
//     },
//     {
//         district: "सीतापुर",
//         name: "शहजादपुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "सुल्तानपुर शाहपुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "लालपुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "गौरिया"
//     },
//     {
//         district: "सीतापुर",
//         name: "मिरचौड़ी"
//     },
//     {
//         district: "सीतापुर",
//         name: "धरमपुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "हेलेपारा"
//     },
//     {
//         district: "सीतापुर",
//         name: "गोडैचा"
//     },
//     {
//         district: "सीतापुर",
//         name: "महुआ कोला"
//     },
//     {
//         district: "सीतापुर",
//         name: "कुँवरपुर गद्दी"
//     },
//     {
//         district: "सीतापुर",
//         name: "पनाहनगर"
//     },
//     {
//         district: "सीतापुर",
//         name: "ठाकुरनगर"
//     },
//     {
//         district: "सीतापुर",
//         name: "गोहलारी"
//     },
//     {
//         district: "सीतापुर",
//         name: "सदरावॉ"
//     },
//     {
//         district: "सीतापुर",
//         name: "कण्डारी"
//     },
//     {
//         district: "सीतापुर",
//         name: "सिकन्दरबाद"
//     },
//     {
//         district: "सीतापुर",
//         name: "पेंदारी"
//     },
//     {
//         district: "सीतापुर",
//         name: "कुँइयाखेड़ा"
//     },
//     {
//         district: "सीतापुर",
//         name: "अकोहरा"
//     },
//     {
//         district: "सीतापुर",
//         name: "मिर्जापुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "उमरिया खानपुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "कन्धेलिया"
//     },
//     {
//         district: "सीतापुर",
//         name: "अखरी"
//     },
//     {
//         district: "सीतापुर",
//         name: "अंगरौरा"
//     },
//     {
//         district: "सीतापुर",
//         name: "अटौरा"
//     },
//     {
//         district: "सीतापुर",
//         name: "गौलोक कोडर"
//     },
//     {
//         district: "सीतापुर",
//         name: "मल्लापुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "काशीपुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "शाहपुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "लखनियापुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "बेलवा बसैहिया"
//     },
//     {
//         district: "सीतापुर",
//         name: "अकबरपुर रेवान"
//     },
//     {
//         district: "सीतापुर",
//         name: "विर सिंहपुर"
//     },
//     {
//         district: "सीतापुर",
//         name: "नयागाँव"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "पहाडपुरवस्तीपुर"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "भीरपुरप्रतापपुर"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "बीही निदूरा"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "हेगनापुर"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "इसौली"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "जरईकला"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "डीह"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "कांपा"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "हलियापुर"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "मेघगऊ"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "गर्दैया"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "पुरैना"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "ऐंजर"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "चन्दौर"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "कोरो"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "गोसैसिंहपुर"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "कटघरापट्टी"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "बन्धुआकला"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "भाई"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "गोरावारिक"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "हसनपुर"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "कटावां"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "लौहरदक्षिण"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "बनकपुर"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "बलहरी"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "चरासा"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "Mugair"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "फतहपरसगत"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "Sarauli"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "Bankegaon"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "Raivigo"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "Pakadpur"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "Dehdhagupur"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "Saifulla Ganj"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "Said Khan Pur"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "Bhandar Parasrampur"
//     },
//     {
//         district: "सुलतानपुर",
//         name: ""
//     },
//     {
//         district: "सुलतानपुर",
//         name: "कुड़वार"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "शाहपुरसरकण्डेडीह"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "सोहगौली"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "हाजीपट्टी"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "कोटिया"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "तोतोमुरैनी"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "खरगपुर"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "नानेमऊ"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "शाहपुरलपटा"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "प्रतापपुरकमैचा"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "सोनावां"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "भिरौरा"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "शुकुलउमरी"
//     },
//     {
//         district: "उन्नाव",
//         name: "लाल खेड़ा"
//     },
//     {
//         district: "उन्नाव",
//         name: "सारूखेडा"
//     },
//     {
//         district: "उन्नाव",
//         name: "कटरीगदनपुर आहार"
//     },
//     {
//         district: "उन्नाव",
//         name: "मेला आलपशाह"
//     },
//     {
//         district: "उन्नाव",
//         name: "फरीदपुर कट्टर"
//     },
//     {
//         district: "उन्नाव",
//         name: "जगतनगर"
//     },
//     {
//         district: "उन्नाव",
//         name: "सि०करन"
//     },
//     {
//         district: "उन्नाव",
//         name: "खुटहानौगवां"
//     },
//     {
//         district: "उन्नाव",
//         name: "बदियाखेड़ा"
//     },
//     {
//         district: "उन्नाव",
//         name: "बैदरा"
//     },
//     {
//         district: "उन्नाव",
//         name: "कटरीअलुहापुर सरेसा"
//     },
//     {
//         district: "उन्नाव",
//         name: "विभौराचन्दनपुर"
//     },
//     {
//         district: "उन्नाव",
//         name: "निबई गै०ए1"
//     },
//     {
//         district: "उन्नाव",
//         name: "रजवाखेड़ा"
//     },
//     {
//         district: "उन्नाव",
//         name: "दुधौड़ा"
//     },
//     {
//         district: "उन्नाव",
//         name: "खैरहा एहतमाली"
//     },
//     {
//         district: "उन्नाव",
//         name: "बसधना"
//     },
//     {
//         district: "उन्नाव",
//         name: "कनिकामऊ"
//     },
//     {
//         district: "उन्नाव",
//         name: "कटरी मरौंदा मझवारा"
//     },
//     {
//         district: "उन्नाव",
//         name: "मिर्जापुर"
//     },
//     {
//         district: "उन्नाव",
//         name: "रौतापुर"
//     },
//     {
//         district: "उन्नाव",
//         name: "शंकरपुर सराय"
//     },
//     {
//         district: "उन्नाव",
//         name: "सन्नी"
//     },
//     {
//         district: "उन्नाव",
//         name: "नेतुआ"
//     },
//     {
//         district: "उन्नाव",
//         name: "बरबट"
//     },
//     {
//         district: "उन्नाव",
//         name: "बक्सर"
//     },
//     {
//         district: "उन्नाव",
//         name: "सेंदूपुर"
//     },
//     {
//         district: "उन्नाव",
//         name: "संग्रामपुर"
//     },
//     {
//         district: "उन्नाव",
//         name: "दूलीखेडा"
//     },
//     {
//         district: "उन्नाव",
//         name: "छांछीराई खेडा"
//     },
//     {
//         district: "उन्नाव",
//         name: "रूपपुर चन्देला"
//     },
//     {
//         district: "उन्नाव",
//         name: "ककरौरा"
//     },
//     {
//         district: "उन्नाव",
//         name: "रुस्तमपुर"
//     },
//     {
//         district: "उन्नाव",
//         name: "जाजामऊ एहतमाली"
//     },
//     {
//         district: "उन्नाव",
//         name: "जैतपुर"
//     },
//     {
//         district: "उन्नाव",
//         name: "गडाई"
//     },
//     {
//         district: "उन्नाव",
//         name: "सैंता"
//     },
//     {
//         district: "उन्नाव",
//         name: "बेहटा कच्छ"
//     },
//     {
//         district: "उन्नाव",
//         name: "सिरधरपुर एह०"
//     },
//     {
//         district: "उन्नाव",
//         name: "भिखारीपुर पतसिया"
//     },
//     {
//         district: "उन्नाव",
//         name: "खैरहन"
//     },
//     {
//         district: "उन्नाव",
//         name: "सरांय कटियान"
//     },
//     {
//         district: "उन्नाव",
//         name: "असोहा"
//     },
//     {
//         district: "उन्नाव",
//         name: "सोहरामऊ"
//     },
//     {
//         district: "उन्नाव",
//         name: "बेहटा भवानी"
//     },
//     {
//         district: "उन्नाव",
//         name: "हिलौली"
//     },
//     {
//         district: "उन्नाव",
//         name: "खांनपुर"
//     },
//     {
//         district: "उन्नाव",
//         name: "मिर्जापुर अजिगांव"
//     },
//     {
//         district: "उन्नाव",
//         name: "हसनगंज"
//     },
//     {
//         district: "उन्नाव",
//         name: "पनापुर कला"
//     },
//     {
//         district: "आगरा",
//         name: "अन्य"
//     },
//     {
//         district: "अम्बेडकरनगर",
//         name: "अन्य"
//     },
//     {
//         district: "अमेठी",
//         name: "अन्य"
//     },
//     {
//         district: "vejksgk ",
//         name: "अन्य"
//     },
//     {
//         district: "औरैया",
//         name: "अन्य"
//     },
//     {
//         district: "बदायूँ",
//         name: "अन्य"
//     },
//     {
//         district: "बलरामपुर",
//         name: "अन्य"
//     },
//     {
//         district: "बांदा",
//         name: "अन्य"
//     },
//     {
//         district: "बरेली",
//         name: "अन्य"
//     },
//     {
//         district: "भदोही",
//         name: "अन्य"
//     },
//     {
//         district: "बागपत",
//         name: "अन्य"
//     },
//     {
//         district: "fctukSj",
//         name: "अन्य"
//     },
//     {
//         district: "बुलन्दशहर",
//         name: "अन्य"
//     },
//     {
//         district: "चन्दौली",
//         name: "अन्य"
//     },
//     {
//         district: "देवरिया",
//         name: "अन्य"
//     },
//     {
//         district: "एटा",
//         name: "अन्य"
//     },
//     {
//         district: "इटावा",
//         name: "अन्य"
//     },
//     {
//         district: "फर्रुखाबाद",
//         name: "अन्य"
//     },
//     {
//         district: "फिरोजाबाद",
//         name: "अन्य"
//     },
//     {
//         district: "फतेहपुर",
//         name: "अन्य"
//     },
//     {
//         district: "गौतमबुद्धनगर",
//         name: "अन्य"
//     },
//     {
//         district: "गाजियाबाद",
//         name: "अन्य"
//     },
//     {
//         district: "हाथरस",
//         name: "अन्य"
//     },
//     {
//         district: "हमीरपुर",
//         name: "अन्य"
//     },
//     {
//         district: "हापुड़",
//         name: "अन्य"
//     },
//     {
//         district: "हरदोई",
//         name: "अन्य"
//     },
//     {
//         district: "जालौन",
//         name: "अन्य"
//     },
//     {
//         district: "जौनपुर",
//         name: "अन्य"
//     },
//     {
//         district: "कन्नौज",
//         name: "अन्य"
//     },
//     {
//         district: "कानपुर देहात",
//         name: "अन्य"
//     },
//     {
//         district: "कानपुर नगर",
//         name: "अन्य"
//     },
//     {
//         district: "कासगंज",
//         name: "अन्य"
//     },
//     {
//         district: "कौशाम्बी",
//         name: "अन्य"
//     },
//     {
//         district: "ललितपुर",
//         name: "अन्य"
//     },
//     {
//         district: "महोबा",
//         name: "अन्य"
//     },
//     {
//         district: "eSuiqjh ",
//         name: "अन्य"
//     },
//     {
//         district: "मथुरा",
//         name: "अन्य"
//     },
//     {
//         district: "मऊ",
//         name: "अन्य"
//     },
//     {
//         district: "मेरठ",
//         name: "अन्य"
//     },
//     {
//         district: "मीरजापुर",
//         name: "अन्य"
//     },
//     {
//         district: "मुजपफरनगर",
//         name: "अन्य"
//     },
//     {
//         district: "प्रयागराज ",
//         name: "अन्य"
//     },
//     {
//         district: "पीलीभीत",
//         name: "अन्य"
//     },
//     {
//         district: "प्रतापगढ़",
//         name: "अन्य"
//     },
//     {
//         district: "रायबरेली जं.",
//         name: "अन्य"
//     },
//     {
//         district: "रामपुर",
//         name: "अन्य"
//     },
//     {
//         district: "शाहजहाँपुर",
//         name: "अन्य"
//     },
//     {
//         district: "सहारनपुर",
//         name: "अन्य"
//     },
//     {
//         district: "सम्भल",
//         name: "अन्य"
//     },
//     {
//         district: "संत कबीर नगर",
//         name: "अन्य"
//     },
//     {
//         district: "शामली",
//         name: "अन्य"
//     },
//     {
//         district: "श्रावस्ती",
//         name: "अन्य"
//     },
//     {
//         district: "सिद्धार्थनगर",
//         name: "अन्य"
//     },
//     {
//         district: "सीतापुर",
//         name: "अन्य"
//     },
//     {
//         district: "सुलतानपुर",
//         name: "अन्य"
//     },
//     {
//         district: "उन्नाव",
//         name: "अन्य"
//     }
// ]

